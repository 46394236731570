import React, { useState, useEffect, useMemo, useCallback } from "react";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import { ClipLoader } from "react-spinners";
import CustomersTable from "../../components/CustomersTable";
import LayoutHeader from "../../components/LayoutHeader";
import useCustomersStore from "../../utils/useCustomersStore";
import { TbRefresh } from "react-icons/tb";
import { HiSearch } from "react-icons/hi";

export default function Customers() {
  const { docs, fetchCustomers, loading, setCached, setLoading } =
    useCustomersStore();

  const [customers, setCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalCustomers, setTotalCustomers] = useState(0);

  // Optimize callback functions
  const updateCustomers = useCallback((newCustomers) => {
    setCustomers(newCustomers);
  }, []);

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const handleRefreshData = useCallback(async () => {
    setLoading(true);
    setCached(false);
    await fetchCustomers();
  }, [fetchCustomers, setCached, setLoading]);

  // Fetch customer data
  useEffect(() => {
    const getData = async () => {
      await fetchCustomers();
      setTotalCustomers(docs.length);
      updateCustomers(docs);
    };

    getData();
  }, [docs, fetchCustomers, updateCustomers]);

  // Filter customers based on search query
  const filteredCustomers = useMemo(() => {
    if (!searchQuery.trim()) return customers;

    const query = searchQuery.toLowerCase();
    return customers.filter(
      (customer) =>
        customer.customer_name?.toLowerCase().includes(query) ||
        customer.customer_phone_no?.includes(query)
    );
  }, [customers, searchQuery]);

  // Render empty state
  const renderEmptyState = () => (
    <div className="flex flex-col items-center justify-center px-4 py-12 text-center bg-white rounded-lg shadow">
      <div className="mb-4 text-gray-400">
        <svg
          className="w-12 h-12 mx-auto"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
          ></path>
        </svg>
      </div>
      <h3 className="text-lg font-medium text-gray-900">No customers found</h3>
      <p className="mt-1 text-gray-500">
        {searchQuery
          ? "Try adjusting your search terms"
          : "Customer data will appear here once added"}
      </p>
    </div>
  );

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="h-full">
          <LayoutHeader title="Customers" />

          <div className="px-4 pb-8">
            {/* Header with search and stats */}
            <div className="p-4 mb-6 bg-white rounded-lg shadow-sm">
              <div className="flex flex-col items-start justify-between gap-4 lg:flex-row lg:items-center">
                {/* Search bar with icon */}
                <div className="relative w-full lg:w-auto lg:min-w-[400px]">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <HiSearch className="w-5 h-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearch}
                    placeholder="Search by name or phone number"
                    className="w-full pl-10 pr-4 py-2.5 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
                    aria-label="Search customers"
                  />
                </div>

                {/* Stats and refresh button */}
                <div className="flex items-center justify-between w-full lg:w-auto">
                  <div className="flex items-center px-4 py-2 rounded-lg bg-blue-50">
                    <div className="text-sm font-medium text-gray-700">
                      Total Customers:{" "}
                      <span className="font-bold text-blue-900">
                        {totalCustomers}
                      </span>
                    </div>
                  </div>

                  <button
                    onClick={handleRefreshData}
                    disabled={loading}
                    className={`ml-4 flex items-center justify-center p-2.5 rounded-lg text-white ${
                      loading ? "bg-blue-400" : "bg-blue-900 hover:bg-blue-800"
                    } transition duration-150 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                    aria-label="Refresh data"
                    title="Refresh data"
                  >
                    <TbRefresh
                      className={`w-5 h-5 ${loading ? "animate-spin" : ""}`}
                    />
                  </button>
                </div>
              </div>
            </div>

            {/* Loading state */}
            {loading && (
              <div className="flex items-center justify-center w-full py-12">
                <ClipLoader size={40} color="#1E3A8A" />
              </div>
            )}

            {/* Results area */}
            {!loading && (
              <>
                {filteredCustomers.length === 0 ? (
                  renderEmptyState()
                ) : (
                  <div className="overflow-hidden bg-white rounded-lg shadow">
                    <div className="overflow-x-auto">
                      <CustomersTable customers={filteredCustomers} />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
}
