import React, { useState } from "react";
import { format } from "date-fns";
import {
  FaUser,
  FaEnvelope,
  FaClock,
  FaCheckCircle,
  FaTimesCircle,
  FaUserShield,
  FaUserCog,
} from "react-icons/fa";

export default function UserCard({
  name,
  email,
  photoUrl,
  lastLogin,
  isVerified,
  active,
  admin,
  uid,
}) {
  const [imageError, setImageError] = useState(false);

  // Format the last login date if available
  const formattedLastLogin = lastLogin
    ? format(lastLogin.toDate(), "MMM d, yyyy h:mm a")
    : "Never";

  // Handle image loading errors
  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div className="overflow-hidden bg-white rounded-lg shadow">
      <div className="px-4 py-5 sm:p-6">
        {/* User Avatar */}
        <div className="flex items-center">
          <div className="flex-shrink-0">
            {photoUrl && !imageError ? (
              <img
                src={photoUrl}
                alt={`${name}'s profile`}
                className="object-cover w-16 h-16 rounded-full"
                onError={handleImageError}
              />
            ) : (
              <div className="flex items-center justify-center w-16 h-16 text-white bg-blue-900 rounded-full">
                <FaUser className="w-8 h-8" />
              </div>
            )}
          </div>

          <div className="ml-5">
            <h3 className="text-lg font-medium leading-6 text-gray-900 break-all">
              {name}
            </h3>
            <div className="flex items-center mt-1 text-sm text-gray-500">
              <FaEnvelope className="w-4 h-4 mr-1 text-gray-400" />
              <span className="truncate">{email}</span>
            </div>
          </div>
        </div>

        {/* User Status Badges */}
        <div className="flex flex-wrap gap-2 mt-4">
          <span
            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
              active ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
            }`}
          >
            {active ? (
              <>
                <FaCheckCircle className="w-3 h-3 mr-1" />
                Active
              </>
            ) : (
              <>
                <FaTimesCircle className="w-3 h-3 mr-1" />
                Inactive
              </>
            )}
          </span>

          <span
            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
              admin ? "bg-blue-100 text-blue-800" : "bg-gray-100 text-gray-800"
            }`}
          >
            {admin ? (
              <>
                <FaUserShield className="w-3 h-3 mr-1" />
                Admin
              </>
            ) : (
              <>
                <FaUserCog className="w-3 h-3 mr-1" />
                User
              </>
            )}
          </span>

          <span
            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
              isVerified
                ? "bg-indigo-100 text-indigo-800"
                : "bg-yellow-100 text-yellow-800"
            }`}
          >
            {isVerified ? "Verified" : "Unverified"}
          </span>
        </div>

        {/* Last Login Info */}
        <div className="flex items-center mt-4 text-sm text-gray-500">
          <FaClock className="w-4 h-4 mr-1 text-gray-400" />
          <span>Last login: {formattedLastLogin}</span>
        </div>
      </div>

      {/* User Actions */}
      <div className="px-4 py-3 bg-gray-50 sm:px-6">
        <div className="flex justify-between">
          <button
            type="button"
            className="inline-flex items-center px-3 py-1 text-xs font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            View Details
          </button>

          <button
            type="button"
            className="inline-flex items-center px-3 py-1 text-xs font-medium text-white bg-blue-900 border border-transparent rounded shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Edit User
          </button>
        </div>
      </div>
    </div>
  );
}
