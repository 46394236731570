import { useState, useEffect, useContext, useCallback, memo } from "react";
import { AuthContext } from "../../utils/authContext";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { toastOptions } from "../../utils/helpers";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";

// Icons
import { BsEyeFill, BsEyeSlashFill, BsArrowLeft } from "react-icons/bs";
import { FaSpinner, FaLock } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { ClipLoader } from "react-spinners";

const FormInput = memo(
  ({
    id,
    name,
    label,
    type,
    placeholder,
    value,
    onChange,
    required,
    icon,
    rightElement,
  }) => {
    return (
      <div className="mb-5">
        <label
          htmlFor={id}
          className="block mb-2 text-sm font-semibold text-gray-700"
        >
          {label}
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500 pointer-events-none">
            {icon}
          </div>
          <input
            type={type}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            className="w-full py-3 pl-10 pr-10 text-gray-700 transition-all bg-white border border-gray-300 rounded-lg bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-blue-900 focus:border-transparent"
            placeholder={placeholder}
            required={required}
          />
          {rightElement && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              {rightElement}
            </div>
          )}
        </div>
      </div>
    );
  }
);

FormInput.displayName = "FormInput";

export default function UserLogin() {
  const navigate = useNavigate();
  const { currentUser, signIn } = useContext(AuthContext);

  const [formState, setFormState] = useState({
    email: "",
    password: "",
    showPassword: false,
    isSubmitting: false,
    rememberMe: false,
  });

  const handleInputChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  }, []);

  const togglePasswordVisibility = useCallback(() => {
    setFormState((prev) => ({
      ...prev,
      showPassword: !prev.showPassword,
    }));
  }, []);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const checkUserActivity = useCallback(async (email) => {
    try {
      const collRef = collection(db, "users");
      const q = query(collRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return true; // User doesn't exist yet, allow login attempt
      }

      return querySnapshot.docs[0].data().active;
    } catch (error) {
      console.error("Error checking user activity:", error);
      return false;
    }
  }, []);

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault();

      // Don't proceed if already submitting
      if (formState.isSubmitting) return;

      setFormState((prev) => ({ ...prev, isSubmitting: true }));

      try {
        const { email, password } = formState;

        // First check if user is active
        const isActive = await checkUserActivity(email);

        if (!isActive) {
          toast.error(
            "Your account is not active. Please contact the admin.",
            toastOptions
          );
          return;
        }

        // Proceed with sign in
        await signIn(email, password);
        toast.success("Logged in successfully!", toastOptions);
        navigate("/user/dashboard");
      } catch (error) {
        console.error("Login error:", error);

        const errorMap = {
          "auth/wrong-password": "Invalid email or password",
          "auth/user-not-found": "Invalid email or password",
          "auth/too-many-requests":
            "Too many failed login attempts. Please try again later.",
          "auth/invalid-email": "Please enter a valid email address",
        };

        const errorMessage =
          errorMap[error.code] ||
          "An error occurred during login. Please try again.";
        toast.error(errorMessage, toastOptions);
      } finally {
        setFormState((prev) => ({ ...prev, isSubmitting: false }));
      }
    },
    [formState, signIn, navigate, checkUserActivity]
  );

  // Redirect if already logged in
  useEffect(() => {
    if (currentUser) {
      navigate("/user/dashboard");
    }
  }, [currentUser, navigate]);

  if (currentUser) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-[#faa93b] from-15% via-[#FAC954] via-30% to-[#1e3a8a] to-70%">
        <ClipLoader size={40} color="#1e3a8a" />
        <p className="mt-4 text-lg font-medium text-white">
          Redirecting to dashboard...
        </p>
      </div>
    );
  }

  return (
    <main className="flex items-center justify-center min-h-screen bg-gradient-to-r from-[#faa93b] from-15% via-[#FAC954] via-30% to-[#1e3a8a] to-70% px-4 py-8">
      <div className="w-full max-w-md">
        <div className="overflow-hidden bg-white shadow-xl bg-opacity-20 rounded-2xl backdrop-blur-sm">
          {/* Back Button */}
          <div className="px-8 pt-4">
            <button
              onClick={handleBack}
              className="flex items-center text-gray-700 transition-colors hover:text-blue-900"
              aria-label="Go back"
            >
              <BsArrowLeft className="w-5 h-5 mr-2" />
              <span className="text-sm font-medium">Back</span>
            </button>
          </div>

          {/* Logo and Header */}
          <div className="px-8 pt-4 pb-4">
            <div className="flex flex-col items-center justify-center mb-6">
              <Link
                to="/"
                className="mb-4 transition transform hover:scale-105"
              >
                <img
                  src="/assets/logo.svg"
                  alt="JK SOLAR ENERGY"
                  width={120}
                  height={120}
                  className="drop-shadow-md"
                  loading="eager"
                />
              </Link>
              <h1 className="text-2xl font-bold text-center text-gray-900">
                User Portal
              </h1>
              <p className="mt-1 text-center text-gray-700">
                Login to access your account
              </p>
            </div>
          </div>

          {/* Login Form */}
          <form className="px-8 pt-2 pb-8 space-y-4" onSubmit={handleLogin}>
            <FormInput
              id="email"
              name="email"
              label="Email Address"
              type="email"
              placeholder="Enter your email"
              value={formState.email}
              onChange={handleInputChange}
              required
              icon={<MdEmail className="w-5 h-5" />}
            />

            <FormInput
              id="password"
              name="password"
              label="Password"
              type={formState.showPassword ? "text" : "password"}
              placeholder="Enter your password"
              value={formState.password}
              onChange={handleInputChange}
              required
              icon={<FaLock className="w-5 h-5" />}
              rightElement={
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="text-gray-500 focus:outline-none"
                  aria-label={
                    formState.showPassword ? "Hide password" : "Show password"
                  }
                >
                  {formState.showPassword ? (
                    <BsEyeFill className="w-5 h-5" />
                  ) : (
                    <BsEyeSlashFill className="w-5 h-5" />
                  )}
                </button>
              }
            />

            <div className="mb-6">
              <button
                className="relative flex justify-center w-full px-4 py-3 text-sm font-semibold text-white transition-all duration-200 bg-blue-900 border border-transparent rounded-full group hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 disabled:opacity-70 disabled:cursor-not-allowed"
                disabled={formState.isSubmitting}
                type="submit"
              >
                {formState.isSubmitting ? (
                  <>
                    <FaSpinner className="mr-2 animate-spin" size={20} />
                    <span>Logging in...</span>
                  </>
                ) : (
                  <span>Sign in</span>
                )}
              </button>
            </div>
          </form>
        </div>

        <div className="mt-6 text-sm text-center text-gray-200">
          &copy; {new Date().getFullYear()} JK Solar Energy. All rights
          reserved.
        </div>
      </div>
    </main>
  );
}
