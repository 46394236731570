import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import UserLayout from "../../components/UserLayout";
import UserProtectedRoute from "../../components/UserProtectedRoute";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  limit,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { AuthContext } from "../../utils/authContext";
import { ClipLoader } from "react-spinners";
import UserCustomersTable from "../../components/UserCustomersTable";
import LayoutHeader from "../../components/LayoutHeader";

// Icons for enhanced UI
import { UserCircle, AlertCircle } from "lucide-react";

export default function UserCustomers() {
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [refreshing, setRefreshing] = useState(false);
  const { currentUser } = useContext(AuthContext);

  // Statistics derived from customer data
  const stats = useMemo(() => {
    if (!customers.length) return { total: 0, recent: 0, pending: 0 };

    const now = new Date();
    const thirtyDaysAgo = new Date(now.setDate(now.getDate() - 30));

    return {
      total: customers.length,
      recent: customers.filter((c) => c.created_at?.toDate() > thirtyDaysAgo)
        .length,
      pending: customers.filter(
        (c) => c.status === "pending" || c.status === "processing"
      ).length,
    };
  }, [customers]);

  // Memoize the Firestore query to prevent unnecessary re-renders
  const customersQuery = useMemo(() => {
    if (!currentUser?.uid) return null;

    const customersCol = collection(
      db,
      `temp_orders/${currentUser.uid}/orders`
    );
    return query(customersCol, orderBy("created_at", "desc"), limit(pageSize));
  }, [currentUser?.uid, pageSize]);

  // Load more customers
  const handleLoadMore = useCallback(() => {
    setPageSize((prev) => prev + 25);
  }, []);

  useEffect(() => {
    if (!customersQuery) return;

    setLoading(true);
    setError(null);

    const unsubscribe = onSnapshot(
      customersQuery,
      (querySnapshot) => {
        try {
          const docs = [];
          querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
          });
          setCustomers(docs);
        } catch (error) {
          console.error("Error fetching customers:", error);
          setError(
            "Failed to load customer data. Please refresh and try again."
          );
        } finally {
          setLoading(false);
          setRefreshing(false);
        }
      },
      (err) => {
        console.error("Snapshot error:", err);
        setError("Connection error. Please check your internet and try again.");
        setLoading(false);
        setRefreshing(false);
      }
    );

    return unsubscribe;
  }, [customersQuery]);

  return (
    <UserProtectedRoute>
      <UserLayout>
        <div className="flex flex-col w-full h-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          {/* Enhanced header with stats */}
          <div className="mb-6">
            <div className="flex flex-col justify-between py-4 border-b sm:flex-row sm:items-center">
              <LayoutHeader title="Customers" />
            </div>

            {/* Stats cards */}
            <div className="grid grid-cols-1 gap-4 my-4 md:grid-cols-3">
              <div className="p-4 bg-white border border-gray-100 rounded-lg shadow-sm">
                <h3 className="text-sm font-medium text-gray-500">
                  Total Customers
                </h3>
                <p className="mt-1 text-2xl font-bold">{stats.total}</p>
              </div>
              <div className="p-4 bg-white border border-gray-100 rounded-lg shadow-sm">
                <h3 className="text-sm font-medium text-gray-500">
                  New (30 days)
                </h3>
                <p className="mt-1 text-2xl font-bold">{stats.recent}</p>
              </div>
              <div className="p-4 bg-white border border-gray-100 rounded-lg shadow-sm">
                <h3 className="text-sm font-medium text-gray-500">
                  Pending Orders
                </h3>
                <p className="mt-1 text-2xl font-bold">{stats.pending}</p>
              </div>
            </div>
          </div>

          {/* Loading state */}
          {loading && (
            <div className="flex flex-col items-center justify-center w-full py-12">
              <ClipLoader size={40} />
              <p className="mt-4 text-sm text-gray-500">
                {refreshing
                  ? "Refreshing customers..."
                  : "Loading customer data..."}
              </p>
            </div>
          )}

          {/* Error state */}
          {error && !loading && (
            <div className="w-full p-4 my-4 border border-red-200 rounded-lg bg-red-50">
              <div className="flex">
                <AlertCircle className="w-5 h-5 mr-2 text-red-500" />
                <p className="text-red-600">{error}</p>
              </div>
            </div>
          )}

          {/* Empty state */}
          {!loading && !error && customers.length === 0 && (
            <div className="flex flex-col items-center justify-center py-12 bg-white border border-gray-200 rounded-lg shadow-sm">
              <UserCircle className="w-16 h-16 text-gray-300" />
              <h3 className="mt-4 text-lg font-medium text-gray-900">
                No customers yet
              </h3>
              <p className="mt-1 text-gray-500">
                When you have customers, they will appear here.
              </p>
            </div>
          )}

          {/* Customer table */}
          {!loading && !error && customers.length > 0 && (
            <div className="overflow-hidden bg-white border border-gray-200 rounded-lg shadow-sm">
              <UserCustomersTable customers={customers} />

              {/* Load more button */}
              {customers.length >= pageSize && (
                <div className="flex justify-center py-4 border-t">
                  <button
                    onClick={handleLoadMore}
                    className="px-4 py-2 text-sm text-gray-600 transition-colors rounded hover:text-gray-900 hover:bg-gray-50"
                  >
                    Load More Customers
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </UserLayout>
    </UserProtectedRoute>
  );
}
