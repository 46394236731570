import React, { useEffect, useState } from "react";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import LayoutHeader from "../../components/LayoutHeader";
import useCustomersStore from "../../utils/useCustomersStore";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/helpers";
import { FaCopy, FaUserFriends, FaUserTie, FaUsers } from "react-icons/fa";
import { IoNavigate } from "react-icons/io5";
import { MdEmail, MdMessage, MdWhatsapp } from "react-icons/md";
import { db } from "../../firebase/firebaseConfig";
import {
  collection,
  getDocs,
  query,
  orderBy as fsOrderBy,
} from "firebase/firestore";

export default function Messaging() {
  const {
    querySnapshot,
    fetchCustomers,
    loading: storeLoading,
  } = useCustomersStore();

  // Initialize with empty arrays for data
  const [customerNums, setCustomerNums] = useState([]);
  const [advisorNums, setAdvisorNums] = useState([]);
  const [employeeNums, setEmployeeNums] = useState([]);
  const [recipient, setRecipient] = useState("customers");
  const [subject, setSubject] = useState("");
  const [customPhoneNumbers, setCustomPhoneNumbers] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [deliveryMethods, setDeliveryMethods] = useState({
    textMessage: false,
    whatsappMessage: false,
    email: false,
  });

  // More flexible regex pattern to match phone numbers
  const phoneNumberRegex = /\d{10}/g;

  const fetchCustomerNums = async () => {
    const nums = [];

    // Check if querySnapshot exists and has documents
    if (querySnapshot && !querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        try {
          const data = doc.data();

          // Check for customer_phone_no field based on your document structure
          if (data && data.customer_phone_no) {
            const phoneValue = data.customer_phone_no.toString();

            const matches = phoneValue.match(phoneNumberRegex);
            if (matches) {
              nums.push(...matches);
            }
          }
        } catch (err) {
          console.error("Error processing customer document:", err);
        }
      });
    } else {
      console.error("Customer querySnapshot is empty or null");
    }

    // Use unique values only
    const uniqueNums = [...new Set(nums)];

    // Set the customer numbers
    setCustomerNums(uniqueNums);
  };

  const fetchEmployeeNums = async () => {
    try {
      const employeesQuery = query(
        collection(db, "employee_list"),
        fsOrderBy("createdAt", "desc")
      );

      const nums = [];
      const querySnapshot = await getDocs(employeesQuery);

      // Check if we actually got documents
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          try {
            const data = doc.data();
            // Check for phone_no field based on your document structure
            if (data && data.phone_no) {
              const phoneValue = data.phone_no.toString();
              const matches = phoneValue.match(phoneNumberRegex);
              if (matches) {
                nums.push(...matches);
              }
            }
          } catch (err) {
            console.error("Error processing employee document:", err);
          }
        });
      } else {
        console.error("No employee documents found");
      }

      // Use unique values
      const uniqueNums = [...new Set(nums)];

      // Set the employee numbers
      setEmployeeNums(uniqueNums);
    } catch (error) {
      console.error("Error fetching employee numbers:", error);
      setEmployeeNums([]);
    }
  };

  const fetchAdvisorNums = async () => {
    try {
      const advisorsQuery = query(
        collection(db, "advisor_list"),
        fsOrderBy("createdAt", "desc")
      );

      const nums = [];
      const querySnapshot = await getDocs(advisorsQuery);

      // Check if we actually got documents
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          try {
            const data = doc.data();
            // Check for phone_no field based on your document structure
            if (data && data.phone_no) {
              // Handle both string and number types for phone_no
              const phoneValue = data.phone_no.toString();
              const matches = phoneValue.match(phoneNumberRegex);
              if (matches) {
                nums.push(...matches);
              }
            }
          } catch (err) {
            console.error("Error processing advisor document:", err);
          }
        });
      } else {
        console.error("No advisor documents found");
      }

      // Use unique values
      const uniqueNums = [...new Set(nums)];

      // Set the advisor numbers
      setAdvisorNums(uniqueNums);
    } catch (error) {
      console.error("Error fetching advisor numbers:", error);
      setAdvisorNums([]);
    }
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        // Make sure we have customer data by fetching it from the store
        await fetchCustomers();

        // Now get the phone numbers
        await fetchCustomerNums();
        await fetchAdvisorNums();
        await fetchEmployeeNums();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
    // eslint-disable-next-line
  }, []);

  const handleDeliveryMethodChange = (method) => {
    setDeliveryMethods((prevMethods) => ({
      ...prevMethods,
      [method]: !prevMethods[method],
    }));
  };

  const copyToClipboard = () => {
    const textArea = document.getElementById("messageBody");
    textArea.select();
    document.execCommand("copy");
    toast.success("Numbers copied successfully", toastOptions);
  };

  const navigateToFast2SMS = () => {
    window.open("https://www.fast2sms.com/dashboard/dlt", "_blank");
  };

  const getRecipientCount = () => {
    if (recipient === "custom") {
      return customPhoneNumbers.split(/[,\n]/).filter((num) => num.trim())
        .length;
    }
    if (recipient === "customers") return customerNums.length;
    if (recipient === "advisors") return advisorNums.length;
    if (recipient === "employees") return employeeNums.length;
    return 0;
  };

  const getDisplayedNumbers = () => {
    if (recipient === "custom") {
      return customPhoneNumbers;
    }

    // Ensure we have data for each category
    const numMap = {
      customers: customerNums,
      advisors: advisorNums,
      employees: employeeNums,
    };

    // Get the appropriate array and join with newlines
    const numbers = numMap[recipient] || [];
    return numbers.join("\n");
  };

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="flex flex-col h-full overflow-x-auto overflow-y-auto bg-white">
          <LayoutHeader title="Messaging" />

          <div className="flex-1 w-full max-w-6xl p-4 mx-auto md:p-6 lg:p-8">
            <div className="overflow-hidden bg-white border border-gray-100 rounded-lg shadow-sm">
              <div className="p-5 border-b border-gray-100">
                <h2 className="text-lg font-medium text-gray-800">
                  Message Distribution
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Send messages to your contacts
                </p>
              </div>

              <div className="p-5">
                <form className="space-y-6">
                  {/* Recipient Selection */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Recipients:
                    </label>
                    <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-4">
                      <div
                        className={`border rounded-lg p-3 cursor-pointer transition-all duration-200 hover:shadow-sm ${
                          recipient === "customers"
                            ? "bg-gray-800 text-white"
                            : "bg-white text-gray-700 border-gray-200 hover:border-gray-300"
                        }`}
                        onClick={() => setRecipient("customers")}
                      >
                        {isLoading || storeLoading ? (
                          <div className="flex items-center justify-center h-16 animate-pulse">
                            <div className="w-4 h-4 mr-3 bg-gray-300 rounded-full"></div>
                            <div className="flex-1">
                              <div className="w-24 h-4 mb-2 bg-gray-300 rounded"></div>
                              <div className="w-16 h-3 bg-gray-200 rounded"></div>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <FaUsers
                              className={`${
                                recipient === "customers"
                                  ? "text-white"
                                  : "text-gray-500"
                              } text-lg mr-3`}
                            />
                            <div>
                              <div className="font-medium">Customers</div>
                              <div className="mt-1 text-xs">
                                {customerNums.length} contacts
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div
                        className={`border rounded-lg p-3 cursor-pointer transition-all duration-200 hover:shadow-sm ${
                          recipient === "employees"
                            ? "bg-gray-800 text-white"
                            : "bg-white text-gray-700 border-gray-200 hover:border-gray-300"
                        }`}
                        onClick={() => setRecipient("employees")}
                      >
                        {isLoading ? (
                          <div className="flex items-center justify-center h-16 animate-pulse">
                            <div className="w-4 h-4 mr-3 bg-gray-300 rounded-full"></div>
                            <div className="flex-1">
                              <div className="w-24 h-4 mb-2 bg-gray-300 rounded"></div>
                              <div className="w-16 h-3 bg-gray-200 rounded"></div>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <FaUserFriends
                              className={`${
                                recipient === "employees"
                                  ? "text-white"
                                  : "text-gray-500"
                              } text-lg mr-3`}
                            />
                            <div>
                              <div className="font-medium">Employees</div>
                              <div className="mt-1 text-xs">
                                {employeeNums.length} contacts
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div
                        className={`border rounded-lg p-3 cursor-pointer transition-all duration-200 hover:shadow-sm ${
                          recipient === "advisors"
                            ? "bg-gray-800 text-white"
                            : "bg-white text-gray-700 border-gray-200 hover:border-gray-300"
                        }`}
                        onClick={() => setRecipient("advisors")}
                      >
                        {isLoading ? (
                          <div className="flex items-center justify-center h-16 animate-pulse">
                            <div className="w-4 h-4 mr-3 bg-gray-300 rounded-full"></div>
                            <div className="flex-1">
                              <div className="w-24 h-4 mb-2 bg-gray-300 rounded"></div>
                              <div className="w-16 h-3 bg-gray-200 rounded"></div>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <FaUserTie
                              className={`${
                                recipient === "advisors"
                                  ? "text-white"
                                  : "text-gray-500"
                              } text-lg mr-3`}
                            />
                            <div>
                              <div className="font-medium">Advisors</div>
                              <div className="mt-1 text-xs">
                                {advisorNums.length} contacts
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div
                        className={`border rounded-lg p-3 cursor-pointer transition-all duration-200 hover:shadow-sm ${
                          recipient === "custom"
                            ? "bg-gray-800 text-white"
                            : "bg-white text-gray-700 border-gray-200 hover:border-gray-300"
                        }`}
                        onClick={() => setRecipient("custom")}
                      >
                        <div className="flex items-center">
                          <div
                            className={`text-lg mr-3 ${
                              recipient === "custom"
                                ? "text-white"
                                : "text-gray-500"
                            }`}
                          >
                            ➕
                          </div>
                          <div>
                            <div className="font-medium">Custom</div>
                            <div className="mt-1 text-xs">Enter manually</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Custom Phone Numbers */}
                  {recipient === "custom" && (
                    <div className="p-4 space-y-2 border border-gray-200 rounded-lg bg-gray-50">
                      <label
                        htmlFor="phoneNumber"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Custom Phone Numbers:
                      </label>
                      <textarea
                        id="phoneNumber"
                        value={customPhoneNumbers}
                        onChange={(e) => setCustomPhoneNumbers(e.target.value)}
                        placeholder="Enter phone numbers separated by commas or new lines"
                        rows={3}
                        className="w-full p-2.5 text-gray-700 bg-white border border-gray-300 rounded-lg focus:ring-1 focus:ring-gray-500 focus:border-gray-500 transition-all duration-200"
                      />
                      <p className="mt-1 text-xs text-gray-500">
                        Enter 10-digit phone numbers separated by commas or new
                        lines
                      </p>
                    </div>
                  )}

                  {/* Delivery Methods */}
                  <div className="space-y-3">
                    <label className="block text-sm font-medium text-gray-700">
                      Delivery Method:
                    </label>
                    <div className="space-y-3 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4">
                      <div
                        className={`p-3 border rounded-lg transition-all ${
                          deliveryMethods.textMessage
                            ? "border-gray-800 bg-gray-50"
                            : "border-gray-200 bg-white"
                        }`}
                      >
                        <label className="flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="w-4 h-4 mr-3 text-gray-800 border-gray-300 rounded focus:ring-gray-500"
                            checked={deliveryMethods.textMessage}
                            onChange={() =>
                              handleDeliveryMethodChange("textMessage")
                            }
                          />
                          <div className="flex items-center">
                            <MdMessage
                              className={`text-lg mr-2 ${
                                deliveryMethods.textMessage
                                  ? "text-gray-800"
                                  : "text-gray-400"
                              }`}
                            />
                            <span className="text-sm text-gray-700">
                              Text Message
                            </span>
                          </div>
                        </label>
                      </div>

                      <div className="p-3 border border-gray-200 rounded-lg bg-gray-50 opacity-60">
                        <label className="flex items-center cursor-not-allowed">
                          <input
                            type="checkbox"
                            className="w-4 h-4 mr-3 text-gray-800 border-gray-300 rounded focus:ring-gray-500"
                            checked={deliveryMethods.whatsappMessage}
                            disabled
                          />
                          <div className="flex items-center">
                            <MdWhatsapp className="mr-2 text-lg text-gray-400" />
                            <span className="text-sm text-gray-700">
                              WhatsApp Message
                            </span>
                          </div>
                        </label>
                      </div>

                      <div className="p-3 border border-gray-200 rounded-lg bg-gray-50 opacity-60">
                        <label className="flex items-center cursor-not-allowed">
                          <input
                            type="checkbox"
                            className="w-4 h-4 mr-3 text-gray-800 border-gray-300 rounded focus:ring-gray-500"
                            checked={deliveryMethods.email}
                            disabled
                          />
                          <div className="flex items-center">
                            <MdEmail className="mr-2 text-lg text-gray-400" />
                            <span className="text-sm text-gray-700">Email</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Subject (only visible if email is checked) */}
                  {deliveryMethods.email && (
                    <div className="space-y-2">
                      <label
                        htmlFor="subject"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email Subject:
                      </label>
                      <input
                        id="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        className="w-full p-2.5 text-gray-700 bg-white border border-gray-300 rounded-lg focus:ring-1 focus:ring-gray-500 focus:border-gray-500 transition-all duration-200"
                        placeholder="Enter email subject line"
                      />
                    </div>
                  )}

                  {/* Phone Numbers Display */}
                  <div className="space-y-2">
                    <div className="flex items-center justify-between">
                      <div>
                        <label
                          htmlFor="messageBody"
                          className="text-sm font-medium text-gray-700"
                        >
                          Phone Numbers
                        </label>
                        <p className="text-xs text-gray-500">
                          {getRecipientCount()} contacts selected
                        </p>
                      </div>
                      <button
                        type="button"
                        onClick={copyToClipboard}
                        className="px-3 py-1.5 text-white bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 transition-all duration-200 text-sm"
                      >
                        <div className="flex items-center">
                          <FaCopy className="mr-1.5" />
                          <span>Copy Numbers</span>
                        </div>
                      </button>
                    </div>
                    <div className="relative mt-2">
                      {isLoading && (
                        <div className="flex items-center justify-center w-full h-32">
                          <div className="w-full space-y-2 animate-pulse">
                            <div className="w-3/4 h-3 bg-gray-200 rounded"></div>
                            <div className="w-1/2 h-3 bg-gray-200 rounded"></div>
                            <div className="w-2/3 h-3 bg-gray-200 rounded"></div>
                            <div className="w-1/3 h-3 bg-gray-200 rounded"></div>
                          </div>
                        </div>
                      )}
                      {!isLoading && (
                        <textarea
                          id="messageBody"
                          value={getDisplayedNumbers()}
                          rows={8}
                          className="w-full p-3 font-mono text-sm text-gray-700 transition-all duration-200 border border-gray-300 rounded-lg bg-gray-50 focus:ring-1 focus:ring-gray-500 focus:border-gray-500"
                          readOnly
                        />
                      )}
                    </div>
                  </div>

                  {/* Action Button */}
                  <div className="flex justify-end pt-4 border-t border-gray-100">
                    <button
                      type="button"
                      onClick={navigateToFast2SMS}
                      className="px-4 py-2.5 text-white bg-red-600 hover:bg-red-700 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 transition-all duration-200 flex items-center"
                    >
                      <IoNavigate className="mr-2" />
                      <span className="font-medium">Navigate to Fast2SMS</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
}
