import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./utils/authProvider";
import AdminLogin from "./pages/admin/index";
import Dashboard from "./pages/admin/dashboard";
import Advisors from "./pages/admin/advisors";
import AddAdvisor from "./pages/admin/add-advisor";
import Home from "./pages/home";
import UserLogin from "./pages/user";
import Customers from "./pages/admin/customers";
import Users from "./pages/admin/users";
import UserCustomers from "./pages/user/customers";
import Messaging from "./pages/admin/messaging";
import Orders from "./pages/admin/orders";
import AddOrder from "./pages/admin/add-order";
import EditOrder from "./pages/admin/edit-order";
import UserOrders from "./pages/user/orders";
import NotFound from "./pages/404";
import UserDashboardWrapper from "./pages/user/dashboard";
import EmpVerify from "./pages/empVerify";
import AdvisorOrders from "./pages/admin/advisor-orders";
import { Bill } from "./pages/admin/bill";
import { Billing } from "./pages/admin/billing";
import SolarRoofTop from "./pages/admin/solar-roof-top";

export default function App() {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin">
            <Route index element={<AdminLogin />} />
            <Route path="dashboard" element={<Dashboard />} />
            {/* <Route path="advisors" element={<Advisors />} /> */}
            <Route path="advisor/orders/:id" element={<AdvisorOrders />} />
            <Route path="employees" element={<Advisors />} />
            {/* <Route path="add-advisor" element={<AddAdvisor />} /> */}
            <Route path="add-employee" element={<AddAdvisor />} />
            <Route path="customers" element={<Customers />} />
            <Route path="orders" element={<Orders />} />
            <Route path="add-order" element={<AddOrder />} />
            <Route path="edit-order/:id" element={<EditOrder />} />
            <Route path="messaging" element={<Messaging />} />
            <Route path="users" element={<Users />} />
            <Route path="bill/:id" element={<Bill />} />
            <Route path="billing" element={<Billing />} />
            <Route path="solar-roof-top" element={<SolarRoofTop />} />
          </Route>
          <Route path="/user">
            <Route index element={<UserLogin />} />
            <Route path="dashboard" element={<UserDashboardWrapper />} />
            <Route path="customers" element={<UserCustomers />} />
            <Route path="orders" element={<UserOrders />} />
          </Route>
          <Route path="/empverify" element={<EmpVerify />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <ToastContainer />
      </AuthProvider>
    </>
  );
}
