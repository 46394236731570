import React, { useContext, useEffect, useState } from "react";
import UserProtectedRoute from "../../components/UserProtectedRoute";
import UserLayout from "../../components/UserLayout";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { FaSpinner, FaUpload, FaIdCard, FaBriefcase } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase/firebaseConfig";
import { toastOptions } from "../../utils/helpers";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { AuthContext } from "../../utils/authContext";
import LayoutHeader from "../../components/LayoutHeader";
import { DEFAULT_PHOTO_URL } from "../../utils/constants";
import { MdPayment, MdDescription } from "react-icons/md";
import { HiUser, HiOutlineDocumentDuplicate } from "react-icons/hi";

export default function UserOrders() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const storage = getStorage();

  // State variables organized by category
  // Modal and UI state
  const [showModal, setShowModal] = useState(false);
  const [upiPaymentMethod, setUpiPaymentMethod] = useState("");
  const [addBtnClicked, setAddBtnClicked] = useState(false);
  const [customerImageUploading, setCustomerImageUploading] = useState(false);
  const [guarantorImageUploading, setGuarantorImageUploading] = useState(false);

  // Business details
  const [firmName, setFirmName] = useState("jk_solar_energy");
  const [advisorCode, setAdvisorCode] = useState("");
  const [advisorName, setAdvisorName] = useState("");
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  // Customer details
  const [customerName, setCustomerName] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerPhoneNo, setCustomerPhoneNo] = useState("");
  const [customerAlternatePhoneNo, setCustomerAlternatePhoneNo] = useState("");
  const [customerAadharNo, setCustomerAadharNo] = useState("");
  const [customerPanNo, setCustomerPanNo] = useState("");
  const [customerParentage, setCustomerParentage] = useState("");
  const [customerBankName, setCustomerBankName] = useState("");
  const [customerBankAccountNo, setCustomerBankAccountNo] = useState("");
  const [customerBankIfscCode, setCustomerBankIfscCode] = useState("");
  const [customerPhotoUrl, setCustomerPhotoUrl] = useState("");
  const [customerOccupation, setCustomerOccupation] = useState("");
  const [customerQualification, setCustomerQualification] = useState("");

  // Guarantor details and toggle
  const [guarantor, setGuarantor] = useState(false);
  const [guarantorName, setGuarantorName] = useState("");
  const [guarantorPanNo, setGuarantorPanNo] = useState("");
  const [guarantorAadharNo, setGuarantorAadharNo] = useState("");
  const [guarantorAddress, setGuarantorAddress] = useState("");
  const [guarantorBankAccountNo, setGuarantorBankAccountNo] = useState("");
  const [guarantorBankName, setGuarantorBankName] = useState("");
  const [guarantorIfscCode, setGuarantorIfscCode] = useState("");
  const [guarantorParentage, setGuarantorParentage] = useState("");
  const [guarantorPhoneNo, setGuarantorPhoneNo] = useState("");
  const [guarantorPhotoUrl, setGuarantorPhotoUrl] = useState("");
  const [guarantorOccupation, setGuarantorOccupation] = useState("");
  const [guarantorQualification, setGuarantorQualification] = useState("");

  // Finance details and toggle
  const [finance, setFinance] = useState(false);
  const [financedBy, setFinancedBy] = useState("");

  // Order details
  const [orderDetails, setOrderDetails] = useState("");
  const [orderCost, setOrderCost] = useState("");
  const [orderNotes, setOrderNotes] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [performaBillNo, setPerformaBillNo] = useState("");
  const [challanNo, setChallanNo] = useState("");
  const [commission, setCommission] = useState("");

  // Payment details
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [downpayment, setDownpayment] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [chequeNumber, setChequeNumber] = useState("");
  const [chequeDate, setChequeDate] = useState("");
  const [chequeBankName, setChequeBankName] = useState("");

  // Payment method handlers
  const handlePaymentClick = (method) => {
    setUpiPaymentMethod(method);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setAddBtnClicked(true);

    try {
      // Prepare data object
      const data = {
        firm_name: firmName,
        advisor_code: advisorCode === "" ? "N/A" : advisorCode,
        advisor_name: advisorName === "" ? "N/A" : advisorName,
        customer_name: customerName,
        customer_address: customerAddress,
        customer_phone_no: customerPhoneNo,
        customer_alternate_phone_no:
          customerAlternatePhoneNo === "" ? "N/A" : customerAlternatePhoneNo,
        customer_aadhar_no: customerAadharNo === "" ? "N/A" : customerAadharNo,
        customer_pan_no: customerPanNo === "" ? "N/A" : customerPanNo,
        customer_parentage: customerParentage,
        customer_bank_account_no:
          customerBankAccountNo === "" ? "N/A" : customerBankAccountNo,
        customer_bank_name: customerBankName === "" ? "N/A" : customerBankName,
        customer_bank_ifsc_code:
          customerBankIfscCode === "" ? "N/A" : customerBankIfscCode,
        customer_photo_url: customerPhotoUrl,
        customer_occupation: customerOccupation,
        customer_qualification: customerQualification,
        guarantor: guarantor,
        guarantor_name: guarantorName,
        guarantor_pan_no: guarantorPanNo,
        guarantor_aadhar_no: guarantorAadharNo,
        guarantor_address: guarantorAddress,
        guarantor_bank_account_no: guarantorBankAccountNo,
        guarantor_bank_name: guarantorBankName,
        guarantor_bank_ifsc_code: guarantorIfscCode,
        guarantor_parentage: guarantorParentage,
        guarantor_phone_no: guarantorPhoneNo,
        guarantor_photo_url: guarantorPhotoUrl,
        guarantor_occupation: guarantorOccupation,
        guarantor_qualification: guarantorQualification,
        finance: finance,
        financed_by: financedBy,
        order_details: orderDetails,
        order_date: orderDate,
        order_cost: orderCost,
        order_notes: orderNotes,
        payment_method: paymentMethod,
        downpayment: downpayment === "" ? "N/A" : downpayment,
        transaction_id: transactionId,
        chequeNumber: chequeNumber,
        chequeDate: chequeDate,
        chequeBankName: chequeBankName,
        advisor: true,
        order_status: "pending",
        customer_id: `${
          firmName === "jk_solar_energy" ? "JKSE" : "JKSS"
        }-CUS-${customerPhoneNo}`,
        performaBillNo: performaBillNo,
        challanNo: challanNo === "" ? "N/A" : challanNo,
        commission: commission === "" ? "N/A" : commission,
        created_at: serverTimestamp(),
      };

      const tempData = {
        customer_name: customerName,
        created_at: serverTimestamp(),
      };

      // Create new order document
      const newDocRef = doc(collection(db, "orders"));
      await setDoc(newDocRef, data);

      // Add to temp_orders collection
      const tempDocRef = doc(
        collection(db, `temp_orders/${currentUser.uid}/orders`)
      );
      await setDoc(tempDocRef, tempData);

      // Set firm code
      const firmCode = firmName === "jk_solar_energy" ? "JKSE" : "JKSS";

      // Check if customer exists in customers collection
      const customerDocRef = doc(
        db,
        "customers",
        `${firmCode}-CUS-${customerPhoneNo}`
      );
      const customerDoc = await getDoc(customerDocRef);

      // If customer doesn't exist, add to customers collection
      if (!customerDoc.exists()) {
        const customerData = {
          customer_name: customerName,
          customer_phone_no: customerPhoneNo,
          photoUrl: customerPhotoUrl,
          firmName: firmName,
          createdAt: serverTimestamp(),
        };
        await setDoc(customerDocRef, customerData);
      }

      setAddBtnClicked(false);
      toast.success("Order added successfully!", toastOptions);
      navigate("/user/customers");
    } catch (error) {
      setAddBtnClicked(false);
      console.error(error);
      toast.error("Failed to add order. Please try again.", toastOptions);
    }
  };

  // Fetch all employees
  const getAllEmployees = async () => {
    try {
      const employees = [];
      const employeesRef = collection(db, "employee_codes");
      const employeesSnapshot = await getDocs(employeesRef);

      employeesSnapshot.forEach((doc) => {
        employees.push({
          employee_name: doc.data().employee_name,
          employee_code: doc.data().employee_code,
        });
      });

      // Add "Others" option
      employees.push({
        employee_name: "Others",
        employee_code: "Others",
      });

      setEmployees(employees);
    } catch (error) {
      console.error("Failed to fetch employees:", error);
      toast.error("Failed to load employee data");
    }
  };

  // Initialize default values
  useEffect(() => {
    setCustomerPhotoUrl(DEFAULT_PHOTO_URL);
    setGuarantorPhotoUrl(DEFAULT_PHOTO_URL);
    getAllEmployees();
  }, []);

  // Handle image upload
  const handleImageUpload = (e, setPhotoUrl, setImageUploading) => {
    try {
      const file = e.target.files[0];

      if (!file) {
        toast.error("Please choose a file first!", toastOptions);
        return;
      }

      setImageUploading(true);

      const timestamp = Date.now();
      const randomNum = Math.floor(Math.random() * 1000);
      const storageRef = ref(storage, `/orders/${timestamp}-${randomNum}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Progress monitoring can be added here
        },
        (err) => {
          console.error(err);
          setImageUploading(false);
          toast.error("Image upload failed. Please try again.", toastOptions);
        },
        async () => {
          try {
            const downloadUrl = await getDownloadURL(uploadTask?.snapshot?.ref);
            setPhotoUrl(downloadUrl);
          } catch (error) {
            console.error("Failed to get download URL:", error);
            setPhotoUrl(DEFAULT_PHOTO_URL);
          } finally {
            setImageUploading(false);
          }
        }
      );
    } catch (error) {
      setPhotoUrl(DEFAULT_PHOTO_URL);
      setImageUploading(false);
      console.error(error);
      toast.error("Error processing image. Please try again.", toastOptions);
    }
  };

  const handleCustomerImageUpload = (e) => {
    handleImageUpload(e, setCustomerPhotoUrl, setCustomerImageUploading);
  };

  const handleGuarantorImageUpload = (e) => {
    handleImageUpload(e, setGuarantorPhotoUrl, setGuarantorImageUploading);
  };

  // Handle advisor name change and filtering
  const handleAdvisorNameChange = (e) => {
    const inputName = e.target.value;
    setAdvisorName(inputName);

    if (inputName.trim() === "") {
      setFilteredEmployees([]);
      return;
    }

    const filtered = employees.filter((employee) =>
      employee.employee_name.toLowerCase().includes(inputName.toLowerCase())
    );
    setFilteredEmployees(filtered);
  };

  const handleEmployeeSelection = (selectedEmployee) => {
    setAdvisorName(selectedEmployee.employee_name);
    setAdvisorCode(selectedEmployee.employee_code);
    setFilteredEmployees([]); // Clear the suggestions
  };

  // Form input classes for consistency
  const inputClass =
    "w-full px-4 py-2 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent";
  const labelClass = "block mb-2 font-semibold text-gray-700";
  const selectClass =
    "w-full px-4 py-2 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500";
  const checkboxClass =
    "w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500";
  const sectionClass = "p-6 mb-6 bg-white rounded-lg shadow-sm";
  const buttonClass =
    "px-6 py-2 font-medium text-white bg-blue-900 rounded-lg hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50 transition duration-200";

  return (
    <UserProtectedRoute>
      <UserLayout>
        <div className="pb-12 mx-auto max-w-7xl">
          <LayoutHeader
            title="Add New Order"
            subtitle="Create a new customer order"
          />

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Business Information Section */}
            <section className={sectionClass}>
              <h2 className="flex items-center mb-4 text-xl font-semibold text-blue-900">
                <HiOutlineDocumentDuplicate className="mr-2" size={24} />
                Business Information
              </h2>

              <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2 lg:grid-cols-3">
                <div>
                  <label className={labelClass} htmlFor="firmName">
                    Firm Name*
                  </label>
                  <select
                    id="firmName"
                    required
                    value={firmName ?? ""}
                    onChange={(e) => setFirmName(e.target.value)}
                    className={selectClass}
                  >
                    <option value="">Select Firm Name</option>
                    <option value="jk_solar_energy">JK Solar Energy</option>
                    <option value="jk_sale_and_service">
                      JK Sale and Service Agency
                    </option>
                  </select>
                </div>

                <div>
                  <label className={labelClass} htmlFor="advisorCode">
                    Advisor Code
                  </label>
                  <input
                    type="text"
                    id="advisorCode"
                    maxLength={8}
                    placeholder="Optional"
                    value={advisorCode ?? ""}
                    onChange={(e) =>
                      setAdvisorCode(e.target.value.toUpperCase())
                    }
                    className={inputClass}
                  />
                </div>

                <div className="relative">
                  <label className={labelClass} htmlFor="advisorName">
                    Advisor Name
                  </label>
                  <input
                    type="text"
                    id="advisorName"
                    placeholder="Optional"
                    value={advisorName ?? ""}
                    onChange={handleAdvisorNameChange}
                    className={inputClass}
                  />
                  {filteredEmployees.length > 0 && (
                    <div className="absolute z-10 w-full mt-1">
                      <ul className="overflow-y-auto bg-white border border-gray-300 rounded-md shadow-lg max-h-48">
                        {filteredEmployees.map((employee) => (
                          <li
                            key={employee.employee_code}
                            className="px-4 py-2 transition-colors cursor-pointer hover:bg-gray-100"
                            onClick={() => handleEmployeeSelection(employee)}
                          >
                            {employee.employee_name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <div>
                  <label className={labelClass} htmlFor="orderDate">
                    Order Date*
                  </label>
                  <input
                    type="date"
                    id="orderDate"
                    required
                    value={orderDate ?? ""}
                    onChange={(e) => setOrderDate(e.target.value)}
                    className={inputClass}
                  />
                </div>
              </div>
            </section>

            {/* Customer Information Section */}
            <section className={sectionClass}>
              <h2 className="flex items-center mb-4 text-xl font-semibold text-blue-900">
                <HiUser className="mr-2" size={24} />
                Customer Information
              </h2>

              <div className="grid grid-cols-1 gap-6 lg:grid-cols-4">
                <div className="lg:col-span-3">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
                    <div>
                      <label className={labelClass} htmlFor="customerName">
                        Customer Name*
                      </label>
                      <input
                        type="text"
                        id="customerName"
                        required
                        placeholder="Enter Customer Name"
                        value={customerName ?? ""}
                        onChange={(e) => setCustomerName(e.target.value)}
                        className={inputClass}
                      />
                    </div>

                    <div>
                      <label className={labelClass} htmlFor="customerParentage">
                        Customer Parentage*
                      </label>
                      <input
                        type="text"
                        id="customerParentage"
                        required
                        placeholder="Enter Customer Parentage"
                        value={customerParentage ?? ""}
                        onChange={(e) => setCustomerParentage(e.target.value)}
                        className={inputClass}
                      />
                    </div>

                    <div className="md:col-span-2">
                      <label className={labelClass} htmlFor="customerAddress">
                        Customer Address*
                      </label>
                      <input
                        type="text"
                        id="customerAddress"
                        required
                        placeholder="Enter Customer Address"
                        value={customerAddress ?? ""}
                        onChange={(e) => setCustomerAddress(e.target.value)}
                        className={inputClass}
                      />
                    </div>

                    <div>
                      <label className={labelClass} htmlFor="customerPhoneNo">
                        Customer Phone No*
                      </label>
                      <input
                        type="text"
                        maxLength={10}
                        id="customerPhoneNo"
                        required
                        placeholder="Enter Customer Phone Number"
                        value={customerPhoneNo ?? ""}
                        onChange={(e) => setCustomerPhoneNo(e.target.value)}
                        className={inputClass}
                      />
                    </div>

                    <div>
                      <label
                        className={labelClass}
                        htmlFor="customerAlternatePhoneNo"
                      >
                        Customer Alternate Phone No
                      </label>
                      <input
                        type="text"
                        maxLength={10}
                        id="customerAlternatePhoneNo"
                        placeholder="Optional"
                        value={customerAlternatePhoneNo ?? ""}
                        onChange={(e) =>
                          setCustomerAlternatePhoneNo(e.target.value)
                        }
                        className={inputClass}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-center justify-center lg:col-span-1">
                  <div className="relative w-32 h-32 mb-4 overflow-hidden border-2 border-gray-300 rounded-lg">
                    <img
                      src={customerPhotoUrl}
                      alt="Customer"
                      className="object-cover w-full h-full"
                    />
                  </div>

                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleCustomerImageUpload}
                      className="hidden"
                      id="customer-image-upload"
                      disabled={customerImageUploading}
                    />
                    <label
                      htmlFor="customer-image-upload"
                      className={`flex items-center justify-center ${buttonClass} ${
                        customerImageUploading
                          ? "opacity-70 cursor-not-allowed"
                          : ""
                      }`}
                    >
                      {customerImageUploading ? (
                        <>
                          <FaSpinner className="mr-2 animate-spin" size={16} />
                          Uploading...
                        </>
                      ) : (
                        <>
                          <FaUpload className="mr-2" size={16} />
                          Upload Photo
                        </>
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 mt-6 gap-x-6 gap-y-4 md:grid-cols-2 lg:grid-cols-3">
                <div>
                  <label className={labelClass} htmlFor="customerQualification">
                    Customer Qualification*
                  </label>
                  <input
                    type="text"
                    id="customerQualification"
                    placeholder="Enter Customer Qualification"
                    required
                    value={customerQualification ?? ""}
                    onChange={(e) => setCustomerQualification(e.target.value)}
                    className={inputClass}
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="customerOccupation">
                    Customer Occupation*
                  </label>
                  <input
                    type="text"
                    id="customerOccupation"
                    placeholder="Enter Customer Occupation"
                    required
                    value={customerOccupation ?? ""}
                    onChange={(e) => setCustomerOccupation(e.target.value)}
                    className={inputClass}
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="customerPanNo">
                    Customer PAN No
                  </label>
                  <input
                    type="text"
                    id="customerPanNo"
                    placeholder="Optional"
                    value={customerPanNo ?? ""}
                    onChange={(e) => setCustomerPanNo(e.target.value)}
                    className={inputClass}
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="customerAadharNo">
                    Customer Aadhar No
                  </label>
                  <input
                    type="text"
                    id="customerAadharNo"
                    placeholder="Optional"
                    value={customerAadharNo ?? ""}
                    onChange={(e) => setCustomerAadharNo(e.target.value)}
                    className={inputClass}
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="customerBankAccountNo">
                    Customer Bank Account No
                  </label>
                  <input
                    type="text"
                    id="customerBankAccountNo"
                    placeholder="Optional"
                    value={customerBankAccountNo ?? ""}
                    onChange={(e) => setCustomerBankAccountNo(e.target.value)}
                    className={inputClass}
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="customerBankName">
                    Customer Bank Name
                  </label>
                  <input
                    type="text"
                    id="customerBankName"
                    placeholder="Optional"
                    value={customerBankName ?? ""}
                    onChange={(e) => setCustomerBankName(e.target.value)}
                    className={inputClass}
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="customerBankIfscCode">
                    Customer Bank IFSC Code
                  </label>
                  <input
                    type="text"
                    id="customerBankIfscCode"
                    placeholder="Optional"
                    value={customerBankIfscCode ?? ""}
                    onChange={(e) => setCustomerBankIfscCode(e.target.value)}
                    className={inputClass}
                  />
                </div>
              </div>
            </section>

            {/* Guarantor Information Section */}
            <section className={sectionClass}>
              <div className="flex items-center mb-4">
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className={checkboxClass}
                    checked={guarantor ?? false}
                    onChange={(e) => setGuarantor(e.target.checked)}
                  />
                  <span className="flex items-center ml-2 text-lg font-semibold text-blue-900">
                    <FaIdCard className="mr-2" size={20} />
                    Guarantor Information
                  </span>
                </label>
              </div>

              {guarantor && (
                <div className="mt-4 animate-fadeIn">
                  <div className="grid grid-cols-1 gap-6 lg:grid-cols-4">
                    <div className="lg:col-span-3">
                      <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
                        <div>
                          <label className={labelClass} htmlFor="guarantorName">
                            Guarantor Name
                          </label>
                          <input
                            type="text"
                            id="guarantorName"
                            placeholder="Enter Guarantor Name"
                            value={guarantorName ?? ""}
                            onChange={(e) => setGuarantorName(e.target.value)}
                            className={inputClass}
                          />
                        </div>

                        <div>
                          <label
                            className={labelClass}
                            htmlFor="guarantorParentage"
                          >
                            Guarantor Parentage
                          </label>
                          <input
                            type="text"
                            id="guarantorParentage"
                            placeholder="Enter Guarantor Parentage"
                            value={guarantorParentage ?? ""}
                            onChange={(e) =>
                              setGuarantorParentage(e.target.value)
                            }
                            className={inputClass}
                          />
                        </div>

                        <div className="md:col-span-2">
                          <label
                            className={labelClass}
                            htmlFor="guarantorAddress"
                          >
                            Guarantor Address
                          </label>
                          <input
                            type="text"
                            id="guarantorAddress"
                            placeholder="Enter Guarantor Address"
                            value={guarantorAddress ?? ""}
                            onChange={(e) =>
                              setGuarantorAddress(e.target.value)
                            }
                            className={inputClass}
                          />
                        </div>

                        <div>
                          <label
                            className={labelClass}
                            htmlFor="guarantorPhoneNo"
                          >
                            Guarantor Phone No
                          </label>
                          <input
                            type="text"
                            id="guarantorPhoneNo"
                            placeholder="Enter Guarantor Phone Number"
                            value={guarantorPhoneNo ?? ""}
                            onChange={(e) =>
                              setGuarantorPhoneNo(e.target.value)
                            }
                            className={inputClass}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col items-center justify-center lg:col-span-1">
                      <div className="relative w-32 h-32 mb-4 overflow-hidden border-2 border-gray-300 rounded-lg">
                        <img
                          src={guarantorPhotoUrl}
                          alt="Guarantor"
                          className="object-cover w-full h-full"
                        />
                      </div>

                      <div>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleGuarantorImageUpload}
                          className="hidden"
                          id="guarantor-image-upload"
                          disabled={guarantorImageUploading}
                        />
                        <label
                          htmlFor="guarantor-image-upload"
                          className={`flex items-center justify-center ${buttonClass} ${
                            guarantorImageUploading
                              ? "opacity-70 cursor-not-allowed"
                              : ""
                          }`}
                        >
                          {guarantorImageUploading ? (
                            <>
                              <FaSpinner
                                className="mr-2 animate-spin"
                                size={16}
                              />
                              Uploading...
                            </>
                          ) : (
                            <>
                              <FaUpload className="mr-2" size={16} />
                              Upload Photo
                            </>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 mt-6 gap-x-6 gap-y-4 md:grid-cols-2 lg:grid-cols-3">
                    <div>
                      <label
                        className={labelClass}
                        htmlFor="guarantorQualification"
                      >
                        Guarantor Qualification
                      </label>
                      <input
                        type="text"
                        id="guarantorQualification"
                        placeholder="Enter Guarantor Qualification"
                        value={guarantorQualification ?? ""}
                        onChange={(e) =>
                          setGuarantorQualification(e.target.value)
                        }
                        className={inputClass}
                      />
                    </div>

                    <div>
                      <label
                        className={labelClass}
                        htmlFor="guarantorOccupation"
                      >
                        Guarantor Occupation
                      </label>
                      <input
                        type="text"
                        id="guarantorOccupation"
                        placeholder="Enter Guarantor Occupation"
                        value={guarantorOccupation ?? ""}
                        onChange={(e) => setGuarantorOccupation(e.target.value)}
                        className={inputClass}
                      />
                    </div>

                    <div>
                      <label className={labelClass} htmlFor="guarantorPanNo">
                        Guarantor PAN No
                      </label>
                      <input
                        type="text"
                        id="guarantorPanNo"
                        placeholder="Enter Guarantor PAN No"
                        value={guarantorPanNo ?? ""}
                        onChange={(e) => setGuarantorPanNo(e.target.value)}
                        className={inputClass}
                      />
                    </div>

                    <div>
                      <label className={labelClass} htmlFor="guarantorAadharNo">
                        Guarantor Aadhar No
                      </label>
                      <input
                        type="text"
                        id="guarantorAadharNo"
                        placeholder="Enter Guarantor Aadhar No"
                        value={guarantorAadharNo ?? ""}
                        onChange={(e) => setGuarantorAadharNo(e.target.value)}
                        className={inputClass}
                      />
                    </div>

                    <div>
                      <label
                        className={labelClass}
                        htmlFor="guarantorBankAccountNo"
                      >
                        Guarantor Bank Account No
                      </label>
                      <input
                        type="text"
                        id="guarantorBankAccountNo"
                        placeholder="Enter Guarantor Bank Account No"
                        value={guarantorBankAccountNo ?? ""}
                        onChange={(e) =>
                          setGuarantorBankAccountNo(e.target.value)
                        }
                        className={inputClass}
                      />
                    </div>

                    <div>
                      <label className={labelClass} htmlFor="guarantorBankName">
                        Guarantor Bank Name
                      </label>
                      <input
                        type="text"
                        id="guarantorBankName"
                        placeholder="Enter Guarantor Bank Name"
                        value={guarantorBankName ?? ""}
                        onChange={(e) => setGuarantorBankName(e.target.value)}
                        className={inputClass}
                      />
                    </div>

                    <div>
                      <label className={labelClass} htmlFor="guarantorIfscCode">
                        Guarantor Bank IFSC Code
                      </label>
                      <input
                        type="text"
                        id="guarantorIfscCode"
                        placeholder="Enter Guarantor Bank IFSC Code"
                        value={guarantorIfscCode ?? ""}
                        onChange={(e) => setGuarantorIfscCode(e.target.value)}
                        className={inputClass}
                      />
                    </div>
                  </div>
                </div>
              )}
            </section>

            {/* Finance Section */}
            <section className={sectionClass}>
              <div className="flex items-center mb-4">
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className={checkboxClass}
                    checked={finance ?? false}
                    onChange={(e) => setFinance(e.target.checked)}
                  />
                  <span className="flex items-center ml-2 text-lg font-semibold text-blue-900">
                    <FaBriefcase className="mr-2" size={20} />
                    Financing Options
                  </span>
                </label>
              </div>

              {finance && (
                <div className="mt-4 animate-fadeIn">
                  <div className="max-w-md">
                    <label className={labelClass} htmlFor="financedBy">
                      Finance Partner
                    </label>
                    <select
                      id="financedBy"
                      value={financedBy ?? ""}
                      onChange={(e) => setFinancedBy(e.target.value)}
                      className={selectClass}
                    >
                      <option value="">Select Finance Partner</option>
                      <option value="j_and_k_bank">J&K Bank</option>
                      <option value="sbi_bank">SBI Bank</option>
                      <option value="hdfc_bank">HDFC Bank</option>
                      <option value="bajaj_finserv">Bajaj Finserv</option>
                      <option value="samsung_finance">Samsung Finance</option>
                    </select>
                  </div>
                </div>
              )}
            </section>

            {/* Order Details Section */}
            <section className={sectionClass}>
              <h2 className="flex items-center mb-4 text-xl font-semibold text-blue-900">
                <MdDescription className="mr-2" size={24} />
                Order Details
              </h2>

              <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
                <div className="lg:col-span-2">
                  <label className={labelClass} htmlFor="orderDetails">
                    Order Details*
                  </label>
                  <textarea
                    id="orderDetails"
                    required
                    rows={4}
                    placeholder="Enter Order Details"
                    value={orderDetails ?? ""}
                    onChange={(e) => setOrderDetails(e.target.value)}
                    className={inputClass}
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="orderCost">
                    Order Cost*
                  </label>
                  <input
                    type="number"
                    id="orderCost"
                    required
                    placeholder="Enter Order Cost"
                    value={orderCost ?? ""}
                    onChange={(e) => setOrderCost(e.target.value)}
                    className={inputClass}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 gap-6 mt-4 lg:grid-cols-3">
                <div className="lg:col-span-2">
                  <label className={labelClass} htmlFor="orderNotes">
                    Order Notes
                  </label>
                  <textarea
                    id="orderNotes"
                    rows={3}
                    placeholder="Enter Order Notes (Optional)"
                    value={orderNotes ?? ""}
                    onChange={(e) => setOrderNotes(e.target.value)}
                    className={inputClass}
                  />
                </div>

                <div className="flex flex-col space-y-4">
                  <label className={labelClass}>Quick Payment Options</label>
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={() => handlePaymentClick("googlePay")}
                      type="button"
                      className="flex items-center justify-center p-2 transition duration-200 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                    >
                      <img
                        className="w-auto h-10"
                        src="/assets/gpay_icon.png"
                        alt="Google Pay"
                      />
                    </button>

                    <button
                      onClick={() => handlePaymentClick("phonePay")}
                      type="button"
                      className="flex items-center justify-center p-2 transition duration-200 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                    >
                      <img
                        className="w-auto h-10"
                        src="/assets/phone_pe_icon.png"
                        alt="PhonePe"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </section>

            {/* Payment Section */}
            <section className={sectionClass}>
              <h2 className="flex items-center mb-4 text-xl font-semibold text-blue-900">
                <MdPayment className="mr-2" size={24} />
                Payment Information
              </h2>

              <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2 lg:grid-cols-3">
                <div>
                  <label className={labelClass} htmlFor="paymentMethod">
                    Payment Method*
                  </label>
                  <select
                    id="paymentMethod"
                    value={paymentMethod ?? ""}
                    required
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    className={selectClass}
                  >
                    <option value="">Select Payment Method</option>
                    <option value="cash">Cash</option>
                    <option value="cheque">Cheque</option>
                    <option value="card">Card</option>
                    <option value="upi">UPI</option>
                  </select>
                </div>

                <div>
                  <label className={labelClass} htmlFor="downpayment">
                    Downpayment
                  </label>
                  <input
                    type="number"
                    id="downpayment"
                    placeholder="Optional"
                    value={downpayment ?? ""}
                    onChange={(e) => setDownpayment(e.target.value)}
                    className={inputClass}
                  />
                </div>

                {(paymentMethod === "card" || paymentMethod === "upi") && (
                  <div>
                    <label className={labelClass} htmlFor="transactionId">
                      Transaction ID
                    </label>
                    <input
                      type="text"
                      id="transactionId"
                      placeholder="Enter Transaction ID"
                      value={transactionId ?? ""}
                      onChange={(e) => setTransactionId(e.target.value)}
                      className={inputClass}
                    />
                  </div>
                )}
              </div>

              {paymentMethod === "cheque" && (
                <div className="grid grid-cols-1 mt-4 gap-x-6 gap-y-4 md:grid-cols-2 lg:grid-cols-3">
                  <div>
                    <label className={labelClass} htmlFor="chequeNumber">
                      Cheque Number
                    </label>
                    <input
                      type="text"
                      id="chequeNumber"
                      placeholder="Enter Cheque Number"
                      value={chequeNumber ?? ""}
                      onChange={(e) => setChequeNumber(e.target.value)}
                      className={inputClass}
                    />
                  </div>

                  <div>
                    <label className={labelClass} htmlFor="chequeDate">
                      Cheque Date
                    </label>
                    <input
                      type="date"
                      id="chequeDate"
                      value={chequeDate ?? ""}
                      onChange={(e) => setChequeDate(e.target.value)}
                      className={inputClass}
                    />
                  </div>

                  <div>
                    <label className={labelClass} htmlFor="chequeBankName">
                      Cheque Bank Name
                    </label>
                    <input
                      type="text"
                      id="chequeBankName"
                      placeholder="Enter Cheque Bank Name"
                      value={chequeBankName ?? ""}
                      onChange={(e) => setChequeBankName(e.target.value)}
                      className={inputClass}
                    />
                  </div>
                </div>
              )}
            </section>

            {/* Documentation Section */}
            <section className={sectionClass}>
              <h2 className="flex items-center mb-4 text-xl font-semibold text-blue-900">
                <HiOutlineDocumentDuplicate className="mr-2" size={24} />
                Documentation
              </h2>

              <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2 lg:grid-cols-3">
                <div>
                  <label className={labelClass} htmlFor="performaBillNo">
                    Performa Bill No*
                  </label>
                  <input
                    type="text"
                    id="performaBillNo"
                    required
                    placeholder="Enter Performa Bill No"
                    value={performaBillNo ?? ""}
                    onChange={(e) => setPerformaBillNo(e.target.value)}
                    className={inputClass}
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="challanNo">
                    Challan No
                  </label>
                  <input
                    type="text"
                    id="challanNo"
                    placeholder="Enter Challan No (Optional)"
                    value={challanNo ?? ""}
                    onChange={(e) => setChallanNo(e.target.value)}
                    className={inputClass}
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="commission">
                    Total Commission
                  </label>
                  <input
                    type="text"
                    id="commission"
                    placeholder="Enter Total Commission (Optional)"
                    value={commission ?? ""}
                    onChange={(e) => setCommission(e.target.value)}
                    className={inputClass}
                  />
                </div>
              </div>
            </section>

            {/* Action Buttons */}
            <div className="flex justify-end space-x-4">
              <button
                type="submit"
                disabled={addBtnClicked}
                className={`${buttonClass} ${
                  addBtnClicked ? "opacity-70 cursor-not-allowed" : ""
                }`}
              >
                {addBtnClicked ? (
                  <>
                    <FaSpinner className="mr-2 animate-spin" size={18} />
                    Processing...
                  </>
                ) : (
                  "Submit Order"
                )}
              </button>
            </div>
          </form>

          {/* Payment QR Code Modal */}
          {showModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
              <div className="relative w-full max-w-lg p-6 bg-white rounded-lg shadow-xl">
                <button
                  onClick={handleCloseModal}
                  className="absolute p-1 text-gray-500 transition duration-200 top-4 right-4 hover:text-gray-700"
                  aria-label="Close"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>

                <div className="text-center">
                  <h3 className="mb-4 text-xl font-semibold text-blue-900">
                    {upiPaymentMethod === "googlePay"
                      ? "Google Pay"
                      : "PhonePe"}{" "}
                    Payment
                  </h3>

                  <img
                    src={
                      upiPaymentMethod === "googlePay"
                        ? "/assets/google-pay-qr-code.png"
                        : "/assets/phone-pay-qr-code.png"
                    }
                    alt={
                      upiPaymentMethod === "googlePay"
                        ? "Google Pay QR Code"
                        : "Phone Pay QR Code"
                    }
                    className="h-64 mx-auto md:h-80"
                  />

                  <p className="mt-4 text-gray-600">
                    Scan the QR code to make payment
                  </p>

                  <button
                    onClick={handleCloseModal}
                    className={`mt-6 ${buttonClass}`}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </UserLayout>
    </UserProtectedRoute>
  );
}
