import React from "react";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaBuilding,
  FaIdBadge,
  FaUser,
  FaBriefcase,
} from "react-icons/fa";

const EmployeeDetails = ({
  isVerified,
  isActive,
  employeeName,
  photoUrl,
  branch,
  employeeCode,
  role = "Other", // Default to "Other" if no role provided
}) => {
  // Default avatar if no photo URL is provided
  const defaultAvatar =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  // If not verified, show the not verified message
  if (!isVerified) {
    return (
      <div className="flex flex-col items-center py-6">
        <div className="w-full max-w-md p-4 border border-gray-200 rounded-lg shadow-sm bg-gray-50">
          <div className="flex items-center justify-center space-x-3 text-red-600">
            <FaTimesCircle className="w-5 h-5" />
            <span className="font-medium">Employee code not verified</span>
          </div>
        </div>
      </div>
    );
  }

  // If verified but not active
  if (!isActive) {
    return (
      <div className="flex flex-col items-center py-6">
        <div className="w-full max-w-md p-4 border border-gray-200 rounded-lg shadow-sm bg-gray-50">
          <div className="flex items-center justify-center space-x-3 text-yellow-600">
            <FaTimesCircle className="w-5 h-5" />
            <span className="font-medium">Employee account is inactive</span>
          </div>
        </div>
      </div>
    );
  }

  // If verified and active - show full profile
  return (
    <div className="flex flex-col items-center py-4">
      <div className="w-full max-w-md overflow-hidden bg-white shadow-md rounded-xl">
        {/* Success status header */}
        <div className="px-6 py-3 border-b border-green-100 bg-green-50">
          <div className="flex items-center justify-center space-x-2 text-green-700">
            <FaCheckCircle className="w-5 h-5" />
            <span className="font-medium">Verified Employee</span>
          </div>
        </div>

        {/* Profile content */}
        <div className="p-6">
          {/* Photo */}
          <div className="flex justify-center">
            <div className="relative">
              <img
                src={photoUrl || defaultAvatar}
                alt={employeeName || "Employee"}
                onError={(e) => {
                  e.target.src = defaultAvatar;
                }}
                className="object-cover border-4 border-white rounded-full shadow-lg w-28 h-28"
              />
              <div className="absolute bottom-0 right-0 w-6 h-6 bg-green-500 border-2 border-white rounded-full"></div>
            </div>
          </div>

          {/* Name and details */}
          <div className="mt-5 text-center">
            <h3 className="text-xl font-bold text-gray-800">{employeeName}</h3>

            {/* Details card with better formatting */}
            <div className="mt-5 overflow-hidden border divide-y divide-gray-200 rounded-lg">
              {/* Employee code info */}
              <div className="px-4 py-3 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4">
                <div className="flex items-center text-sm font-medium text-gray-500">
                  <FaIdBadge className="w-4 h-4 mr-2 text-gray-500" />
                  Employee ID
                </div>
                <div className="mt-1 text-sm font-medium text-right text-gray-900 sm:mt-0 sm:col-span-2">
                  {employeeCode}
                </div>
              </div>
              {/* Role info */}
              <div className="px-4 py-3 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4">
                <div className="flex items-center text-sm font-medium text-gray-500">
                  <FaBriefcase className="w-4 h-4 mr-2 text-gray-500" />
                  Role
                </div>
                <div className="mt-1 text-sm font-medium text-right text-gray-900 sm:mt-0 sm:col-span-2">
                  {role}
                </div>
              </div>

              {/* Branch info */}
              <div className="px-4 py-3 bg-white sm:grid sm:grid-cols-3 sm:gap-4">
                <div className="flex items-center text-sm font-medium text-gray-500">
                  <FaBuilding className="w-4 h-4 mr-2 text-gray-500" />
                  Branch
                </div>
                <div className="mt-1 text-sm font-medium text-right text-gray-900 sm:mt-0 sm:col-span-2">
                  {branch}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="px-6 py-3 border-t border-gray-100 bg-gray-50">
          <div className="flex items-center justify-center text-xs text-gray-500">
            <FaUser className="w-3 h-3 mr-1" />
            <span>
              Employee Status:{" "}
              <span className="font-medium text-green-600">Active</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
