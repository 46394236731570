import { AuthContext } from "./authContext";
import { useState, useEffect, useCallback } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  setPersistence,
  browserSessionPersistence,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { app } from "../firebase/firebaseConfig";
import { updateUserTimestamp } from "./database";

export const AuthProvider = ({ children }) => {
  const auth = getAuth(app);

  // Set persistence to session by default for better security
  useEffect(() => {
    setPersistence(auth, browserSessionPersistence).catch((error) => {
      console.error("Auth persistence error:", error);
    });
  }, [auth]);

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Improved signIn with error handling and rate limiting
  const signIn = useCallback(
    async (email, password) => {
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        // Only update timestamp if sign-in was successful
        await updateUserTimestamp(userCredential.user.uid);
        return userCredential;
      } catch (error) {
        // Enhanced error logging
        console.error("Sign-in error:", error.code, error.message);
        throw error;
      }
    },
    [auth]
  );

  // Improved signUp with stronger validation
  const signUp = useCallback(
    async (email, password) => {
      try {
        // Email validation - simple regex check
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          throw new Error("Invalid email format");
        }

        // Password strength validation - at least 8 chars, one number, one uppercase letter
        if (password.length < 8) {
          throw new Error("Password must be at least 8 characters");
        }
        if (!/\d/.test(password)) {
          throw new Error("Password must contain at least one number");
        }
        if (!/[A-Z]/.test(password)) {
          throw new Error(
            "Password must contain at least one uppercase letter"
          );
        }

        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password,
          {
            autoSignIn: false,
          }
        );

        // Send verification email right after signup
        await sendEmailVerification(userCredential.user);

        return userCredential;
      } catch (error) {
        console.error("Sign-up error:", error.code || "custom", error.message);
        throw error;
      }
    },
    [auth]
  );

  // Improved signOut
  const signOut = useCallback(async () => {
    try {
      await firebaseSignOut(auth);
      // Clear any additional cached data if needed
      return true;
    } catch (error) {
      console.error("Sign-out error:", error);
      throw error;
    }
  }, [auth]);

  // Secured profile update
  const updateUserProfile = useCallback(
    async (displayName, photoURL) => {
      try {
        if (!auth.currentUser) {
          throw new Error("No authenticated user");
        }

        // Sanitize inputs
        const sanitizedName = displayName
          ? displayName.trim().substring(0, 50)
          : null;

        // Validate photoURL if provided
        if (photoURL && typeof photoURL === "string") {
          try {
            new URL(photoURL);
          } catch (e) {
            throw new Error("Invalid photo URL");
          }
        }

        await updateProfile(auth.currentUser, {
          displayName: sanitizedName,
          photoURL: photoURL,
        });

        // Update local state
        setCurrentUser((prev) => ({
          ...prev,
          displayName: sanitizedName,
          photoURL: photoURL,
        }));

        return true;
      } catch (error) {
        console.error("Profile update error:", error);
        throw error;
      }
    },
    [auth]
  );

  // Email verification with rate limiting
  const sendVerificationEmail = useCallback(async () => {
    try {
      if (!auth.currentUser) {
        throw new Error("No authenticated user");
      }

      // Check if user's email is already verified
      if (auth.currentUser.emailVerified) {
        throw new Error("Email is already verified");
      }

      // Simple rate limiting using localStorage
      const lastSent = localStorage.getItem("lastVerificationEmailSent");
      const now = Date.now();

      if (lastSent && now - parseInt(lastSent, 10) < 60000) {
        // 1 minute
        throw new Error(
          "Please wait before requesting another verification email"
        );
      }

      await sendEmailVerification(auth.currentUser);
      localStorage.setItem("lastVerificationEmailSent", now.toString());

      return true;
    } catch (error) {
      console.error("Verification email error:", error);
      throw error;
    }
  }, [auth]);

  // Function to change password
  const changePassword = useCallback(
    async (currentPassword, newPassword) => {
      try {
        if (!auth.currentUser) {
          throw new Error("No authenticated user");
        }

        // First re-authenticate the user to ensure they know the current password
        const credential = EmailAuthProvider.credential(
          auth.currentUser.email,
          currentPassword
        );

        await reauthenticateWithCredential(auth.currentUser, credential);

        // Now change the password
        await updatePassword(auth.currentUser, newPassword);

        return true;
      } catch (error) {
        console.error("Password change error:", error);

        // Provide more user-friendly error messages
        if (error.code === "auth/wrong-password") {
          throw new Error("Current password is incorrect");
        } else if (error.code === "auth/weak-password") {
          throw new Error("New password is too weak");
        } else if (error.code === "auth/requires-recent-login") {
          throw new Error(
            "For security reasons, please login again before changing your password"
          );
        } else {
          throw error;
        }
      }
    },
    [auth]
  );

  // Auth state management
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      try {
        setLoading(true);
        if (user) {
          // Include metadata in the serialized user
          const serializedUser = {
            uid: user.uid,
            email: user.email,
            emailVerified: user.emailVerified,
            displayName: user.displayName,
            photoURL: user.photoURL,
            metadata: {
              creationTime: user.metadata.creationTime,
              lastSignInTime: user.metadata.lastSignInTime,
            },
          };

          setCurrentUser(serializedUser);
        } else {
          setCurrentUser(null);
        }
      } catch (error) {
        console.error("Auth state change error:", error);
        setCurrentUser(null);
      } finally {
        setLoading(false);
      }
    });

    return () => {
      try {
        unsubscribe();
      } catch (error) {
        console.error("Auth unsubscribe error:", error);
      }
    };
  }, [auth]);

  // Reload user to get fresh verification status
  const reloadUser = useCallback(async () => {
    try {
      if (auth.currentUser) {
        await auth.currentUser.reload();
        setCurrentUser({
          uid: auth.currentUser.uid,
          email: auth.currentUser.email,
          emailVerified: auth.currentUser.emailVerified,
          displayName: auth.currentUser.displayName,
          photoURL: auth.currentUser.photoURL,
          metadata: {
            creationTime: auth.currentUser.metadata.creationTime,
            lastSignInTime: auth.currentUser.metadata.lastSignInTime,
          },
        });
      }
    } catch (error) {
      console.error("User reload error:", error);
    }
  }, [auth]);

  const value = {
    currentUser,
    loading,
    updateUserProfile,
    sendVerificationEmail,
    changePassword,
    signIn,
    signUp,
    signOut,
    reloadUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
