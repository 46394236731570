import { useState, useEffect, useContext, useCallback } from "react";
import { AuthContext } from "../../utils/authContext";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { toastOptions } from "../../utils/helpers";
import { BsEyeFill, BsEyeSlashFill, BsArrowLeft } from "react-icons/bs";
import { FaSpinner, FaLock, FaEnvelope } from "react-icons/fa";

export default function AdminLogin() {
  const navigate = useNavigate();
  const { currentUser, signIn } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  // Handle back button click
  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  // Handle input changes
  const handleInputChange = useCallback(
    ({ target: { name, value } }) => {
      setFormData((prev) => ({ ...prev, [name]: value }));
      // Clear error when user types
      if (formErrors[name]) {
        setFormErrors((prev) => ({ ...prev, [name]: "" }));
      }
    },
    [formErrors]
  );

  // Toggle password visibility
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  // Validate form
  const validateForm = useCallback(() => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }, [formData]);

  // Handle login submission
  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault();

      if (!validateForm()) return;

      const { email, password } = formData;
      const adminEmail = process.env.REACT_APP_ADMIN_EMAIL;

      if (email !== adminEmail) {
        toast.error("Not authorized", toastOptions);
        return;
      }

      try {
        setIsLoading(true);
        await signIn(email, password);

        // Success message will be shown after redirection
        if (currentUser && currentUser?.email === adminEmail) {
          toast.success("Logged in successfully!", toastOptions);
          navigate("/admin/dashboard");
        }
      } catch (error) {
        const errorCode = error.code;
        if (errorCode === "auth/wrong-password") {
          toast.error("Invalid email or password", toastOptions);
        } else if (errorCode === "auth/user-not-found") {
          toast.error("User not found", toastOptions);
        } else if (errorCode === "auth/too-many-requests") {
          toast.error(
            "Too many failed login attempts. Please try again later",
            toastOptions
          );
        } else {
          toast.error("Login failed. Please try again", toastOptions);
          console.error(errorCode);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [formData, validateForm, currentUser, signIn, navigate]
  );

  // Redirect if already logged in
  useEffect(() => {
    if (
      currentUser &&
      currentUser?.email === process.env.REACT_APP_ADMIN_EMAIL
    ) {
      navigate("/admin/dashboard");
    }
  }, [navigate, currentUser]);

  // If checking auth status
  if (currentUser === undefined) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-[#faa93b] from-15% via-[#FAC954] via-30% to-[#1e3a8a] to-70%">
        <div className="p-8 text-center">
          <div className="inline-block w-16 h-16">
            <FaSpinner className="w-full h-full text-white animate-spin" />
          </div>
          <p className="mt-4 text-lg font-medium text-white">
            Authenticating...
          </p>
        </div>
      </div>
    );
  }

  return (
    <main className="flex items-center justify-center min-h-screen bg-gradient-to-r from-[#faa93b] from-15% via-[#FAC954] via-30% to-[#1e3a8a] to-70% p-4">
      <div className="w-full max-w-md">
        <div className="overflow-hidden bg-white shadow-xl rounded-xl backdrop-blur-sm bg-white/20">
          {/* Back Button */}
          <div className="px-8 pt-4">
            <button
              onClick={handleBack}
              className="flex items-center text-gray-700 transition-colors hover:text-blue-900"
              aria-label="Go back"
            >
              <BsArrowLeft className="w-5 h-5 mr-2" />
              <span className="text-sm font-medium">Back</span>
            </button>
          </div>

          {/* Logo Section */}
          <div className="flex flex-col items-center justify-center px-8 pt-4 pb-6 border-b border-white/20">
            <Link to="/" className="mb-4">
              <div className="p-3 rounded-full bg-white/30 backdrop-blur-sm">
                <img
                  src="/assets/logo.svg"
                  alt="JK SOLAR ENERGY"
                  className="w-24 h-24 sm:w-28 sm:h-28"
                  loading="eager"
                />
              </div>
            </Link>
            <h1 className="text-2xl font-bold text-gray-900">Admin Portal</h1>
            <p className="mt-2 text-sm text-gray-700">
              Login to access your dashboard
            </p>
          </div>

          {/* Form Section */}
          <form
            className="px-8 py-6 space-y-6"
            onSubmit={handleLogin}
            noValidate
          >
            {/* Email Field */}
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <FaEnvelope className="text-gray-500" />
                </div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className={`w-full px-3 py-3 pl-10 text-gray-700 placeholder-gray-400 transition-all bg-white/70 border rounded-lg focus:outline-none focus:ring-2 ${
                    formErrors.email
                      ? "border-red-500 focus:ring-red-200"
                      : "border-gray-300 focus:ring-blue-200"
                  }`}
                  placeholder="admin@example.com"
                  autoComplete="email"
                  required
                />
              </div>
              {formErrors.email && (
                <p className="mt-1 text-sm text-red-600">{formErrors.email}</p>
              )}
            </div>

            {/* Password Field */}
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <FaLock className="text-gray-500" />
                </div>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className={`w-full px-3 py-3 pl-10 text-gray-700 placeholder-gray-400 transition-all bg-white/70 border rounded-lg focus:outline-none focus:ring-2 ${
                    formErrors.password
                      ? "border-red-500 focus:ring-red-200"
                      : "border-gray-300 focus:ring-blue-200"
                  }`}
                  placeholder="••••••••"
                  autoComplete="current-password"
                  required
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-600 transition-colors hover:text-gray-900"
                >
                  {showPassword ? (
                    <BsEyeFill className="w-5 h-5" />
                  ) : (
                    <BsEyeSlashFill className="w-5 h-5" />
                  )}
                </button>
              </div>
              {formErrors.password && (
                <p className="mt-1 text-sm text-red-600">
                  {formErrors.password}
                </p>
              )}
            </div>

            {/* Login Button */}
            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="relative w-full px-6 py-3 text-base font-semibold text-white transition-all bg-blue-900 rounded-lg shadow-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 disabled:opacity-70"
              >
                {isLoading ? (
                  <>
                    <FaSpinner className="inline w-5 h-5 mr-2 animate-spin" />
                    <span>Signing in...</span>
                  </>
                ) : (
                  <span>Sign In</span>
                )}
              </button>
            </div>
          </form>

          {/* Footer */}
          <div className="px-8 py-4 text-sm text-center text-gray-600 border-t bg-white/10 border-white/20">
            Need help? Contact IT support
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 text-sm text-center text-white/70">
          © {new Date().getFullYear()} JK Solar Energy. All rights reserved.
        </div>
      </div>
    </main>
  );
}
