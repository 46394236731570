export const JKSE_ACCOUNTS = [
  {
    bankName: "J&K Bank",
    accountNumber: "0814020100000106",
    ifscCode: "JAKA0JANGLA",
    logo: "/assets/jkbank.png",
    branch: "Janglat Mandi, Anantnag",
  },
  {
    bankName: "HDFC Bank",
    accountNumber: "50200023870097",
    ifscCode: "HDFC0000858",
    logo: "/assets/hdfcbank.png",
    branch: "K.P Road Anantnag",
  },
  {
    bankName: "State Bank of India",
    accountNumber: "36564332799",
    ifscCode: "SBIN0001478",
    logo: "/assets/sbibank.png",
    branch: "Lal Chowk Anantnag",
  },
];

export const JKSS_ACCOUNTS = [
  {
    bankName: "J&K Bank",
    accountNumber: "0837020100000039",
    ifscCode: "JAKA0SHANKE",
    logo: "/assets/jkbank.png",
    branch: "Shankerpora Anantnag",
  },
  {
    bankName: "HDFC Bank",
    accountNumber: "50200079868973",
    ifscCode: "HDFC0000858",
    logo: "/assets/hdfcbank.png",
    branch: "K.P Road Anantnag",
  },
  {
    bankName: "State Bank of India",
    accountNumber: "42626811745",
    ifscCode: "SBIN0001478",
    logo: "/assets/sbibank.png",
    branch: "Lal Chowk Anantnag",
  },
];

export const DEFAULT_PHOTO_URL =
  "https://firebasestorage.googleapis.com/v0/b/hr-jkse-app.appspot.com/o/user.png?alt=media&token=cab33bcf-4be8-43f2-bbcd-5172266c0da4";

export const DEFAULT_FILE_URL =
  "https://firebasestorage.googleapis.com/v0/b/hr-jkse-app.appspot.com/o/file.png?alt=media&token=8404f8cb-c631-45b2-ba84-2833d37684f9";
