import React, { useEffect, useState, useMemo, useCallback } from "react";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { ClipLoader } from "react-spinners";
import ProtectedRoute from "../../components/ProtectedRoute";
import { BsPersonFillAdd } from "react-icons/bs";
import { Link } from "react-router-dom";
import AdminLayout from "../../components/AdminLayout";
import AdvisorTable from "../../components/AdvisorTable";
import LayoutHeader from "../../components/LayoutHeader";
import { toastOptions } from "../../utils/helpers";
import { toast } from "react-toastify";
import { HiSearch } from "react-icons/hi";

export default function Advisors() {
  const [loading, setLoading] = useState(true);
  const [advisors, setAdvisors] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Use callback to prevent unnecessary rerenders
  const updateAdvisors = useCallback((newAdvisors) => {
    setAdvisors(newAdvisors);
  }, []);

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  // Firestore listener setup
  useEffect(() => {
    const advisorsCol = collection(db, "advisor_list");
    const advisorsQuery = query(advisorsCol, orderBy("advisor_code", "asc"));

    // Set up real-time listener
    const unsubscribe = onSnapshot(
      advisorsQuery,
      (querySnapshot) => {
        const docs = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        updateAdvisors(docs);
        setLoading(false);
      },
      (error) => {
        const errorMessage =
          error?.code === "resource-exhausted"
            ? "Quota exceeded"
            : "Something went wrong";

        toast.error(errorMessage, toastOptions);
        setLoading(false);
      }
    );

    // Cleanup subscription on unmount
    return unsubscribe;
  }, [updateAdvisors]);

  // Memoize filtered results to prevent recalculation on every render
  const filteredAdvisors = useMemo(() => {
    if (!searchQuery.trim()) return advisors;

    const lowercaseQuery = searchQuery.toLowerCase();
    return advisors.filter((advisor) =>
      advisor.advisor_name?.toLowerCase().includes(lowercaseQuery)
    );
  }, [advisors, searchQuery]);

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="h-full">
          <LayoutHeader title="Employees" />

          {/* Header with search and add button */}
          <div className="px-4 mb-6">
            <div className="flex flex-col items-start justify-between gap-4 sm:flex-row sm:items-center">
              {/* Search bar with icon */}
              <div className="relative w-full sm:w-auto">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <HiSearch className="w-5 h-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Search by employee name"
                  className="w-full py-2 pl-10 pr-4 text-gray-700 transition duration-150 bg-white border border-gray-300 rounded-lg sm:w-80 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  aria-label="Search advisors"
                />
              </div>

              {/* Add advisor button */}
              <Link
                to="/admin/add-employee"
                className="flex items-center justify-center w-full px-4 py-2 font-medium text-white transition duration-150 bg-blue-900 border border-transparent rounded-lg shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto"
              >
                <BsPersonFillAdd className="w-5 h-5 mr-2" />
                <span>Add Employee</span>
              </Link>
            </div>
          </div>

          {/* Loading state */}
          {loading && (
            <div className="flex items-center justify-center w-full py-12">
              <ClipLoader size={40} color="#1E3A8A" />
            </div>
          )}

          {/* Results area with shadow and rounded corners */}
          {!loading && (
            <div className="px-4 pb-8">
              <div className="overflow-hidden bg-white rounded-lg shadow">
                {filteredAdvisors.length === 0 ? (
                  <div className="flex flex-col items-center justify-center px-4 py-12 text-center">
                    <p className="text-lg font-medium text-gray-600">
                      No advisors found
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      {searchQuery
                        ? "Try adjusting your search"
                        : "Add an advisor to get started"}
                    </p>
                  </div>
                ) : (
                  <div className="overflow-x-auto">
                    <AdvisorTable advisors={filteredAdvisors} />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
}
