import React, { useState, useEffect, useCallback, useRef } from "react";
import { collection, getDocs, query, where, limit } from "firebase/firestore";
import { FaSpinner, FaIdCard } from "react-icons/fa";
import { db } from "../firebase/firebaseConfig";
import { toast } from "react-toastify";
import { toastOptions } from "../utils/helpers";
import EmployeeDetails from "../components/EmployeeDetails";
import { useLocation } from "react-router-dom";

export default function EmpVerify() {
  // State management
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    name: "",
    code: "",
    photoUrl: "",
    branch: "",
    role: "Other", // Default role
  });

  // Get the URL query parameters
  const location = useLocation();

  // Memoized verification function to prevent unnecessary re-renders
  const verifyEmployee = useCallback(async (employeeCode) => {
    if (!employeeCode?.trim()) {
      toast.error("Please enter employee code", toastOptions);
      return;
    }

    try {
      // Reset verification state
      setIsVerified(false);
      setIsActive(false);
      setLoading(true);

      // Optimize query by filtering on the database side and limiting to 1 result
      const employeeCodeQuery = query(
        collection(db, "advisor_list"),
        where("advisor_code", "==", employeeCode),
        limit(1)
      );

      const querySnapshot = await getDocs(employeeCodeQuery);

      if (querySnapshot.empty) {
        toast.error("Invalid employee code", toastOptions);
        setEmployeeData({
          name: "",
          code: "",
          photoUrl: "",
          branch: "",
          role: "Other", // Reset to default role
        });
        return;
      }

      // Get the first (and should be only) matching document
      const employee = querySnapshot.docs[0].data();

      setIsVerified(true);
      setIsActive(employee.is_active);
      setEmployeeData({
        name: employee.advisor_name || "",
        code: employee.advisor_code || "",
        photoUrl: employee.photoUrl || "",
        branch: employee.branch || "Naibasti Anantnag",
        role: employee.role || "Other", // Set role with fallback to "Other"
      });
    } catch (error) {
      console.error("Verification error:", error);
      toast.error("An error occurred during verification", toastOptions);
    } finally {
      setLoading(false);
    }
  }, []);

  // Extract the ID from the URL when component mounts
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const idFromUrl = queryParams.get("id");

    if (idFromUrl) {
      const formattedId = idFromUrl.trim().toUpperCase();
      setCode(formattedId);
      // Automatically verify the employee when ID is provided in URL
      verifyEmployee(formattedId);
    }
  }, [location, verifyEmployee]);

  // Handle Enter key press
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !loading) {
      verifyEmployee(code);
    }
  };

  const inputRef = useRef(null);

  // Focus input field when code is empty and component mounts
  useEffect(() => {
    if (!code && inputRef.current && !location.search.includes("id=")) {
      inputRef.current.focus();
    }
  }, [code, location.search]);

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-screen text-gray-800 bg-gradient-to-b from-gray-100 to-gray-200">
      {/* Container with responsive padding */}
      <div className="w-full max-w-lg px-4 py-4 sm:px-6 md:px-0 sm:py-6 md:py-8">
        <div className="mb-6 text-center sm:mb-8">
          {/* Responsive logo container */}
          <div className="flex justify-center mb-4 sm:mb-6">
            <div className="p-3 transition-all duration-300 bg-white rounded-full shadow-lg sm:p-4 md:p-5 hover:shadow-xl">
              <img
                src="/assets/logo.svg"
                alt="JK Solar Energy Logo"
                className="w-12 h-12 sm:w-16 sm:h-16 md:w-20 md:h-20"
              />
            </div>
          </div>
          {/* Responsive typography */}
          <h1 className="mb-2 text-2xl font-bold tracking-tight text-gray-800 sm:mb-3 sm:text-3xl md:text-4xl">
            Employee Verification
          </h1>
          <p className="max-w-sm mx-auto text-base text-gray-600 sm:text-lg">
            Verify employee credentials and status
          </p>
        </div>

        {/* Main card with responsive padding and sizing */}
        <div className="p-5 overflow-hidden transition-all bg-white shadow-xl sm:p-6 md:p-8 rounded-xl sm:rounded-2xl">
          {/* Input and button with responsive layout */}
          <div className="flex flex-col items-center gap-3 sm:flex-row sm:gap-4">
            <div className="relative flex-grow w-full group">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400 pointer-events-none sm:pl-4">
                <FaIdCard className="w-4 h-4 sm:w-5 sm:h-5" />
              </div>
              <input
                ref={inputRef}
                type="text"
                id="code"
                className="w-full py-3 pl-10 pr-4 text-base text-gray-700 transition-all border border-gray-300 rounded-lg sm:py-4 sm:pl-12 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-lg"
                placeholder="Enter Employee Code"
                value={code}
                onChange={(e) => setCode(e.target.value.toUpperCase())}
                onKeyPress={handleKeyPress}
                disabled={loading}
                aria-label="Employee Code"
              />
            </div>

            <button
              className="flex items-center justify-center w-full px-6 py-3 text-base font-medium text-white transition duration-300 bg-blue-600 rounded-lg shadow-md sm:w-auto sm:px-8 sm:py-4 sm:text-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-70"
              onClick={() => verifyEmployee(code)}
              disabled={loading}
              aria-label="Verify Employee"
            >
              {loading ? (
                <>
                  <FaSpinner className="w-4 h-4 mr-2 sm:mr-3 animate-spin sm:w-5 sm:h-5" />
                  <span>Verifying...</span>
                </>
              ) : (
                "Verify"
              )}
            </button>
          </div>

          <div className="mt-5 sm:mt-6">
            <EmployeeDetails
              isVerified={isVerified}
              isActive={isActive}
              employeeName={employeeData.name}
              photoUrl={employeeData.photoUrl}
              branch={employeeData.branch}
              employeeCode={employeeData.code}
              role={employeeData.role} // Pass role to EmployeeDetails component
            />
          </div>
        </div>

        {/* Responsive footer */}
        <div className="mt-6 text-xs text-center text-gray-600 sm:mt-8 sm:text-sm">
          <p>© 2025 JK Solar Energy. All rights reserved.</p>
          <p className="mt-1">Secure Employee Verification System</p>
        </div>
      </div>
    </div>
  );
}
