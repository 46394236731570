import React, { useEffect, useRef } from "react";

export const ItemDescriptionCell = ({ row, rowIndex, handleCellChange }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      adjustHeight(textareaRef.current);
    }
  }, [row.description]);

  const adjustHeight = (element) => {
    // Reset height to auto first to get accurate scrollHeight
    element.style.height = "auto";
    // Set height to scrollHeight to show all content
    element.style.height = `${element.scrollHeight}px`;
  };

  return (
    <div
      className="item-description-wrapper"
      style={{ position: "relative", width: "100%" }}
    >
      <textarea
        ref={textareaRef}
        value={row.description}
        onChange={(e) => {
          handleCellChange(rowIndex, "description", e.target.value);
          adjustHeight(e.target);
        }}
        onFocus={(e) => adjustHeight(e.target)}
        className="w-full resize-none focus:outline-none item-description"
        style={{
          overflow: "hidden",
          minHeight: "20px",
          height: "auto",
        }}
      />
      {/* This hidden div will be used for printing - it shows the exact same content */}
      <div
        className="hidden print-only-content"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
          display: "none",
        }}
        dangerouslySetInnerHTML={{
          __html: row.description.replace(/\n/g, "<br/>"),
        }}
      />
    </div>
  );
};
