import { useEffect, useContext } from "react";
import { AuthContext } from "../utils/authContext";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const { currentUser, signOut } = useContext(AuthContext);
  const adminEmail = process.env.REACT_APP_ADMIN_EMAIL;
  const isAdmin = currentUser?.email === adminEmail;

  useEffect(() => {
    // If no user, redirect to admin login
    if (!currentUser) {
      navigate("/admin");
      return;
    }

    // If user is not admin, sign them out
    if (!isAdmin) {
      signOut();
    }
  }, [currentUser, isAdmin, navigate, signOut]);

  // Only render children if user is admin
  return isAdmin ? children : null;
};

export default ProtectedRoute;
