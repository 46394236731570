import { db } from "../firebase/firebaseConfig";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";

// Update user timestamp in Firestore
export const updateUserTimestamp = async (userId) => {
  if (!userId) return false;

  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      lastLogin: serverTimestamp(),
    });
    return true;
  } catch (error) {
    console.error("Error updating timestamp:", error);
    return false;
  }
};

// Update user profile in Firestore
export const updateUserProfileInFirestore = async (userId, data) => {
  if (!userId) return false;

  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      ...data,
      updatedAt: serverTimestamp(),
    });
    return true;
  } catch (error) {
    console.error("Error updating user profile in Firestore:", error);
    return false;
  }
};
