import React from "react";

export default function LayoutHeader({ title }) {
  return (
    <header className="w-full mx-2 my-2 mb-4 bg-blue-900 rounded-full shadow-md">
      <div className="container flex items-center justify-center py-4 mx-auto">
        <h1 className="text-2xl font-semibold text-white">{title}</h1>
      </div>
    </header>
  );
}
