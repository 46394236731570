import AdminLayout from "../../components/AdminLayout";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { db } from "../../firebase/firebaseConfig";
import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import ProtectedRoute from "../../components/ProtectedRoute";
import { ClipLoader } from "react-spinners";
import DashboardCard from "../../components/DashboardCard";
import LayoutHeader from "../../components/LayoutHeader";
import { FcSalesPerformance } from "react-icons/fc";
import { GiShoppingBag } from "react-icons/gi";
import { FaUsers } from "react-icons/fa";
import { formatAmountToInr } from "../../utils/helpers";
import Overview from "../../components/Overview";
import useOrdersStore from "../../utils/useOrdersStore";
import useCustomersStore from "../../utils/useCustomersStore";

const initialGraphData = [
  { name: "Jan", total: 0 },
  { name: "Feb", total: 0 },
  { name: "Mar", total: 0 },
  { name: "Apr", total: 0 },
  { name: "May", total: 0 },
  { name: "Jun", total: 0 },
  { name: "Jul", total: 0 },
  { name: "Aug", total: 0 },
  { name: "Sep", total: 0 },
  { name: "Oct", total: 0 },
  { name: "Nov", total: 0 },
  { name: "Dec", total: 0 },
];

export default function Dashboard() {
  const { querySnapshot, fetchOrders, loading } = useOrdersStore();
  const { fetchCustomers } = useCustomersStore();

  const [firmName, setFirmName] = useState("all_firms");
  const [financePartner, setFinancePartner] = useState("all_partners");
  const [year, setYear] = useState(new Date().getFullYear());

  const [orderSnapshot, setOrderSnapshot] = useState(null);

  const [numCustomers, setNumCustomers] = useState(0);
  const [numOrders, setNumOrders] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [totalSalesThisMonth, setTotalSalesThisMonth] = useState(0);
  const [totalCommissionYear, setTotalCommissionYear] = useState(0);
  const [totalDownpaymentYear, setTotalDownpaymentYear] = useState(0);
  const [totalSalesYear, setTotalSalesYear] = useState(0);

  const [graphData, setGraphData] = useState(initialGraphData);

  useEffect(() => {
    const getData = async () => {
      await fetchOrders();
      setOrderSnapshot(querySnapshot);
      await fetchCustomers();
    };

    getData();
  }, [fetchOrders, querySnapshot, fetchCustomers]);

  // Optimized with useCallback to prevent unnecessary recreations
  const filterAndUpdateOrdersData = useCallback(() => {
    if (!orderSnapshot) {
      return;
    }

    // Pre-calculate constants outside the loop
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const yearInt = parseInt(year);
    const isAllFirms = firmName === "all_firms";
    const isAllPartners = financePartner === "all_partners";
    const isNoFinance = financePartner === "no_finance";

    // Create a month name lookup for faster access
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Create a new array for graph data instead of modifying the original
    const updatedGraphData = Array(12)
      .fill()
      .map((_, i) => ({
        name: monthNames[i],
        total: 0,
      }));

    // Initialize counters
    let totalSalesTillNow = 0;
    let totalCommissionTillNow = 0;
    let totalSalesThisMonth = 0;
    let totalSalesYear = 0;
    let totalCommissionYear = 0;
    let totalDownpaymentYear = 0;

    // Process all orders in a single loop with optimized conditions
    orderSnapshot.forEach((doc) => {
      const data = doc.data();
      const orderCost = parseInt(data.order_cost) || 0;
      const orderCommission = parseInt(data.commission) || 0;
      const downpayment = parseInt(data.downpayment) || 0;

      // Parse date only once
      const orderDate = new Date(data.order_date);
      const orderMonth = orderDate.getMonth();
      const orderMonthNum = orderMonth + 1;
      const orderYear = orderDate.getFullYear();
      const orderFirmName = data.firm_name;
      const orderFinancePartner = data.financed_by;
      const isFinanced = data.finance === true || data.finance === "true";

      // Check finance filter match
      const matchesFinanceFilter =
        isAllPartners ||
        (isNoFinance && !isFinanced) ||
        (orderFinancePartner === financePartner && isFinanced);

      if (!matchesFinanceFilter) {
        return; // Skip this order if it doesn't match finance filter
      }

      // Accumulate totals regardless of firm filter
      totalSalesTillNow += orderCost;
      totalCommissionTillNow += orderCommission;

      // Check if order is for current month
      const isCurrentMonth =
        orderMonthNum === currentMonth && orderYear === currentYear;

      // Current month sales calculation
      if (isCurrentMonth && (isAllFirms || orderFirmName === firmName)) {
        totalSalesThisMonth += orderCost;
      }

      // Year-specific calculations with firm filter
      const matchesYearFilter = orderYear === yearInt;
      const matchesFirmFilter = isAllFirms || orderFirmName === firmName;

      if (matchesYearFilter && matchesFirmFilter) {
        totalSalesYear += orderCost;
        totalCommissionYear += orderCommission;
        totalDownpaymentYear += downpayment;

        // Update graph data for matching month
        updatedGraphData[orderMonth].total += orderCost;
      }
    });

    // Batch state updates
    setGraphData(updatedGraphData);
    setTotalSales(totalSalesTillNow);
    setTotalCommission(totalCommissionTillNow);
    setTotalSalesThisMonth(totalSalesThisMonth);
    setTotalSalesYear(totalSalesYear);
    setTotalDownpaymentYear(totalDownpaymentYear);
    setTotalCommissionYear(totalCommissionYear);
  }, [orderSnapshot, firmName, year, financePartner]);

  useEffect(() => {
    filterAndUpdateOrdersData();
  }, [filterAndUpdateOrdersData]);

  // Optimize database queries with better async handling
  useEffect(() => {
    const fetchCounts = async () => {
      try {
        let ordersQuery, customersQuery;

        // Create base query for orders
        if (firmName === "all_firms") {
          ordersQuery = query(collection(db, "orders"));
        } else {
          ordersQuery = query(
            collection(db, "orders"),
            where("firm_name", "==", firmName)
          );
        }

        // Add finance filter if needed
        if (financePartner !== "all_partners") {
          if (financePartner === "no_finance") {
            // Filter for non-financed orders
            ordersQuery = query(
              collection(db, "orders"),
              where(
                "firm_name",
                firmName === "all_firms" ? "!=" : "==",
                firmName === "all_firms" ? "" : firmName
              ),
              where("finance", "==", false)
            );
          } else {
            // Filter for specific finance partner
            ordersQuery = query(
              collection(db, "orders"),
              where(
                "firm_name",
                firmName === "all_firms" ? "!=" : "==",
                firmName === "all_firms" ? "" : firmName
              ),
              where("finance", "==", true),
              where("financed_by", "==", financePartner)
            );
          }
        }

        // Create customers query
        if (firmName === "all_firms") {
          customersQuery = query(collection(db, "customers"));
        } else {
          customersQuery = query(
            collection(db, "customers"),
            where("firmName", "==", firmName)
          );
        }

        // Run queries in parallel
        const [ordersCount, customersCount] = await Promise.all([
          getCountFromServer(ordersQuery),
          getCountFromServer(customersQuery),
        ]);

        // Batch state updates
        setNumOrders(ordersCount.data().count);
        setNumCustomers(customersCount.data().count);
      } catch (error) {
        console.error("Error fetching counts:", error);
      }
    };

    fetchCounts();
  }, [firmName, financePartner]);

  // Dynamically generate year options from 2015 to current year
  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let y = currentYear; y >= 2015; y--) {
      years.push(
        <option key={y} value={y.toString()}>
          Year {y}
        </option>
      );
    }
    return years;
  }, []);

  // Memoize Dashboard Cards to prevent unnecessary rerenders
  const topCards = useMemo(
    () => (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        <DashboardCard
          title={"Total Sales"}
          num={formatAmountToInr(totalSales)}
          icon={FcSalesPerformance}
        />
        <DashboardCard
          hide
          title={"Total Commission"}
          num={formatAmountToInr(totalCommission)}
          icon={FcSalesPerformance}
        />
        <DashboardCard
          title={"Total Orders"}
          num={numOrders}
          icon={GiShoppingBag}
        />
        <DashboardCard
          title={"Total Customers"}
          num={numCustomers}
          icon={FaUsers}
        />
      </div>
    ),
    [totalSales, totalCommission, numOrders, numCustomers]
  );

  // Memoize side cards
  const sideCards = useMemo(
    () => (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1">
        <DashboardCard
          title={"Sales (This Month)"}
          num={formatAmountToInr(totalSalesThisMonth)}
          icon={FcSalesPerformance}
        />
        <DashboardCard
          title={`Sales (${year})`}
          num={formatAmountToInr(totalSalesYear)}
          icon={FcSalesPerformance}
        />
        <DashboardCard
          hide={true}
          title={`Commission (${year})`}
          num={formatAmountToInr(totalCommissionYear)}
          icon={FcSalesPerformance}
        />
        <DashboardCard
          title={`Downpayment (${year})`}
          num={formatAmountToInr(totalDownpaymentYear)}
          icon={FcSalesPerformance}
        />
      </div>
    ),
    [
      totalSalesThisMonth,
      totalSalesYear,
      totalCommissionYear,
      totalDownpaymentYear,
      year,
    ]
  );

  // Handle select changes efficiently with memoized callbacks
  const handleFirmChange = useCallback((e) => setFirmName(e.target.value), []);
  const handlePartnerChange = useCallback(
    (e) => setFinancePartner(e.target.value),
    []
  );
  const handleYearChange = useCallback((e) => setYear(e.target.value), []);

  // Memoize chart component
  const chartComponent = useMemo(
    () => (
      <div className="w-full overflow-x-auto">
        <div className="min-w-full min-h-72">
          <Overview data={graphData} />
        </div>
      </div>
    ),
    [graphData]
  );

  return (
    <>
      <ProtectedRoute>
        <AdminLayout>
          <div className="h-full mb-10 bg-gray-50">
            <LayoutHeader title={"Admin Dashboard"} />

            {loading ? (
              <div className="flex justify-center w-full py-12">
                <ClipLoader size={40} color="#1E3A8A" />
              </div>
            ) : (
              <>
                {/* Filters Section - Optimized with memoized handlers */}
                <div className="w-full px-3 py-4 mb-4 bg-white border-b shadow-sm sm:px-6">
                  <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="w-full">
                      <label
                        htmlFor="firm"
                        className="block mb-1 text-sm font-medium text-gray-700"
                      >
                        Firm
                      </label>
                      <select
                        id="firm"
                        required
                        value={firmName ?? ""}
                        onChange={handleFirmChange}
                        className="w-full px-3 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="all_firms">All Firms</option>
                        <option value="jk_solar_energy">JK Solar Energy</option>
                        <option value="jk_sale_and_service">
                          JK Sale and Service Agency
                        </option>
                        <option value="saaslynx">SaasLynx</option>
                      </select>
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="partner"
                        className="block mb-1 text-sm font-medium text-gray-700"
                      >
                        Finance Partner
                      </label>
                      <select
                        id="partner"
                        required
                        value={financePartner ?? ""}
                        onChange={handlePartnerChange}
                        className="w-full px-3 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="all_partners">All Partners</option>
                        <option value="no_finance">None (Cash)</option>
                        <option value="j_and_k_bank">J&K Bank</option>
                        <option value="sbi_bank">SBI Bank</option>
                        <option value="hdfc_bank">HDFC Bank</option>
                        <option value="bajaj_finserv">Bajaj Finserv</option>
                        <option value="samsung_finance">Samsung Finance</option>
                      </select>
                    </div>
                    <div className="w-full sm:col-span-2 lg:col-span-1">
                      <label
                        htmlFor="year"
                        className="block mb-1 text-sm font-medium text-gray-700"
                      >
                        Year
                      </label>
                      <select
                        id="year"
                        required
                        value={year ?? ""}
                        onChange={handleYearChange}
                        className="w-full px-3 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      >
                        {yearOptions}
                      </select>
                    </div>
                  </div>
                </div>

                {/* Dashboard Cards - Using memoized components */}
                <div className="grid gap-4 px-3 sm:px-4">
                  {/* Top Cards Section */}
                  {topCards}

                  {/* Main Content Area - Chart and right column cards */}
                  <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
                    {/* Chart Section */}
                    <div className="p-4 bg-white border rounded-lg shadow-md lg:col-span-3">
                      <h3 className="mb-4 text-lg font-semibold text-gray-800">
                        Sales Overview
                      </h3>
                      {chartComponent}
                    </div>

                    {/* Right Column Cards */}
                    <div className="lg:col-span-1">{sideCards}</div>
                  </div>
                </div>
              </>
            )}
          </div>
        </AdminLayout>
      </ProtectedRoute>
    </>
  );
}
