import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import { collection, getDocs, where, query } from "firebase/firestore";
import LayoutHeader from "../../components/LayoutHeader";
import OrdersTable from "../../components/OrdersTable";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { formatAmountToInr, toastOptions } from "../../utils/helpers";
import { db } from "../../firebase/firebaseConfig";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FiCalendar, FiFilter, FiRefreshCw } from "react-icons/fi";

export default function AdvisorOrders() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  const [advisorName, setAdvisorName] = useState("");
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCommision, setTotalCommision] = useState(0);

  // Date range states
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isFilterActive, setIsFilterActive] = useState(false);

  const [isGoBackClicked, setIsGoBackClicked] = useState(false);

  const handleGoBack = () => {
    setIsGoBackClicked(true);
    setTimeout(() => {
      navigate(-1);
    }, 500);
  };

  const updateOrders = useCallback((newOrders) => {
    setAllOrders(newOrders);
    setOrders(newOrders);
  }, []);

  const fetchOrdersOfAdvisor = async () => {
    if (id === undefined) return;
    try {
      const ordersCol = collection(db, "orders");
      const ordersQuery = query(ordersCol, where("advisor_code", "==", id));
      const res = await getDocs(ordersQuery);

      const ordersData = res.docs.map((doc) => doc.data());
      updateOrders(ordersData);

      if (res.docs.length > 0) {
        setAdvisorName(res.docs[0].data().advisor_name);
      }

      calculateTotals(ordersData);
    } catch (error) {
      console.error(error);
      if (error?.code === "resource-exhausted") {
        toast.error("Quota exceeded", toastOptions);
      } else {
        toast.error("Something went wrong", toastOptions);
      }
    } finally {
      setLoading(false);
    }
  };

  const calculateTotals = (ordersToCalculate) => {
    const totalCost = ordersToCalculate.reduce(
      (acc, order) => acc + (parseInt(order.order_cost) || 0),
      0
    );

    const totalCommission = ordersToCalculate.reduce(
      (acc, order) => acc + (parseInt(order.commission) || 0),
      0
    );

    setTotalOrders(ordersToCalculate.length);
    setTotalAmount(totalCost);
    setTotalCommision(totalCommission);
  };

  useEffect(() => {
    fetchOrdersOfAdvisor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const applyFilters = () => {
    if (!startDate || !endDate) {
      toast.error("Please select both start and end dates", toastOptions);
      return;
    }

    let filtered = [...allOrders];

    const startDateObj = new Date(startDate);
    // Set end date to end of the day
    const endDateObj = new Date(endDate);
    endDateObj.setHours(23, 59, 59, 999);

    filtered = filtered.filter((order) => {
      const orderDate = new Date(order.order_date);
      return orderDate >= startDateObj && orderDate <= endDateObj;
    });

    setOrders(filtered);
    calculateTotals(filtered);
    setIsFilterActive(true);

    toast.success(
      `Showing ${filtered.length} orders from ${startDate} to ${endDate}`,
      toastOptions
    );
  };

  const resetFilters = () => {
    setStartDate("");
    setEndDate("");
    setSearchQuery("");
    setOrders(allOrders);
    calculateTotals(allOrders);
    setIsFilterActive(false);

    toast.info("All filters have been reset", toastOptions);
  };

  const filteredOrders = useMemo(
    () =>
      orders.filter(
        (order) =>
          order.customer_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          (order.customer_phone_no &&
            order.customer_phone_no.includes(searchQuery))
      ),
    [orders, searchQuery]
  );

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="h-full bg-gray-50">
          <LayoutHeader title={`Employee(${id}) Orders`} />

          {/* Advisor Info Panel */}
          <div className="flex items-start p-4 mx-4 mt-4 mb-6 bg-white rounded-lg shadow-md">
            <div
              className="flex items-center justify-center p-2 mr-4 transition-colors duration-300 bg-blue-900 rounded-full hover:bg-blue-800 hover:cursor-pointer"
              onClick={handleGoBack}
            >
              <IoIosArrowBack
                size={22}
                className={`text-white transform ${
                  isGoBackClicked ? "rotate-180" : ""
                } transition duration-500 ease-in-out`}
              />
            </div>

            <div className="flex flex-col flex-grow">
              <div className="mb-2">
                <span className="text-sm font-medium text-gray-500">
                  Employee Name
                </span>
                <h2 className="text-xl font-bold text-gray-800">
                  {advisorName === ""
                    ? "Loading..."
                    : advisorName.toUpperCase()}
                </h2>
              </div>

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                <div className="p-3 rounded-lg bg-blue-50">
                  <p className="text-sm font-medium text-blue-800">
                    Total Orders
                  </p>
                  <p className="text-2xl font-bold text-blue-900">
                    {totalOrders}
                  </p>
                </div>

                <div className="p-3 rounded-lg bg-blue-50">
                  <p className="text-sm font-medium text-blue-800">
                    Total Sales
                  </p>
                  <p className="text-2xl font-bold text-blue-900">
                    {formatAmountToInr(totalAmount)}
                  </p>
                </div>

                <div className="p-3 rounded-lg bg-blue-50">
                  <p className="text-sm font-medium text-blue-800">
                    Total Commission
                  </p>
                  <p className="text-2xl font-bold text-blue-900">
                    {formatAmountToInr(totalCommision)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Date Range Filter Panel */}
          <div className="p-4 mx-4 mb-6 bg-white rounded-lg shadow-md">
            <div className="flex items-center mb-3">
              <FiCalendar className="mr-2 text-blue-900" />
              <h3 className="text-lg font-semibold text-gray-800">
                Date Range Filter
              </h3>
              {isFilterActive && (
                <span className="px-2 py-1 ml-3 text-xs font-medium text-white bg-green-500 rounded-full">
                  Active
                </span>
              )}
            </div>

            <div className="flex flex-wrap items-end gap-4">
              <div className="flex flex-col">
                <label className="mb-1 text-sm font-medium text-gray-600">
                  Start Date
                </label>
                <input
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  className="px-3 py-2 text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div className="flex flex-col">
                <label className="mb-1 text-sm font-medium text-gray-600">
                  End Date
                </label>
                <input
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  className="px-3 py-2 text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <button
                onClick={applyFilters}
                className="flex items-center px-4 py-2 text-sm font-medium text-white transition-colors duration-300 bg-blue-900 rounded-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <FiFilter className="mr-2" />
                Apply Filter
              </button>

              <button
                onClick={resetFilters}
                className="flex items-center px-4 py-2 text-sm font-medium text-white transition-colors duration-300 bg-blue-900 rounded-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <FiRefreshCw className="mr-2" />
                Reset
              </button>
            </div>
          </div>

          {/* Search and Table Container */}
          <div className="mx-4 mb-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-between p-4 border-b">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Search by Customer's name or Phone number"
                className="w-full px-4 py-2 text-gray-600 border rounded-md sm:w-96 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {loading ? (
              <div className="flex justify-center w-full py-16">
                <ClipLoader color="#1e3a8a" size={60} />
              </div>
            ) : (
              <div className="overflow-x-auto">
                <OrdersTable orders={filteredOrders} />
              </div>
            )}
          </div>
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
}
