import React, { createRef, useState } from "react";
import { parseFirebaseTimestamp } from "../utils/helpers";
import OrderModal from "./OrderModal";

export default function OrdersTable({ orders }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(null);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const handleOnClick = (order) => {
    setData(order);
    setIsModalOpen(true);
  };

  const truncatedOrders = orders.length > 20 ? orders.slice(0, limit) : orders;

  const handleNext = () => {
    setPage(page + 1);
    setLimit(Math.max(1, limit + 20));
  };

  const handlePrevious = () => {
    setPage(Math.max(1, page - 1));
    setLimit(Math.max(1, limit - 20));
  };

  return (
    <>
      {data && (
        <OrderModal
          ref={createRef()}
          id={data}
          data={data}
          setData={setData}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Customer ID
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Customer Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Phone Number
            </th>

            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Address
            </th>

            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Order Details
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Order Cost
            </th>

            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Order Date
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Order Status
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {truncatedOrders.map((order) => (
            <tr
              key={order.id}
              onClick={() => handleOnClick(order)}
              className="cursor-pointer hover:bg-gray-50"
            >
              <td
                className={`px-6 py-4 text-sm font-bold whitespace-nowrap ${
                  order.order_status === "delivered"
                    ? "text-green-600"
                    : order.order_status === "pending"
                    ? "text-yellow-600"
                    : order.order_status === "installed"
                    ? "text-blue-600"
                    : order.order_status === "returned"
                    ? "text-orange-600"
                    : order.order_status === "cancelled"
                    ? "text-red-600"
                    : "text-gray-600"
                }`}
              >
                {order.customer_id.toString()}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {order.customer_name}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {order.customer_phone_no ? order.customer_phone_no : "N/A"}
              </td>

              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {order.customer_address
                  ? order.customer_address.length > 33
                    ? order.customer_address.substring(0, 30) + "..."
                    : order.customer_address
                  : "N/A"}
              </td>

              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {order.order_details
                  ? order.order_details.length > 33
                    ? order.order_details.substring(0, 30) + "..."
                    : order.order_details
                  : "N/A"}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {order.order_cost ? order.order_cost : "N/A"}
              </td>

              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {order.order_date
                  ? order.order_date.toString().substring(0, 10)
                  : parseFirebaseTimestamp(order.created_at)}
              </td>
              <td className="px-6 py-4 text-sm font-bold text-gray-500 whitespace-nowrap">
                {order.order_status ? order.order_status.toUpperCase() : "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center justify-between p-4 mt-4">
        <button
          onClick={handlePrevious}
          className={`flex items-center p-2 px-4 font-semibold text-white bg-blue-900 border rounded-md hover:bg-blue-800 ${
            page === 1 ? "opacity-0" : "opacity-100"
          }`}
          disabled={page === 1}
        >
          Previous
        </button>
        {!(truncatedOrders.length >= orders.length) && (
          <button
            onClick={handleNext}
            className="flex items-center p-2 px-4 font-semibold text-white bg-blue-900 border rounded-md hover:bg-blue-800"
          >
            Next
          </button>
        )}
      </div>
    </>
  );
}
