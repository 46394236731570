import AdminLayout from "../../components/AdminLayout";
import ProtectedRoute from "../../components/ProtectedRoute";
import React, { useEffect, useState, useCallback } from "react";
import { FaSpinner, FaUser, FaUpload } from "react-icons/fa";
import { HiOutlineMail, HiOutlinePhone, HiOutlineHome } from "react-icons/hi";
import { toast } from "react-toastify";
import { db } from "../../firebase/firebaseConfig";
import {
  doc,
  collection,
  setDoc,
  query,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { toastOptions } from "../../utils/helpers";
import LayoutHeader from "../../components/LayoutHeader";
import { DEFAULT_PHOTO_URL } from "../../utils/constants";

export default function AddAdvisor() {
  const navigate = useNavigate();

  // Role options for the dropdown
  const roleOptions = [
    "Technician",
    "Sales Officer",
    "Sales Advisor",
    "Head Technician",
    "Salesman",
    "Other",
  ];

  const [formData, setFormData] = useState({
    advisorName: "",
    address: "",
    phoneNo: "",
    emailId: "",
    role: "Other", // Default role
    idIssued: false,
    isActive: false,
  });
  const [advCode, setAdvCode] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(DEFAULT_PHOTO_URL);
  const [imageUploading, setImageUploading] = useState(false);

  // Fetch advisor code on component mount
  useEffect(() => {
    const getAdvCode = async () => {
      try {
        const q = query(collection(db, "codes"));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs.length > 0) {
          setAdvCode(querySnapshot.docs[0].data().suffix);
        }
      } catch (error) {
        console.error("Error fetching advisor code:", error);
        toast.error("Could not retrieve advisor code", toastOptions);
      }
    };
    getAdvCode();
  }, []);

  // Handle form field changes
  const handleChange = useCallback((e) => {
    const { id, value, checked, type } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: type === "checkbox" ? checked : value,
    }));
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (advCode === 0) {
      toast.error("Employee code not available", toastOptions);
      return;
    }

    // Validate required fields
    if (!formData.advisorName || !formData.phoneNo) {
      toast.error("Name and phone number are required", toastOptions);
      return;
    }

    // Phone number validation
    if (formData.phoneNo.length !== 10 || !/^\d+$/.test(formData.phoneNo)) {
      toast.error("Please enter a valid 10-digit phone number", toastOptions);
      return;
    }

    // Email validation if provided
    if (formData.emailId && !/\S+@\S+\.\S+/.test(formData.emailId)) {
      toast.error("Please enter a valid email address", toastOptions);
      return;
    }

    setIsSubmitting(true);

    const advisorCode = "JKSE" + (advCode + 1).toString();
    const data = {
      advisor_code: advisorCode,
      advisor_name: formData.advisorName,
      address: formData.address,
      phone_no: formData.phoneNo,
      email_id: formData.emailId,
      role: formData.role, // Add role to the data
      id_issued: formData.idIssued,
      is_active: formData.isActive,
      photoUrl: photoUrl,
    };

    try {
      // Add to advisor_list collection
      const newDocRef = doc(collection(db, "advisor_list"));
      await setDoc(newDocRef, data);

      // Update the advisor code counter
      const updateRef = doc(db, "codes", "last_advisor_code");
      await updateDoc(updateRef, { suffix: advCode + 1 });

      // Add employee code to employee_codes collection
      const empCodeData = {
        employee_code: advisorCode,
        employee_name: formData.advisorName,
        photoUrl: photoUrl,
        branch: "JKSE Anantnag",
        role: formData.role, // Add role to employee_codes as well
        active: formData.isActive,
      };
      const empCodeRef = doc(collection(db, "employee_codes"));
      await setDoc(empCodeRef, empCodeData);

      toast.success("Employee added successfully!", toastOptions);
      navigate("/admin/employees");
    } catch (error) {
      console.error("Error adding employee:", error);
      toast.error("Failed to add employee", toastOptions);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    // Validate file type
    if (!file.type.match("image.*")) {
      toast.error("Please select an image file", toastOptions);
      return;
    }

    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      toast.error("Image size should not exceed 5MB", toastOptions);
      return;
    }

    setImageUploading(true);

    const storage = getStorage();
    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000);
    const storageRef = ref(storage, `/advisors/${timestamp}-${randomNum}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Optional: Track upload progress here
      },
      (error) => {
        console.error("Upload error:", error);
        toast.error("Failed to upload image", toastOptions);
        setImageUploading(false);
      },
      async () => {
        try {
          const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
          setPhotoUrl(downloadUrl);
        } catch (error) {
          console.error("Download URL error:", error);
          setPhotoUrl(DEFAULT_PHOTO_URL);
        } finally {
          setImageUploading(false);
        }
      }
    );
  };

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="h-full">
          <LayoutHeader title="Add Employee" />

          <div className="max-w-5xl px-4 py-6 mx-auto sm:px-6">
            <div className="overflow-hidden bg-white rounded-lg shadow-md">
              <form onSubmit={handleSubmit} className="p-6">
                <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
                  {/* Left Column - Basic Info */}
                  <div className="space-y-6 lg:col-span-2">
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                      {/* Advisor Name */}
                      <div>
                        <label
                          htmlFor="advisorName"
                          className="block mb-1 text-sm font-medium text-gray-700"
                        >
                          Employee Name *
                        </label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <FaUser className="w-5 h-5 text-gray-400" />
                          </div>
                          <input
                            type="text"
                            id="advisorName"
                            placeholder="Enter full name"
                            value={formData.advisorName}
                            onChange={handleChange}
                            required
                            className="block w-full py-2 pl-10 pr-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>
                      </div>

                      {/* Phone Number */}
                      <div>
                        <label
                          htmlFor="phoneNo"
                          className="block mb-1 text-sm font-medium text-gray-700"
                        >
                          Phone Number *
                        </label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <HiOutlinePhone className="w-5 h-5 text-gray-400" />
                          </div>
                          <input
                            type="tel"
                            id="phoneNo"
                            placeholder="10-digit mobile number"
                            value={formData.phoneNo}
                            onChange={handleChange}
                            maxLength={10}
                            required
                            className="block w-full py-2 pl-10 pr-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Role Dropdown */}
                    <div>
                      <label
                        htmlFor="role"
                        className="block mb-1 text-sm font-medium text-gray-700"
                      >
                        Role
                      </label>
                      <select
                        id="role"
                        value={formData.role}
                        onChange={handleChange}
                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      >
                        {roleOptions.map((role) => (
                          <option key={role} value={role}>
                            {role}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Email */}
                    <div>
                      <label
                        htmlFor="emailId"
                        className="block mb-1 text-sm font-medium text-gray-700"
                      >
                        Email Address
                      </label>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <HiOutlineMail className="w-5 h-5 text-gray-400" />
                        </div>
                        <input
                          type="email"
                          id="emailId"
                          placeholder="example@email.com"
                          value={formData.emailId}
                          onChange={handleChange}
                          className="block w-full py-2 pl-10 pr-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>
                    </div>

                    {/* Address */}
                    <div>
                      <label
                        htmlFor="address"
                        className="block mb-1 text-sm font-medium text-gray-700"
                      >
                        Address
                      </label>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <HiOutlineHome className="w-5 h-5 text-gray-400" />
                        </div>
                        <input
                          type="text"
                          id="address"
                          placeholder="Complete address"
                          value={formData.address}
                          onChange={handleChange}
                          className="block w-full py-2 pl-10 pr-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>
                    </div>

                    {/* Checkboxes */}
                    <div className="flex flex-wrap gap-6">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="idIssued"
                          checked={formData.idIssued}
                          onChange={handleChange}
                          className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label
                          htmlFor="idIssued"
                          className="block ml-2 text-sm text-gray-700"
                        >
                          ID Issued
                        </label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="isActive"
                          checked={formData.isActive}
                          onChange={handleChange}
                          className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label
                          htmlFor="isActive"
                          className="block ml-2 text-sm text-gray-700"
                        >
                          Active Status
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Right Column - Photo Upload */}
                  <div className="flex flex-col items-center justify-start p-4 rounded-lg bg-gray-50">
                    <div className="flex flex-col items-center mb-4">
                      <div className="w-32 h-32 mb-4 overflow-hidden bg-gray-200 border-2 border-gray-300 rounded-full">
                        <img
                          src={photoUrl}
                          alt="Employee"
                          className="object-cover w-full h-full"
                        />
                      </div>

                      <div className="w-full">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                          className="hidden"
                          id="image-upload"
                          disabled={imageUploading}
                        />
                        <label
                          htmlFor="image-upload"
                          className={`flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md ${
                            imageUploading
                              ? "bg-blue-400 cursor-not-allowed"
                              : "bg-blue-900 hover:bg-blue-800 cursor-pointer"
                          }`}
                        >
                          {imageUploading ? (
                            <FaSpinner className="mr-2 animate-spin" />
                          ) : (
                            <FaUpload className="mr-2" />
                          )}
                          {imageUploading ? "Uploading..." : "Upload Photo"}
                        </label>
                      </div>
                    </div>

                    <div className="mt-2 text-sm text-center text-gray-500">
                      <p>Recommended: Square image</p>
                      <p>Max size: 5MB</p>
                    </div>
                  </div>
                </div>

                {/* Submit Button */}
                <div className="flex justify-end mt-8">
                  <button
                    type="button"
                    onClick={() => navigate("/admin/employees")}
                    className="px-4 py-2 mr-4 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting || imageUploading}
                    className={`px-6 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                      isSubmitting || imageUploading
                        ? "bg-blue-400 cursor-not-allowed"
                        : "bg-blue-900 hover:bg-blue-800"
                    } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                  >
                    {isSubmitting ? (
                      <>
                        <FaSpinner className="inline mr-2 animate-spin" />
                        Adding...
                      </>
                    ) : (
                      "Add Employee"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
}
