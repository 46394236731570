import React, { useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../utils/authContext";

const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;

export default function LoginPage() {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const handleAdminLogin = useCallback(
    (e) => {
      e.preventDefault();
      navigate("/admin");
    },
    [navigate]
  );

  const handleUserLogin = useCallback(
    (e) => {
      e.preventDefault();
      navigate("/user");
    },
    [navigate]
  );

  useEffect(() => {
    if (currentUser) {
      const { email } = currentUser;
      if (email === ADMIN_EMAIL) {
        navigate("/admin/dashboard");
      } else {
        navigate("/user/dashboard");
      }
    }
  }, [currentUser, navigate]);

  return (
    <main className="flex items-center justify-center min-h-screen bg-gradient-to-r from-[#faa93b] from-15% via-[#FAC954] via-30% to-[#1e3a8a] to-70% p-4">
      <div className="w-full max-w-md overflow-hidden shadow-xl bg-white/10 backdrop-blur-sm rounded-2xl">
        <div className="flex flex-col items-center px-8 pt-8 pb-4">
          <div className="p-4 mb-6 rounded-full shadow-md bg-white/90">
            <img
              src="/assets/logo.svg"
              alt="JK SOLAR ENERGY"
              width={100}
              height={100}
              className="w-20 h-20 sm:w-24 sm:h-24"
              loading="eager"
            />
          </div>

          <h1 className="mb-2 text-2xl font-bold text-white sm:text-3xl">
            Welcome Back
          </h1>
          <p className="mb-8 text-center text-white/80">
            Sign in to access your dashboard
          </p>

          <div className="w-full space-y-4 sm:space-y-0 sm:flex sm:space-x-4 sm:justify-between">
            <button
              onClick={handleAdminLogin}
              className="flex items-center justify-center w-full px-6 py-3 font-semibold text-white transition duration-300 ease-in-out transform bg-blue-900 rounded-lg shadow-lg sm:w-1/2 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-white/50 hover:scale-105"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                />
              </svg>
              Admin Login
            </button>

            <button
              onClick={handleUserLogin}
              className="flex items-center justify-center w-full px-6 py-3 font-semibold text-blue-900 transition duration-300 ease-in-out transform bg-white rounded-lg shadow-lg sm:w-1/2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-900/50 hover:scale-105"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
              </svg>
              User Login
            </button>
          </div>
        </div>

        <div className="py-4 mt-8 text-center bg-blue-900/20">
          <p className="text-sm text-white">
            © 2025 JK Solar Energy. All rights reserved.
          </p>
        </div>
      </div>
    </main>
  );
}
