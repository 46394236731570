import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { create } from "zustand";
import { db } from "../firebase/firebaseConfig";

const useOrdersStore = create((set, get) => ({
  docs: [],
  querySnapshot: null,
  loading: true,
  cached: false,
  error: null,
  setLoading: (value) => set({ loading: value }),
  setCached: (value) => set({ cached: value }),
  fetchOrders: async () => {
    try {
      const cached = get().cached;
      if (!cached) {
        const ordersQuery = query(
          collection(db, "orders"),
          orderBy("created_at", "desc")
          // limit(10)
        );

        const docs = [];
        const querySnapshot = await getDocs(ordersQuery);
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });

        set({ docs, querySnapshot, loading: false, cached: true, error: null });
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      set({ loading: false });
      set({ error });
    }
  },
  addOrder: (newOrder) => {
    const docs = get().docs;
    // Ensure we have a unique ID for the new order
    const newOrderWithId = {
      ...newOrder,
      id: newOrder.id || new Date().toISOString(),
    };

    // Add the new order to the beginning of the docs array
    set({
      docs: [newOrderWithId, ...docs],
      loading: false,
      error: null,
    });

    return newOrderWithId;
  },
  updateOrderStatus: (orderId, newStatus) => {
    const docs = get().docs;

    // Map through docs and update the specific order
    const updatedDocs = docs.map((order) => {
      if (order.id === orderId) {
        return { ...order, order_status: newStatus };
      }
      return order;
    });

    set({ docs: updatedDocs, loading: false, error: null });
  },
  updateOrder: (orderId, updatedFields) => {
    const docs = get().docs;

    // Map through docs and update the specific order with all updated fields
    const updatedDocs = docs.map((order) => {
      if (order.id === orderId) {
        return { ...order, ...updatedFields };
      }
      return order;
    });

    set({ docs: updatedDocs, loading: false, error: null });
  },
  deleteOrder: (orderId) => {
    const docs = get().docs;
    const updatedDocs = docs.filter((order) => order.id !== orderId);
    set({ docs: updatedDocs, loading: false, error: null });
  },
}));

export default useOrdersStore;
