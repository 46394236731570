import {
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useState, useCallback } from "react";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import { FaSpinner, FaUpload, FaIdCard } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase/firebaseConfig";
import { toastOptions } from "../../utils/helpers";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import LayoutHeader from "../../components/LayoutHeader";
import useOrdersStore from "../../utils/useOrdersStore";
import { DEFAULT_FILE_URL, DEFAULT_PHOTO_URL } from "../../utils/constants";

const FormSection = ({ title, children }) => (
  <div className="p-4 mb-6 bg-white rounded-lg shadow-md">
    <h3 className="pb-2 mb-4 text-lg font-bold text-blue-900 border-b border-gray-200">
      {title}
    </h3>
    <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-2 md:grid-cols-3">
      {children}
    </div>
  </div>
);

const FormField = ({ label, required, id, children }) => (
  <div className="mb-3">
    <label
      className="block mb-1 text-sm font-semibold text-gray-700"
      htmlFor={id}
    >
      {label} {required && <span className="text-red-500">*</span>}
    </label>
    {children}
  </div>
);

const QRCodeModal = ({ showModal, handleCloseModal, upiPaymentMethod }) =>
  showModal && (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-xl">
        <div className="text-center">
          <h3 className="mb-3 text-xl font-bold text-blue-900">Scan to Pay</h3>
          <img
            src={
              upiPaymentMethod === "googlePay"
                ? "/assets/google-pay-qr-code.png"
                : "/assets/phone-pay-qr-code.png"
            }
            alt={
              upiPaymentMethod === "googlePay"
                ? "Google Pay QR Code"
                : "Phone Pay QR Code"
            }
            className="object-contain mx-auto h-72 md:h-80"
          />
          <p className="mt-3 text-gray-600">Scan the QR code to make payment</p>
          <button
            onClick={handleCloseModal}
            className="px-4 py-2 mt-4 font-semibold text-white transition duration-200 bg-blue-900 rounded-lg hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );

export default function AddOrder() {
  const { addOrder } = useOrdersStore();
  const navigate = useNavigate();
  const storage = getStorage();

  // Modal state
  const [showModal, setShowModal] = useState(false);
  const [upiPaymentMethod, setUpiPaymentMethod] = useState("");

  // Form state
  const [firmName, setFirmName] = useState("jk_solar_energy");
  const [pmsgyCustomer, setPmsgyCustomer] = useState("no");
  const [advisorCode, setAdvisorCode] = useState("");
  const [advisorName, setAdvisorName] = useState("");

  // Customer details
  const [customerName, setCustomerName] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerPhoneNo, setCustomerPhoneNo] = useState("");
  const [customerAlternatePhoneNo, setCustomerAlternatePhoneNo] = useState("");
  const [customerAadharNo, setCustomerAadharNo] = useState("");
  const [customerPanNo, setCustomerPanNo] = useState("");
  const [customerParentage, setCustomerParentage] = useState("");
  const [customerBankName, setCustomerBankName] = useState("");
  const [customerBankAccountNo, setCustomerBankAccountNo] = useState("");
  const [customerBankIfscCode, setCustomerBankIfscCode] = useState("");
  const [customerPhotoUrl, setCustomerPhotoUrl] = useState("");
  const [customerOccupation, setCustomerOccupation] = useState("");
  const [customerQualification, setCustomerQualification] = useState("");

  // Guarantor details
  const [guarantor, setGuarantor] = useState(false);
  const [guarantorName, setGuarantorName] = useState("");
  const [guarantorPanNo, setGuarantorPanNo] = useState("");
  const [guarantorAadharNo, setGuarantorAadharNo] = useState("");
  const [guarantorAddress, setGuarantorAddress] = useState("");
  const [guarantorBankAccountNo, setGuarantorBankAccountNo] = useState("");
  const [guarantorBankName, setGuarantorBankName] = useState("");
  const [guarantorIfscCode, setGuarantorIfscCode] = useState("");
  const [guarantorParentage, setGuarantorParentage] = useState("");
  const [guarantorPhoneNo, setGuarantorPhoneNo] = useState("");
  const [guarantorPhotoUrl, setGuarantorPhotoUrl] = useState("");
  const [guarantorOccupation, setGuarantorOccupation] = useState("");
  const [guarantorQualification, setGuarantorQualification] = useState("");

  // Payment details
  const [finance, setFinance] = useState(false);
  const [isCash, setIsCash] = useState(false);
  const [financedBy, setFinancedBy] = useState("");

  // Order details
  const [orderDetails, setOrderDetails] = useState("");
  const [orderCost, setOrderCost] = useState("");
  const [orderNotes, setOrderNotes] = useState("");
  const [orderDate, setOrderDate] = useState("");

  // Payment method details
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [downpayment, setDownpayment] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [chequeNumber, setChequeNumber] = useState("");
  const [chequeDate, setChequeDate] = useState("");
  const [chequeBankName, setChequeBankName] = useState("");

  // UI state
  const [addBtnClicked, setAddBtnClicked] = useState(false);
  const [customerImageUploading, setCustomerImageUploading] = useState(false);
  const [guarantorImageUploading, setGuarantorImageUploading] = useState(false);

  // Document URLs
  const [electricityBillUrl, setElectricityBillUrl] = useState("");
  const [bankPassbookUrl, setBankPassbookUrl] = useState("");

  // Document upload state
  const [electricityBillUploading, setElectricityBillUploading] =
    useState(false);
  const [bankPassbookUploading, setBankPassbookUploading] = useState(false);

  // Additional order details
  const [performaBillNo, setPerformaBillNo] = useState("");
  const [challanNo, setChallanNo] = useState("");
  const [commission, setCommission] = useState("");

  // Employee state
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  // Modal handlers
  const handlePaymentClick = (method) => {
    setUpiPaymentMethod(method);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // File upload handler
  const handleFileUpload = useCallback(
    async (e, type, setUrl, setLoading, defaultUrl) => {
      try {
        const file = e.target.files[0];
        setLoading(true);

        if (!file) {
          toast.error("Please choose a file first!", toastOptions);
          setLoading(false);
          return;
        }

        const timestamp = Date.now();
        const storageRef = ref(storage, `/orders/${timestamp}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        await uploadTask;
        const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);

        setUrl(downloadUrl);
        setLoading(false);
      } catch (error) {
        setUrl(defaultUrl);
        setLoading(false);
        toast.error("Upload failed. Please try again.", toastOptions);
        console.error(error);
      }
    },
    [storage]
  );

  // Specialized upload handlers
  const handleCustomerImageUpload = (e) =>
    handleFileUpload(
      e,
      "customer",
      setCustomerPhotoUrl,
      setCustomerImageUploading,
      DEFAULT_PHOTO_URL
    );

  const handleGuarantorImageUpload = (e) =>
    handleFileUpload(
      e,
      "guarantor",
      setGuarantorPhotoUrl,
      setGuarantorImageUploading,
      DEFAULT_PHOTO_URL
    );

  const handleElectricityBillUpload = (e) =>
    handleFileUpload(
      e,
      "electricity_bill",
      setElectricityBillUrl,
      setElectricityBillUploading,
      DEFAULT_FILE_URL
    );

  const handleBankPassbookUpload = (e) =>
    handleFileUpload(
      e,
      "bank_passbook",
      setBankPassbookUrl,
      setBankPassbookUploading,
      DEFAULT_FILE_URL
    );

  // Employee selection handlers
  const handleAdvisorNameChange = (e) => {
    const inputName = e.target.value;
    setAdvisorName(inputName);

    if (inputName.trim() === "") {
      setFilteredEmployees([]);
      return;
    }

    const filtered = employees.filter((employee) =>
      employee.employee_name.toLowerCase().includes(inputName.toLowerCase())
    );
    setFilteredEmployees(filtered);
  };

  const handleEmployeeSelection = (selectedEmployee) => {
    setAdvisorName(selectedEmployee.employee_name);
    setAdvisorCode(selectedEmployee.employee_code);
    setFilteredEmployees([]);
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setAddBtnClicked(true);

    const data = {
      firm_name: firmName,
      advisor_code: advisorCode === "" ? "N/A" : advisorCode,
      advisor_name: advisorName === "" ? "N/A" : advisorName,
      customer_name: customerName,
      customer_address: customerAddress,
      customer_phone_no: customerPhoneNo,
      customer_alternate_phone_no:
        customerAlternatePhoneNo === "" ? "N/A" : customerAlternatePhoneNo,
      customer_aadhar_no: customerAadharNo === "" ? "N/A" : customerAadharNo,
      customer_pan_no: customerPanNo === "" ? "N/A" : customerPanNo,
      customer_parentage: customerParentage,
      customer_bank_account_no:
        customerBankAccountNo === "" ? "N/A" : customerBankAccountNo,
      customer_bank_name: customerBankName === "" ? "N/A" : customerBankName,
      customer_bank_ifsc_code:
        customerBankIfscCode === "" ? "N/A" : customerBankIfscCode,
      customer_photo_url: customerPhotoUrl,
      customer_occupation: customerOccupation,
      customer_qualification: customerQualification,
      guarantor: guarantor,
      guarantor_name: guarantorName,
      guarantor_pan_no: guarantorPanNo,
      guarantor_aadhar_no: guarantorAadharNo,
      guarantor_address: guarantorAddress,
      guarantor_bank_account_no: guarantorBankAccountNo,
      guarantor_bank_name: guarantorBankName,
      guarantor_bank_ifsc_code: guarantorIfscCode,
      guarantor_parentage: guarantorParentage,
      guarantor_phone_no: guarantorPhoneNo,
      guarantor_photo_url: guarantorPhotoUrl,
      guarantor_occupation: guarantorOccupation,
      guarantor_qualification: guarantorQualification,
      finance: finance,
      financed_by: financedBy,
      order_details: orderDetails,
      order_date: orderDate,
      order_cost: orderCost,
      order_notes: orderNotes,
      payment_method: paymentMethod,
      downpayment: downpayment === "" ? "N/A" : downpayment,
      transaction_id: transactionId,
      chequeNumber: chequeNumber,
      chequeDate: chequeDate,
      chequeBankName: chequeBankName,
      advisor: true,
      order_status: "pending",
      customer_id: `${
        firmName === "jk_solar_energy"
          ? "JKSE"
          : firmName === "saaslynx"
          ? "SSLX"
          : "JKSS"
      }-CUS-${customerPhoneNo}`,
      performaBillNo: performaBillNo,
      challanNo: challanNo === "" ? "N/A" : challanNo,
      commission: commission === "" ? "N/A" : commission,
      pmsgyCustomer: pmsgyCustomer,
      electricityBillUrl: electricityBillUrl,
      bankPassbookUrl: bankPassbookUrl,
      created_at: serverTimestamp(),
    };

    try {
      const newDocRef = doc(collection(db, "orders"));
      await setDoc(newDocRef, data);

      // Set firm
      const firmCode =
        firmName === "jk_solar_energy"
          ? "JKSE"
          : firmName === "saaslynx"
          ? "SSLX"
          : "JKSS";

      // Check if customer id is present in customers collection
      const customerDocRef = doc(
        db,
        "customers",
        `${firmCode}-CUS-${customerPhoneNo}`
      );
      const customerDoc = await getDoc(customerDocRef);

      if (!customerDoc.exists()) {
        // If not present then add customer to customers collection
        const customerData = {
          customer_name: customerName,
          customer_phone_no: customerPhoneNo,
          photoUrl: customerPhotoUrl,
          firmName: firmName,
          createdAt: serverTimestamp(),
        };
        await setDoc(customerDocRef, customerData);
      }

      setAddBtnClicked(false);
      toast.success("Order added successfully!", toastOptions);
      addOrder(data);
      navigate("/admin/orders");
    } catch (error) {
      setAddBtnClicked(false);
      toast.error("Failed to add order. Please try again.", toastOptions);
      console.error(error);
    }
  };

  // Get all employees
  const getAllEmployees = async () => {
    try {
      const employees = [];
      const employeesRef = collection(db, "employee_codes");
      const employeesSnapshot = await getDocs(employeesRef);

      employeesSnapshot.forEach((doc) => {
        employees.push({
          employee_name: doc.data().employee_name,
          employee_code: doc.data().employee_code,
        });
      });

      // Others (if employee is not applicable)
      employees.push({
        employee_name: "Others",
        employee_code: "Others",
      });

      setEmployees(employees);
    } catch (error) {
      console.error("Error fetching employees:", error);
      toast.error("Failed to load employee data", toastOptions);
    }
  };

  // Component did mount
  useEffect(() => {
    setCustomerPhotoUrl(DEFAULT_PHOTO_URL);
    setGuarantorPhotoUrl(DEFAULT_PHOTO_URL);
    setElectricityBillUrl(DEFAULT_FILE_URL);
    setBankPassbookUrl(DEFAULT_FILE_URL);
    getAllEmployees();
  }, []);

  // Input field style classes
  const inputClass =
    "w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm";
  const selectClass =
    "w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm";
  const textareaClass =
    "w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm";
  const buttonClass =
    "px-4 py-2 text-white bg-blue-900 rounded-lg hover:bg-blue-800 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 text-sm font-medium";
  const checkboxClass =
    "w-4 h-4 text-blue-600 border border-gray-300 rounded focus:ring-blue-500";
  const uploadButtonClass =
    "flex items-center gap-2 px-3 py-2 text-sm font-medium text-white bg-blue-900 rounded-lg hover:bg-blue-800 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50";

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="min-h-screen pb-8 bg-gray-50">
          <LayoutHeader title={"Add Order"} />

          <div className="px-4 py-6 mx-auto max-w-7xl">
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Basic Information */}
              <FormSection title="Basic Information">
                <FormField label="Firm Name" required id="firmName">
                  <select
                    id="firmName"
                    required
                    value={firmName ?? ""}
                    onChange={(e) => setFirmName(e.target.value)}
                    className={selectClass}
                  >
                    <option value="">Select Firm Name</option>
                    <option value="jk_solar_energy">JK Solar Energy</option>
                    <option value="jk_sale_and_service">
                      JK Sale and Service Agency
                    </option>
                    <option value="saaslynx">SaasLynx</option>
                  </select>
                </FormField>

                <FormField label="PMSGY Customer" required id="pmsgyCustomer">
                  <select
                    id="pmsgyCustomer"
                    required
                    value={pmsgyCustomer ?? ""}
                    onChange={(e) => setPmsgyCustomer(e.target.value)}
                    className={selectClass}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </FormField>

                <FormField label="Order Date" required id="orderDate">
                  <input
                    type="date"
                    id="orderDate"
                    required
                    value={orderDate ?? ""}
                    onChange={(e) => setOrderDate(e.target.value)}
                    className={inputClass}
                  />
                </FormField>

                <FormField label="Advisor Code" id="advisorCode">
                  <input
                    type="text"
                    id="advisorCode"
                    maxLength={8}
                    placeholder="Optional"
                    value={advisorCode ?? ""}
                    onChange={(e) =>
                      setAdvisorCode(e.target.value.toUpperCase())
                    }
                    className={inputClass}
                  />
                </FormField>

                <FormField label="Advisor Name" id="advisorName">
                  <div className="relative">
                    <input
                      type="text"
                      id="advisorName"
                      placeholder="Optional"
                      value={advisorName ?? ""}
                      onChange={handleAdvisorNameChange}
                      className={inputClass}
                    />
                    {filteredEmployees.length > 0 && (
                      <ul className="absolute z-10 w-full mt-1 overflow-y-auto bg-white border border-gray-300 rounded-md shadow-lg max-h-48">
                        {filteredEmployees.map((employee) => (
                          <li
                            key={employee.employee_code}
                            className="px-3 py-2 text-sm cursor-pointer hover:bg-gray-100"
                            onClick={() => handleEmployeeSelection(employee)}
                          >
                            {employee.employee_name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </FormField>
              </FormSection>

              {/* Customer Information */}
              <FormSection title="Customer Information">
                <div className="grid grid-cols-1 gap-4 col-span-full sm:col-span-2 md:col-span-2 sm:grid-cols-2">
                  <div>
                    <FormField label="Customer Name" required id="customerName">
                      <input
                        type="text"
                        id="customerName"
                        required
                        placeholder="Enter Customer Name"
                        value={customerName ?? ""}
                        onChange={(e) => setCustomerName(e.target.value)}
                        className={inputClass}
                      />
                    </FormField>

                    <FormField
                      label="Customer Parentage"
                      required
                      id="customerParentage"
                    >
                      <input
                        type="text"
                        id="customerParentage"
                        required
                        placeholder="Enter Customer Parentage"
                        value={customerParentage ?? ""}
                        onChange={(e) => setCustomerParentage(e.target.value)}
                        className={inputClass}
                      />
                    </FormField>

                    <FormField
                      label="Customer Address"
                      required
                      id="customerAddress"
                    >
                      <input
                        type="text"
                        id="customerAddress"
                        required
                        placeholder="Enter Customer Address"
                        value={customerAddress ?? ""}
                        onChange={(e) => setCustomerAddress(e.target.value)}
                        className={inputClass}
                      />
                    </FormField>

                    <FormField
                      label="Customer Phone No"
                      required
                      id="customerPhoneNo"
                    >
                      <input
                        type="text"
                        maxLength={10}
                        id="customerPhoneNo"
                        required
                        placeholder="Enter Customer Phone Number"
                        value={customerPhoneNo ?? ""}
                        onChange={(e) => setCustomerPhoneNo(e.target.value)}
                        className={inputClass}
                      />
                    </FormField>

                    <FormField
                      label="Alternate Phone No"
                      id="customerAlternatePhoneNo"
                    >
                      <input
                        type="text"
                        maxLength={10}
                        id="customerAlternatePhoneNo"
                        placeholder="Optional"
                        value={customerAlternatePhoneNo ?? ""}
                        onChange={(e) =>
                          setCustomerAlternatePhoneNo(e.target.value)
                        }
                        className={inputClass}
                      />
                    </FormField>
                  </div>

                  <div className="flex flex-col items-center justify-center">
                    <div className="w-32 h-32 mb-2 overflow-hidden border-2 border-blue-900 rounded-lg">
                      {customerPhotoUrl && (
                        <img
                          src={customerPhotoUrl}
                          alt="customer"
                          className="object-cover w-full h-full"
                        />
                      )}
                    </div>
                    {!customerImageUploading ? (
                      <div className="text-center">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleCustomerImageUpload}
                          className="hidden"
                          id="customer-image-upload"
                        />
                        <label
                          htmlFor="customer-image-upload"
                          className={uploadButtonClass}
                        >
                          <FaUpload /> Upload Photo
                        </label>
                      </div>
                    ) : (
                      <div className="flex items-center justify-center mt-2">
                        <FaSpinner
                          className="text-blue-900 animate-spin"
                          size={20}
                        />
                        <span className="ml-2 text-sm text-gray-600">
                          Uploading...
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <FormField label="Occupation" required id="customerOccupation">
                  <input
                    type="text"
                    id="customerOccupation"
                    placeholder="Enter Customer Occupation"
                    required
                    value={customerOccupation ?? ""}
                    onChange={(e) => setCustomerOccupation(e.target.value)}
                    className={inputClass}
                  />
                </FormField>

                <FormField
                  label="Qualification"
                  required
                  id="customerQualification"
                >
                  <input
                    type="text"
                    id="customerQualification"
                    placeholder="Enter Customer Qualification"
                    required
                    value={customerQualification ?? ""}
                    onChange={(e) => setCustomerQualification(e.target.value)}
                    className={inputClass}
                  />
                </FormField>

                <FormField label="Aadhar No" id="customerAadharNo">
                  <input
                    type="text"
                    id="customerAadharNo"
                    placeholder="Optional"
                    value={customerAadharNo ?? ""}
                    onChange={(e) => setCustomerAadharNo(e.target.value)}
                    className={inputClass}
                  />
                </FormField>

                <FormField label="PAN No" id="customerPanNo">
                  <input
                    type="text"
                    id="customerPanNo"
                    placeholder="Optional"
                    value={customerPanNo ?? ""}
                    onChange={(e) => setCustomerPanNo(e.target.value)}
                    className={inputClass}
                  />
                </FormField>

                <FormField label="Bank Name" id="customerBankName">
                  <input
                    type="text"
                    id="customerBankName"
                    placeholder="Optional"
                    value={customerBankName ?? ""}
                    onChange={(e) => setCustomerBankName(e.target.value)}
                    className={inputClass}
                  />
                </FormField>

                <FormField label="Bank Account No" id="customerBankAccountNo">
                  <input
                    type="text"
                    id="customerBankAccountNo"
                    placeholder="Optional"
                    value={customerBankAccountNo ?? ""}
                    onChange={(e) => setCustomerBankAccountNo(e.target.value)}
                    className={inputClass}
                  />
                </FormField>

                <FormField label="Bank IFSC Code" id="customerBankIfscCode">
                  <input
                    type="text"
                    id="customerBankIfscCode"
                    placeholder="Optional"
                    value={customerBankIfscCode ?? ""}
                    onChange={(e) => setCustomerBankIfscCode(e.target.value)}
                    className={inputClass}
                  />
                </FormField>
              </FormSection>

              {/* Guarantor Information */}
              <div className="p-4 mb-6 bg-white rounded-lg shadow-md">
                <div className="flex items-center mb-4">
                  <input
                    type="checkbox"
                    id="guarantorCheck"
                    className={checkboxClass}
                    checked={guarantor ?? false}
                    onChange={(e) => setGuarantor(e.target.checked)}
                  />
                  <label
                    htmlFor="guarantorCheck"
                    className="ml-2 text-lg font-bold text-blue-900"
                  >
                    Add Guarantor
                  </label>
                </div>

                {guarantor && (
                  <div className="mt-4">
                    <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-2 md:grid-cols-3">
                      <div className="grid grid-cols-1 gap-4 col-span-full sm:col-span-2 md:col-span-2 sm:grid-cols-2">
                        <div>
                          <FormField label="Guarantor Name" id="guarantorName">
                            <input
                              type="text"
                              id="guarantorName"
                              placeholder="Enter Guarantor Name"
                              value={guarantorName ?? ""}
                              onChange={(e) => setGuarantorName(e.target.value)}
                              className={inputClass}
                            />
                          </FormField>

                          <FormField
                            label="Guarantor Parentage"
                            id="guarantorParentage"
                          >
                            <input
                              type="text"
                              id="guarantorParentage"
                              placeholder="Enter Guarantor Parentage"
                              value={guarantorParentage ?? ""}
                              onChange={(e) =>
                                setGuarantorParentage(e.target.value)
                              }
                              className={inputClass}
                            />
                          </FormField>

                          <FormField
                            label="Guarantor Address"
                            id="guarantorAddress"
                          >
                            <input
                              type="text"
                              id="guarantorAddress"
                              placeholder="Enter Guarantor Address"
                              value={guarantorAddress ?? ""}
                              onChange={(e) =>
                                setGuarantorAddress(e.target.value)
                              }
                              className={inputClass}
                            />
                          </FormField>

                          <FormField
                            label="Guarantor Phone No"
                            id="guarantorPhoneNo"
                          >
                            <input
                              type="text"
                              id="guarantorPhoneNo"
                              placeholder="Enter Guarantor Phone Number"
                              value={guarantorPhoneNo ?? ""}
                              onChange={(e) =>
                                setGuarantorPhoneNo(e.target.value)
                              }
                              className={inputClass}
                            />
                          </FormField>
                        </div>

                        <div className="flex flex-col items-center justify-center">
                          <div className="w-32 h-32 mb-2 overflow-hidden border-2 border-blue-900 rounded-lg">
                            {guarantorPhotoUrl && (
                              <img
                                src={guarantorPhotoUrl}
                                alt="guarantor"
                                className="object-cover w-full h-full"
                              />
                            )}
                          </div>
                          {!guarantorImageUploading ? (
                            <div className="text-center">
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handleGuarantorImageUpload}
                                className="hidden"
                                id="guarantor-image-upload"
                              />
                              <label
                                htmlFor="guarantor-image-upload"
                                className={uploadButtonClass}
                              >
                                <FaUpload /> Upload Photo
                              </label>
                            </div>
                          ) : (
                            <div className="flex items-center justify-center mt-2">
                              <FaSpinner
                                className="text-blue-900 animate-spin"
                                size={20}
                              />
                              <span className="ml-2 text-sm text-gray-600">
                                Uploading...
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                      <FormField label="Occupation" id="guarantorOccupation">
                        <input
                          type="text"
                          id="guarantorOccupation"
                          placeholder="Enter Guarantor Occupation"
                          value={guarantorOccupation ?? ""}
                          onChange={(e) =>
                            setGuarantorOccupation(e.target.value)
                          }
                          className={inputClass}
                        />
                      </FormField>

                      <FormField
                        label="Qualification"
                        id="guarantorQualification"
                      >
                        <input
                          type="text"
                          id="guarantorQualification"
                          placeholder="Enter Guarantor Qualification"
                          value={guarantorQualification ?? ""}
                          onChange={(e) =>
                            setGuarantorQualification(e.target.value)
                          }
                          className={inputClass}
                        />
                      </FormField>

                      <FormField label="Aadhar No" id="guarantorAadharNo">
                        <input
                          type="text"
                          id="guarantorAadharNo"
                          placeholder="Enter Guarantor Aadhar No"
                          value={guarantorAadharNo ?? ""}
                          onChange={(e) => setGuarantorAadharNo(e.target.value)}
                          className={inputClass}
                        />
                      </FormField>

                      <FormField label="PAN No" id="guarantorPanNo">
                        <input
                          type="text"
                          id="guarantorPanNo"
                          placeholder="Enter Guarantor PAN no"
                          value={guarantorPanNo ?? ""}
                          onChange={(e) => setGuarantorPanNo(e.target.value)}
                          className={inputClass}
                        />
                      </FormField>

                      <FormField label="Bank Name" id="guarantorBankName">
                        <input
                          type="text"
                          id="guarantorBankName"
                          placeholder="Enter Guarantor Bank Name"
                          value={guarantorBankName ?? ""}
                          onChange={(e) => setGuarantorBankName(e.target.value)}
                          className={inputClass}
                        />
                      </FormField>

                      <FormField
                        label="Bank Account No"
                        id="guarantorBankAccountNo"
                      >
                        <input
                          type="text"
                          id="guarantorBankAccountNo"
                          placeholder="Enter Guarantor Bank Account No"
                          value={guarantorBankAccountNo ?? ""}
                          onChange={(e) =>
                            setGuarantorBankAccountNo(e.target.value)
                          }
                          className={inputClass}
                        />
                      </FormField>

                      <FormField label="Bank IFSC Code" id="guarantorIfscCode">
                        <input
                          type="text"
                          id="guarantorIfscCode"
                          placeholder="Enter Guarantor Bank IFSC Code"
                          value={guarantorIfscCode ?? ""}
                          onChange={(e) => setGuarantorIfscCode(e.target.value)}
                          className={inputClass}
                        />
                      </FormField>
                    </div>
                  </div>
                )}
              </div>

              {/* Order Details */}
              <FormSection title="Order Details">
                <div className="col-span-full sm:col-span-2">
                  <FormField label="Order Details" required id="orderDetails">
                    <textarea
                      id="orderDetails"
                      required
                      rows={4}
                      placeholder="Enter Order Details"
                      value={orderDetails ?? ""}
                      onChange={(e) => setOrderDetails(e.target.value)}
                      className={textareaClass}
                    />
                  </FormField>
                </div>

                <FormField label="Order Cost" required id="orderCost">
                  <input
                    type="number"
                    id="orderCost"
                    required
                    placeholder="Enter Order Cost"
                    value={orderCost ?? ""}
                    onChange={(e) => setOrderCost(e.target.value)}
                    className={inputClass}
                  />
                </FormField>

                <div className="col-span-full sm:col-span-2">
                  <FormField label="Order Notes" id="orderNotes">
                    <textarea
                      id="orderNotes"
                      rows={3}
                      placeholder="Enter Order Notes (Optional)"
                      value={orderNotes ?? ""}
                      onChange={(e) => setOrderNotes(e.target.value)}
                      className={textareaClass}
                    />
                  </FormField>
                </div>

                <FormField label="Performa Bill No" id="performaBillNo">
                  <input
                    type="text"
                    id="performaBillNo"
                    placeholder="Performa Bill No"
                    value={performaBillNo ?? ""}
                    onChange={(e) => setPerformaBillNo(e.target.value)}
                    className={inputClass}
                  />
                </FormField>

                <FormField label="Challan No" id="challanNo">
                  <input
                    type="text"
                    id="challanNo"
                    placeholder="Enter Challan No (Optional)"
                    value={challanNo ?? ""}
                    onChange={(e) => setChallanNo(e.target.value)}
                    className={inputClass}
                  />
                </FormField>

                <FormField label="Total Commission" id="commission">
                  <input
                    type="text"
                    id="commission"
                    placeholder="Enter Total Commission (Optional)"
                    value={commission ?? ""}
                    onChange={(e) => setCommission(e.target.value)}
                    className={inputClass}
                  />
                </FormField>
              </FormSection>

              {/* Payment Information */}
              <FormSection title="Payment Information">
                <div className="flex flex-wrap gap-4 col-span-full sm:col-span-2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="financeCheck"
                      className={checkboxClass}
                      checked={finance ?? false}
                      onChange={(e) => {
                        setIsCash(false);
                        setFinance(e.target.checked);
                      }}
                    />
                    <label
                      htmlFor="financeCheck"
                      className="ml-2 text-gray-700"
                    >
                      Finance
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="cashCheck"
                      className={checkboxClass}
                      checked={isCash ?? false}
                      onChange={(e) => {
                        setFinance(false);
                        setIsCash(e.target.checked);
                      }}
                    />
                    <label htmlFor="cashCheck" className="ml-2 text-gray-700">
                      Cash Payment (No Finance)
                    </label>
                  </div>
                </div>

                {finance && (
                  <div className="col-span-full sm:col-span-2">
                    <FormField label="Finance Partner" id="financedBy">
                      <select
                        id="financedBy"
                        value={financedBy ?? ""}
                        onChange={(e) => setFinancedBy(e.target.value)}
                        className={selectClass}
                      >
                        <option value="">Select Finance Partner</option>
                        <option value="j_and_k_bank">J&K Bank</option>
                        <option value="sbi_bank">SBI Bank</option>
                        <option value="hdfc_bank">HDFC Bank</option>
                        <option value="bajaj_finserv">Bajaj Finserv</option>
                        <option value="samsung_finance">Samsung Finance</option>
                      </select>
                    </FormField>
                  </div>
                )}

                <FormField label="Payment Method" required id="paymentMethod">
                  <select
                    id="paymentMethod"
                    value={paymentMethod ?? ""}
                    required
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    className={selectClass}
                  >
                    <option value="">Select Payment Method</option>
                    <option value="cash">Cash</option>
                    <option value="cheque">Cheque</option>
                    <option value="card">Card</option>
                    <option value="upi">UPI</option>
                  </select>
                </FormField>

                <FormField label="Downpayment" id="downpayment">
                  <input
                    type="number"
                    id="downpayment"
                    placeholder="Optional"
                    value={downpayment ?? ""}
                    onChange={(e) => setDownpayment(e.target.value)}
                    className={inputClass}
                  />
                </FormField>

                {(paymentMethod === "card" || paymentMethod === "upi") && (
                  <FormField label="Transaction ID" id="transactionId">
                    <input
                      type="text"
                      id="transactionId"
                      placeholder="Enter Transaction Id"
                      value={transactionId ?? ""}
                      onChange={(e) => setTransactionId(e.target.value)}
                      className={inputClass}
                    />
                  </FormField>
                )}

                {paymentMethod === "cheque" && (
                  <>
                    <FormField label="Cheque Number" id="chequeNumber">
                      <input
                        type="text"
                        id="chequeNumber"
                        placeholder="Enter Cheque Number"
                        value={chequeNumber ?? ""}
                        onChange={(e) => setChequeNumber(e.target.value)}
                        className={inputClass}
                      />
                    </FormField>

                    <FormField label="Cheque Bank Name" id="chequeBankName">
                      <input
                        type="text"
                        id="chequeBankName"
                        placeholder="Enter Cheque Bank Name"
                        value={chequeBankName ?? ""}
                        onChange={(e) => setChequeBankName(e.target.value)}
                        className={inputClass}
                      />
                    </FormField>

                    <FormField label="Cheque Date" id="chequeDate">
                      <input
                        type="date"
                        id="chequeDate"
                        required
                        value={chequeDate ?? ""}
                        onChange={(e) => setChequeDate(e.target.value)}
                        className={inputClass}
                      />
                    </FormField>
                  </>
                )}

                <div className="col-span-full sm:col-span-2">
                  <div className="flex flex-wrap items-center gap-4 mt-2">
                    <span className="text-sm font-medium text-gray-700">
                      Quick UPI Payment:
                    </span>
                    <button
                      onClick={() => handlePaymentClick("googlePay")}
                      type="button"
                      className="flex items-center justify-center px-3 py-2 transition duration-200 bg-gray-100 rounded-lg hover:bg-gray-200"
                    >
                      <img
                        className="h-8"
                        src="/assets/gpay_icon.png"
                        alt="Google Pay"
                      />
                    </button>

                    <button
                      onClick={() => handlePaymentClick("phonePay")}
                      type="button"
                      className="flex items-center justify-center px-3 py-2 transition duration-200 bg-gray-100 rounded-lg hover:bg-gray-200"
                    >
                      <img
                        className="h-8"
                        src="/assets/phone_pe_icon.png"
                        alt="PhonePe"
                      />
                    </button>
                  </div>
                </div>
              </FormSection>

              {/* PMSGY Documents */}
              {pmsgyCustomer === "yes" && (
                <FormSection title="PMSGY Documents">
                  <div className="flex flex-wrap justify-start gap-8 col-span-full sm:col-span-2">
                    <div className="flex flex-col items-center">
                      <div className="w-40 h-40 mb-2 overflow-hidden border-2 border-gray-300 rounded-lg">
                        {electricityBillUrl && (
                          <img
                            src={electricityBillUrl}
                            alt="electricity bill"
                            className="object-contain w-full h-full"
                          />
                        )}
                      </div>
                      {!electricityBillUploading ? (
                        <div className="text-center">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleElectricityBillUpload}
                            className="hidden"
                            id="electricity-bill-upload"
                          />
                          <label
                            htmlFor="electricity-bill-upload"
                            className={uploadButtonClass}
                          >
                            <FaIdCard /> Electricity Bill
                          </label>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center mt-2">
                          <FaSpinner
                            className="text-blue-900 animate-spin"
                            size={20}
                          />
                          <span className="ml-2 text-sm text-gray-600">
                            Uploading...
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="flex flex-col items-center">
                      <div className="w-40 h-40 mb-2 overflow-hidden border-2 border-gray-300 rounded-lg">
                        {bankPassbookUrl && (
                          <img
                            src={bankPassbookUrl}
                            alt="bank passbook"
                            className="object-contain w-full h-full"
                          />
                        )}
                      </div>
                      {!bankPassbookUploading ? (
                        <div className="text-center">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleBankPassbookUpload}
                            className="hidden"
                            id="bank-passbook-upload"
                          />
                          <label
                            htmlFor="bank-passbook-upload"
                            className={uploadButtonClass}
                          >
                            <FaIdCard /> Bank Passbook
                          </label>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center mt-2">
                          <FaSpinner
                            className="text-blue-900 animate-spin"
                            size={20}
                          />
                          <span className="ml-2 text-sm text-gray-600">
                            Uploading...
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </FormSection>
              )}

              {/* Submit Button */}
              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={addBtnClicked}
                  className={`${buttonClass} py-3 px-6 text-base font-medium ${
                    addBtnClicked ? "opacity-70 cursor-not-allowed" : ""
                  }`}
                >
                  {addBtnClicked ? (
                    <div className="flex items-center">
                      <FaSpinner className="mr-2 animate-spin" size={20} />
                      <span>Processing...</span>
                    </div>
                  ) : (
                    "Submit Order"
                  )}
                </button>
              </div>
            </form>
          </div>

          {/* QR Code Modal */}
          <QRCodeModal
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            upiPaymentMethod={upiPaymentMethod}
          />
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
}
