import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-4 py-8 text-white bg-blue-900 md:px-8">
      <div className="w-full max-w-md mx-auto text-center">
        {/* Error Code with subtle animation */}
        <h1 className="mb-2 text-5xl font-bold tracking-tight md:text-7xl animate-pulse">
          404
        </h1>

        {/* Divider */}
        <div className="w-16 h-1 mx-auto my-6 rounded-full bg-white/30"></div>

        {/* Error Message */}
        <h2 className="mb-4 text-2xl font-semibold md:text-3xl">
          Page Not Found
        </h2>

        {/* Supporting Text */}
        <p className="mb-8 text-lg leading-relaxed text-white/80">
          The page you're looking for doesn't exist or has been moved.
        </p>

        {/* Back to Home Button */}
        <Link
          to="/"
          className="inline-flex items-center justify-center w-full px-6 py-3 font-medium text-blue-900 transition duration-300 ease-in-out transform bg-white rounded-lg shadow-lg hover:bg-gray-100 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-white/50 focus:ring-opacity-50 md:w-auto"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          Back to Home
        </Link>
      </div>
    </div>
  );
}
