import React, { useEffect, useRef, useState } from "react";
import { db } from "../../firebase/firebaseConfig";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/helpers";
import { FaCalculator, FaMinus, FaPlus, FaPrint, FaSave } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import { ToWords } from "to-words";
import { JKSE_ACCOUNTS, JKSS_ACCOUNTS } from "../../utils/constants";
import { ItemDescriptionCell } from "../../components/ItemDescriptionCell";
import BillsTable from "../../components/BillsTable";

export const Billing = () => {
  const componentRef = useRef();

  const [name, setName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");
  const [date, setDate] = useState("");
  const [prevBill, setPrevBill] = useState("");
  const [firmName, setFirmName] = useState("jk_solar_energy");
  const [billType, setBillType] = useState("bill_performa");
  const [email, setEmail] = useState("");
  const [cusGstin, setCusGstin] = useState("");

  const [billsTable, setBillsTable] = useState(false);

  // Transport and vehicle details
  const [transportDetails, setTransportDetails] = useState({
    transport: "",
    vehicleNo: "",
    grNo: "",
    grDate: "",
    eWayBillNo: "",
    irnAckNo: "",
    irnNo: "",
  });

  const [total, setTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [advancePayment, setAdvancePayment] = useState(0);
  const [roundOff, setRoundOff] = useState(0);

  // Tax calculation properties
  const [taxSummary, setTaxSummary] = useState({
    taxable0: 0,
    taxable5: 0,
    taxable12: 0,
    taxable18: 0,
    sgst0: 0,
    sgst5: 0,
    sgst12: 0,
    sgst18: 0,
    cgst0: 0,
    cgst5: 0,
    cgst12: 0,
    cgst18: 0,
    igst0: 0,
    igst5: 0,
    igst12: 0,
    igst18: 0,
    totalTax: 0,
  });

  const [tableData, setTableData] = useState([
    {
      sno: 1,
      description: "",
      hsnCode: "",
      taxRate: 12,
      qty: 1,
      unit: "PCS",
      rate: 0.0,
      amount: 0.0,
    },
  ]);

  // Calculate tax summary based on table data
  const calculateTaxSummary = (data) => {
    const summary = {
      taxable0: 0,
      taxable5: 0,
      taxable12: 0,
      taxable18: 0,
      sgst0: 0,
      sgst5: 0,
      sgst12: 0,
      sgst18: 0,
      cgst0: 0,
      cgst5: 0,
      cgst12: 0,
      cgst18: 0,
      igst0: 0,
      igst5: 0,
      igst12: 0,
      igst18: 0,
      totalTax: 0,
    };

    data.forEach((item) => {
      const taxableAmount = item.rate * item.qty;
      const taxRate = item.taxRate;

      // Update taxable amounts
      if (taxRate === 0) summary.taxable0 += taxableAmount;
      else if (taxRate === 5) summary.taxable5 += taxableAmount;
      else if (taxRate === 12) summary.taxable12 += taxableAmount;
      else if (taxRate === 18) summary.taxable18 += taxableAmount;

      // Calculate SGST and CGST (half of the total tax rate each)
      const halfTaxRate = taxRate / 2;
      const taxAmount = (taxableAmount * halfTaxRate) / 100;

      if (taxRate === 0) {
        summary.sgst0 = 0;
        summary.cgst0 = 0;
      } else if (taxRate === 5) {
        summary.sgst5 += taxAmount;
        summary.cgst5 += taxAmount;
      } else if (taxRate === 12) {
        summary.sgst12 += taxAmount;
        summary.cgst12 += taxAmount;
      } else if (taxRate === 18) {
        summary.sgst18 += taxAmount;
        summary.cgst18 += taxAmount;
      }

      // Calculate total tax
      summary.totalTax += taxAmount * 2; // Both SGST and CGST
    });

    // Round to 2 decimal places
    Object.keys(summary).forEach((key) => {
      summary[key] = parseFloat(summary[key].toFixed(2));
    });

    return summary;
  };

  const updateGrandTotal = (data) => {
    const totalAmount = data.reduce((acc, item) => acc + item.amount, 0);
    setTotal(parseFloat(totalAmount.toFixed(2)));

    // Calculate total tax
    const taxSummary = calculateTaxSummary(data);
    setTaxSummary(taxSummary);

    // Calculate grand total
    const rawGrandTotal = totalAmount + taxSummary.totalTax - advancePayment;

    // Round to nearest whole number for round off
    const roundedGrandTotal = Math.round(rawGrandTotal);
    const calculatedRoundOff = parseFloat(
      (roundedGrandTotal - rawGrandTotal).toFixed(2)
    );

    setRoundOff(calculatedRoundOff);
    setGrandTotal(roundedGrandTotal);
  };

  const handleCellChange = (rowIndex, field, value) => {
    const updatedTableData = tableData.map((row, index) => {
      if (index === rowIndex) {
        const updatedRow = { ...row, [field]: value };

        // Recalculate amount if quantity or rate changes
        if (field === "qty" || field === "rate") {
          updatedRow.amount = parseFloat(
            (updatedRow.qty * updatedRow.rate).toFixed(2)
          );
        }

        return updatedRow;
      }
      return row;
    });

    setTableData(updatedTableData);
  };

  const handleTransportChange = (field, value) => {
    setTransportDetails({
      ...transportDetails,
      [field]: value,
    });
  };

  const handleAddRow = () => {
    const newRowIndex = tableData.length + 1;
    const newRow = {
      sno: newRowIndex,
      description: "",
      hsnCode: "",
      taxRate: 12,
      qty: 1,
      unit: "PCS",
      rate: 0.0,
      amount: 0.0,
    };

    setTableData([...tableData, newRow]);
  };

  const handleRemoveRow = () => {
    if (tableData.length > 0) {
      const updatedTableData = [...tableData];
      updatedTableData.pop();
      setTableData(updatedTableData);
    }
  };

  const handleCalculate = () => {
    const updatedTableData = [...tableData];
    updatedTableData.forEach((row) => {
      row.amount = parseFloat((row.rate * row.qty).toFixed(2));
    });

    setTableData(updatedTableData);
    updateGrandTotal(updatedTableData);
  };

  const fetchBillNumber = async () => {
    const q = query(collection(db, "codes"));
    const querySnapshot = await getDocs(q);
    setPrevBill(querySnapshot.docs[1].data().bill_number);
  };

  useEffect(() => {
    fetchBillNumber();

    // Set current date in DD/MM/YYYY format
    const today = new Date();
    const formattedDate = `${today.getDate().toString().padStart(2, "0")}/${(
      today.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${today.getFullYear()}`;
    setDate(formattedDate);

    // Default GR date to current date
    setTransportDetails((prev) => ({
      ...prev,
      grDate: formattedDate,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatBillName = (firmName, billType, billNo, name) => {
    let formattedBillType = "";

    switch (billType) {
      case "bill_performa":
        formattedBillType = "Bill Performa";
        break;
      case "tax_invoice":
        formattedBillType = "Tax Invoice";
        break;
      case "estimate":
        formattedBillType = "Estimate";
        break;
      default:
        formattedBillType = "Unknown Bill Type";
    }

    const firm =
      firmName === "jk_solar_energy"
        ? "JKSE"
        : firmName === "saaslynx"
        ? "SSLX"
        : "JKSS";

    return `${firm} - ${formattedBillType} - ${billNo} - ${name}`;
  };

  const PrintStyles = () => (
    <style jsx>{`
      @media print {
        @page {
          size: A4 portrait;
          margin: 0.5cm;
        }

        body,
        table,
        td,
        th,
        input,
        textarea,
        select {
          font-size: 9pt !important;
          line-height: 1.2 !important;
        }

        /* Hide actual textareas and show the print-only divs instead */
        .item-description {
          display: none !important;
        }

        .print-only-content {
          display: block !important;
          position: static !important;
          visibility: visible !important;
          white-space: pre-wrap !important;
          font-size: 9pt !important;
          line-height: 1.2 !important;
          padding: 2px !important;
          min-height: auto !important;
          max-height: none !important;
          overflow: visible !important;
        }

        .item-description-wrapper {
          page-break-inside: auto !important;
          break-inside: auto !important;
          height: auto !important;
        }

        .bill-container {
          border: 1px solid #000 !important;
          max-height: none !important;
          overflow: visible !important;
        }

        .company-logo {
          width: 60px !important;
          height: 60px !important;
        }

        .company-header {
          padding: 0.2rem !important;
        }

        .company-title {
          font-size: 16pt !important;
          font-weight: bold !important;
          margin-bottom: 0 !important;
        }

        .company-subtitle {
          font-size: 8pt !important;
          margin: 0 !important;
          line-height: 1.1 !important;
        }

        .invoice-details td {
          padding: 2px 4px !important;
        }

        .invoice-details input {
          width: auto !important;
        }

        .customer-details {
          padding: 0.2rem !important;
        }

        .customer-name {
          font-weight: bold !important;
          margin-bottom: 2px !important;
        }

        .customer-address {
          height: auto !important;
          min-height: 30px !important;
          max-height: 50px !important;
        }

        .items-table th {
          padding: 2px !important;
          font-size: 8pt !important;
        }

        .items-table td {
          padding: 2px !important;
          font-size: 8pt !important;
          height: auto !important;
        }

        tr,
        td {
          page-break-inside: auto !important;
          break-inside: auto !important;
          height: auto !important;
        }

        .compact-row {
          height: auto !important;
          page-break-inside: auto !important;
          break-inside: auto !important;
        }

        .compact-row td {
          vertical-align: top !important;
          padding-top: 4px !important;
          padding-bottom: 4px !important;
          height: auto !important;
        }

        .item-table-container {
          overflow: visible !important;
          page-break-inside: auto !important;
          break-inside: auto !important;
        }

        /* Terms and conditions section */
        .footer-section {
          page-break-inside: avoid !important;
        }

        .terms-section ol {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
          padding-left: 16px !important;
        }

        .terms-section li {
          font-size: 7.5pt !important;
          line-height: 1.1 !important;
          margin-bottom: 2px !important;
        }

        .terms-section .declaration {
          font-size: 7.5pt !important;
          line-height: 1.1 !important;
          padding: 4px !important;
          margin-top: 4px !important;
        }

        .tax-summary td {
          padding: 1px 2px !important;
          font-size: 8pt !important;
        }

        .bank-details {
          padding: 0.2rem !important;
          font-size: 8pt !important;
          page-break-inside: avoid !important;
        }

        .terms-section {
          padding: 0.2rem !important;
          font-size: 8pt !important;
          page-break-inside: avoid !important;
        }

        .qr-code {
          width: 70px !important;
          height: 70px !important;
        }

        .no-print {
          display: none !important;
        }

        textarea,
        input,
        select {
          border: none !important;
        }
      }
  
      .customer-details {
        padding: 0.1rem !important;
      }
      
      .customer-name {
        font-size: 8pt !important;
        margin-bottom: 0 !important;
        height: 16px !important;
      }
      
      .customer-address {
        font-size: 7.5pt !important;
        line-height: 1 !important;
        min-height: 20px !important;
        max-height: 30px !important;
        margin-bottom: 0 !important;
      }
      
      .customer-details .flex {
        margin-top: 0 !important;
      }
      
      .customer-details input,
      .customer-details textarea {
        padding: 0 !important;
        height: auto !important;
      }
      
      /* Make text smaller in the customer info sections */
      .customer-details .w-1/3,
      .customer-details .w-2/3 {
        font-size: 7pt !important;
        line-height: 1 !important;
      }
      
      /* Reduce spacing between rows */
      .customer-details .flex + .flex {
        margin-top: 0 !important;
      }
    }
  `}</style>
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: formatBillName(
      firmName,
      billType,
      firmName === "jk_solar_energy"
        ? "JKSE" + prevBill.toString()
        : firmName === "saaslynx"
        ? "SSLX" + prevBill.toString()
        : "JKSS" + prevBill.toString(),
      name
    ),
    onBeforeGetContent: () => {
      // First calculate everything
      handleCalculate();

      // Update all the hidden content divs with current textarea content
      document.querySelectorAll(".item-description").forEach((el) => {
        const wrapper = el.closest(".item-description-wrapper");
        if (wrapper) {
          const printDiv = wrapper.querySelector(".print-only-content");
          if (printDiv) {
            printDiv.innerHTML = el.value.replace(/\n/g, "<br/>");
          }
        }
      });

      return Promise.resolve();
    },
    removeAfterPrint: true,
  });

  const handleSave = async () => {
    handleCalculate();
    const billNumber =
      firmName === "jk_solar_energy"
        ? "JKSE" + prevBill.toString()
        : firmName === "saaslynx"
        ? "SSLX" + prevBill.toString()
        : "JKSS" + prevBill.toString();

    const data = {
      name: name,
      phoneNo: phoneNo,
      email: email,
      address: address,
      date: date,
      billNo: billNumber,
      cusGstin: cusGstin,
      firmName: firmName,
      billType: billType,
      transportDetails: transportDetails,
      total: total,
      taxSummary: taxSummary,
      advancePayment: advancePayment,
      roundOff: roundOff,
      grandTotal: grandTotal,
      tableData: tableData,
    };

    try {
      const newDocRef = doc(collection(db, "billing"));
      await setDoc(newDocRef, data);

      // update bill number
      const updateRef = doc(db, "codes", "last_bill_number");
      await updateDoc(updateRef, { bill_number: prevBill + 1 });

      toast.success("Bill saved successfully!", toastOptions);
    } catch (error) {
      console.error(error);
      toast.error(error.code, toastOptions);
    }
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const logo =
    firmName === "jk_solar_energy"
      ? "/assets/jkse_logo.png"
      : firmName === "saaslynx"
      ? "/assets/saaslynx_logo.png"
      : "/assets/jkss_logo.png";

  const gstin =
    firmName === "jk_solar_energy"
      ? "01BTVPK3249N2Z5"
      : firmName === "saaslynx"
      ? ""
      : "01ATXPA5399N1Z0";

  const gemSellerId = "R2YE220006777671";
  const tinNo = "01732104148";

  return (
    <ProtectedRoute>
      <AdminLayout>
        <PrintStyles />
        <div className="h-full max-w-5xl pb-4 mx-auto bg-white">
          {/* Control buttons - won't show in print */}
          <div className="flex flex-row justify-end mx-4 mt-4 mb-4 space-x-4 no-print">
            <button
              onClick={() => setBillsTable(!billsTable)}
              type="button"
              className="inline-flex items-center px-4 py-2 font-semibold text-white bg-gray-700 border border-transparent rounded-md hover:bg-gray-800"
            >
              <FaCalculator className="w-5 h-5 mr-2" />
              {!billsTable ? "View Bills" : "Add Bill"}
            </button>
            <button
              onClick={handleCalculate}
              type="button"
              className="inline-flex items-center px-4 py-2 font-semibold text-white bg-blue-700 border border-transparent rounded-md hover:bg-blue-800"
            >
              <FaCalculator className="w-5 h-5 mr-2" />
              Calculate
            </button>
            <button
              onClick={handleSave}
              type="button"
              className="inline-flex items-center px-4 py-2 font-semibold text-white bg-green-700 border border-transparent rounded-md hover:bg-green-800"
            >
              <FaSave className="w-5 h-5 mr-2" />
              Save
            </button>
            <button
              onClick={handlePrint}
              type="button"
              className="inline-flex items-center px-4 py-2 font-semibold text-white bg-blue-900 border border-transparent rounded-md hover:bg-blue-800"
            >
              <FaPrint className="w-5 h-5 mr-2" />
              Print
            </button>
          </div>

          {billsTable ? (
            <BillsTable />
          ) : (
            <>
              {/* Bill container */}
              <div
                id="bill"
                ref={componentRef}
                className="mx-4 border-2 border-gray-800 bill-container"
              >
                {/* GSTIN and Bill Type Header */}
                <div className="flex border-b-2 border-gray-800">
                  <div className="w-1/3 px-4 py-1 border-r-2 border-gray-800">
                    <div className="text-sm font-bold">GSTIN: {gstin}</div>
                  </div>
                  <div className="flex flex-col items-center justify-center w-1/3 border-r-2 border-gray-800">
                    <select
                      id="bill-type"
                      required
                      value={billType}
                      onChange={(e) => setBillType(e.target.value)}
                      className="px-2 py-1 text-base font-bold text-center uppercase bg-transparent border-0 appearance-none focus:outline-none"
                    >
                      <option value="bill_performa">BILL PROFORMA</option>
                      <option value="tax_invoice">TAX INVOICE</option>
                      <option value="estimate">ESTIMATE</option>
                    </select>
                  </div>
                  <div className="w-1/3 px-2 py-1 text-right">
                    <div className="text-xs">Original for Buyer</div>
                  </div>
                </div>

                {/* Company Header */}
                <div className="flex items-center border-b-2 border-gray-800 company-header">
                  <div className="flex items-center justify-center w-1/5">
                    <img
                      alt="logo"
                      src={logo}
                      className="w-16 h-16 company-logo"
                    />
                  </div>
                  <div className="flex flex-col items-center w-3/5">
                    <select
                      id="firm-name"
                      required
                      value={firmName}
                      onChange={(e) => setFirmName(e.target.value)}
                      className="w-full px-3 py-0 text-xl font-bold text-center uppercase bg-transparent border-0 appearance-none focus:outline-none company-title"
                    >
                      <option value="jk_solar_energy">JK SOLAR ENERGY</option>
                      <option value="jk_sale_and_service">
                        JK SALE & SERVICE AGENCY
                      </option>
                      <option value="saaslynx">SAASLYNX</option>
                    </select>
                    <div className="text-xs font-bold text-red-700 company-subtitle">
                      Government Approved Vendor For PMSGY (MNRE Govt. of India)
                    </div>
                    <div className="text-xs company-subtitle">
                      NAIBASTI ANANTNAG, JAMMU AND KASHMIR, 192101
                    </div>
                    <div className="text-xs company-subtitle">
                      Phone : +91-6005016509 | E-Mail : care@jksolarenergy.in
                    </div>
                    <div className="flex justify-between w-full text-xs company-subtitle">
                      <span>GEM SELLER ID: {gemSellerId}</span>
                      <span>TIN NO: {tinNo}</span>
                    </div>
                  </div>
                  <div className="w-1/5"></div>
                </div>

                {/* Invoice Details Table */}
                <div className="flex border-b-2 border-gray-800">
                  <div className="w-1/2 border-r-2 border-gray-800">
                    <table className="w-full text-xs invoice-details">
                      <tbody>
                        <tr className="border-b border-gray-800">
                          <td className="w-32 px-2 py-0 font-bold">
                            Invoice No.
                          </td>
                          <td className="px-2 py-0">
                            :{" "}
                            <input
                              type="text"
                              value={
                                firmName === "jk_solar_energy"
                                  ? "JKSE" + prevBill.toString()
                                  : firmName === "saaslynx"
                                  ? "SSLX" + prevBill.toString()
                                  : "JKSS" + prevBill.toString()
                              }
                              readOnly
                              className="font-bold uppercase bg-transparent focus:outline-none"
                            />
                          </td>
                        </tr>
                        <tr className="border-b border-gray-800">
                          <td className="px-2 py-0 font-bold">Invoice Date</td>
                          <td className="px-2 py-0">
                            :{" "}
                            <input
                              type="text"
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                              className="font-bold bg-transparent focus:outline-none w-28"
                            />
                          </td>
                        </tr>
                        <tr className="border-b border-gray-800">
                          <td className="px-2 py-0 font-bold">
                            Time of supply
                          </td>
                          <td className="px-2 py-0">
                            :{" "}
                            <input
                              type="text"
                              placeholder="HH:MM"
                              className="w-20 font-bold bg-transparent focus:outline-none"
                            />
                          </td>
                        </tr>
                        <tr className="border-b border-gray-800">
                          <td className="px-2 py-0 font-bold">
                            Place of Supply
                          </td>
                          <td className="px-2 py-0">
                            :{" "}
                            <input
                              type="text"
                              value="Jammu & Kashmir (01)"
                              className="w-40 font-bold bg-transparent focus:outline-none"
                            />
                          </td>
                        </tr>
                        <tr className="border-b border-gray-800">
                          <td className="px-2 py-0 font-bold">
                            Reverse Charges
                          </td>
                          <td className="px-2 py-0">
                            :{" "}
                            <input
                              type="text"
                              value="N"
                              className="w-8 font-bold bg-transparent focus:outline-none"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="px-2 py-0 font-bold">Station</td>
                          <td className="px-2 py-0">
                            :{" "}
                            <input
                              type="text"
                              className="w-32 font-bold bg-transparent focus:outline-none"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="w-1/2">
                    <table className="w-full text-xs invoice-details">
                      <tbody>
                        <tr className="border-b border-gray-800">
                          <td className="w-32 px-2 py-0 font-bold">
                            Transport
                          </td>
                          <td className="px-2 py-0">
                            :{" "}
                            <input
                              type="text"
                              value={transportDetails.transport}
                              onChange={(e) =>
                                handleTransportChange(
                                  "transport",
                                  e.target.value
                                )
                              }
                              className="font-bold bg-transparent focus:outline-none"
                            />
                          </td>
                        </tr>
                        <tr className="border-b border-gray-800">
                          <td className="px-2 py-0 font-bold">Vehicle No.</td>
                          <td className="px-2 py-0">
                            :{" "}
                            <input
                              type="text"
                              value={transportDetails.vehicleNo}
                              onChange={(e) =>
                                handleTransportChange(
                                  "vehicleNo",
                                  e.target.value
                                )
                              }
                              className="font-bold bg-transparent focus:outline-none"
                            />
                          </td>
                        </tr>
                        <tr className="border-b border-gray-800">
                          <td className="px-2 py-0 font-bold">GR.NO.</td>
                          <td className="px-2 py-0">
                            :{" "}
                            <input
                              type="text"
                              value={transportDetails.grNo}
                              onChange={(e) =>
                                handleTransportChange("grNo", e.target.value)
                              }
                              className="font-bold bg-transparent focus:outline-none"
                            />
                          </td>
                        </tr>
                        <tr className="border-b border-gray-800">
                          <td className="px-2 py-0 font-bold">GR.Date.</td>
                          <td className="px-2 py-0">
                            :{" "}
                            <input
                              type="text"
                              value={transportDetails.grDate}
                              onChange={(e) =>
                                handleTransportChange("grDate", e.target.value)
                              }
                              className="font-bold bg-transparent focus:outline-none"
                            />
                          </td>
                        </tr>
                        <tr className="border-b border-gray-800">
                          <td className="px-2 py-0 font-bold">E Way Bill NO</td>
                          <td className="px-2 py-0">
                            :{" "}
                            <input
                              type="text"
                              value={transportDetails.eWayBillNo}
                              onChange={(e) =>
                                handleTransportChange(
                                  "eWayBillNo",
                                  e.target.value
                                )
                              }
                              className="font-bold bg-transparent focus:outline-none"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="px-2 py-0 font-bold">IRN NO</td>
                          <td className="px-2 py-0">
                            :{" "}
                            <input
                              type="text"
                              value={transportDetails.irnNo}
                              onChange={(e) =>
                                handleTransportChange("irnNo", e.target.value)
                              }
                              className="font-bold bg-transparent focus:outline-none"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Billing and Shipping Info */}
                <div className="flex border-b-2 border-gray-800">
                  {["Billed to:", "Shipped to:"].map((title, idx) => (
                    <div
                      key={idx}
                      className={`w-1/2 p-1 customer-details ${
                        idx === 0 ? "border-r-2 border-gray-800" : ""
                      }`}
                    >
                      <div className="font-bold underline">{title}</div>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Customer Name"
                        className="w-full font-bold uppercase focus:outline-none customer-name"
                      />
                      <textarea
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Address"
                        className="w-full uppercase resize-none focus:outline-none customer-address"
                        rows={2}
                      />
                      <div className="flex mt-1">
                        <div className="w-1/3 text-xs">EMAIL-ID</div>
                        <div className="w-2/3 text-xs">
                          :{" "}
                          <input
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="focus:outline-none"
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="w-1/3 text-xs">Mobile No.</div>
                        <div className="w-2/3 text-xs">
                          :{" "}
                          <input
                            type="text"
                            value={phoneNo}
                            onChange={(e) => setPhoneNo(e.target.value)}
                            className="focus:outline-none"
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="w-1/3 text-xs">GSTIN/UIN</div>
                        <div className="w-2/3 text-xs">
                          :{" "}
                          <input
                            type="text"
                            value={cusGstin}
                            onChange={(e) => setCusGstin(e.target.value)}
                            className="focus:outline-none"
                          />
                        </div>
                      </div>
                      {idx === 1 && (
                        <div className="flex">
                          <div className="w-1/3 text-sm">Party PAN</div>
                          <div className="w-2/3 text-sm">
                            :{" "}
                            <input type="text" className="focus:outline-none" />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                {/* Items Table */}
                <div className="flex-grow border-b-2 border-gray-800 item-table-container">
                  <table className="w-full text-sm border-collapse">
                    <thead>
                      <tr className="border-b border-gray-800">
                        <th className="w-10 p-2 text-left border-r border-gray-800">
                          S.n.
                        </th>
                        <th className="p-2 text-left border-r border-gray-800">
                          Description of Items
                        </th>
                        <th className="w-24 p-2 text-center border-r border-gray-800">
                          HSN/SAC
                        </th>
                        <th className="w-16 p-2 text-center border-r border-gray-800">
                          Tax
                        </th>
                        <th className="w-16 p-2 text-center border-r border-gray-800">
                          Qty
                        </th>
                        <th className="p-2 text-center border-r border-gray-800 w-14">
                          Unit
                        </th>
                        <th className="w-20 p-2 text-center border-r border-gray-800">
                          Rate
                        </th>
                        <th className="w-24 p-2 text-center">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, rowIndex) => (
                        <tr
                          key={rowIndex}
                          className="border-b border-gray-800 compact-row"
                        >
                          <td className="p-1 text-center border-r border-gray-800">
                            {row.sno}.
                          </td>
                          <td className="p-1 border-r border-gray-800">
                            <ItemDescriptionCell
                              row={row}
                              rowIndex={rowIndex}
                              handleCellChange={handleCellChange}
                            />
                          </td>
                          <td className="p-1 text-center border-r border-gray-800">
                            <input
                              type="text"
                              value={row.hsnCode}
                              onChange={(e) =>
                                handleCellChange(
                                  rowIndex,
                                  "hsnCode",
                                  e.target.value
                                )
                              }
                              className="w-full text-center focus:outline-none"
                            />
                          </td>
                          <td className="p-1 text-center border-r border-gray-800">
                            <select
                              value={row.taxRate}
                              onChange={(e) =>
                                handleCellChange(
                                  rowIndex,
                                  "taxRate",
                                  parseInt(e.target.value)
                                )
                              }
                              className="w-full text-center bg-transparent appearance-none focus:outline-none"
                              style={{
                                WebkitAppearance: "none",
                                MozAppearance: "none",
                              }}
                            >
                              <option value="0">0%</option>
                              <option value="5">5%</option>
                              <option value="12">12%</option>
                              <option value="18">18%</option>
                            </select>
                          </td>
                          <td className="p-1 text-center border-r border-gray-800">
                            <input
                              type="number"
                              min="1"
                              value={row.qty}
                              onChange={(e) =>
                                handleCellChange(
                                  rowIndex,
                                  "qty",
                                  parseFloat(e.target.value)
                                )
                              }
                              className="w-full text-center focus:outline-none"
                            />
                          </td>
                          <td className="p-1 text-center border-r border-gray-800">
                            <select
                              value={row.unit}
                              onChange={(e) =>
                                handleCellChange(
                                  rowIndex,
                                  "unit",
                                  e.target.value
                                )
                              }
                              className="w-full text-center bg-transparent appearance-none focus:outline-none"
                              style={{
                                WebkitAppearance: "none",
                                MozAppearance: "none",
                              }}
                            >
                              <option value="PCS">PCS</option>
                              <option value="SET">SET</option>
                              <option value="KG">KG</option>
                              <option value="MTR">MTR</option>
                            </select>
                          </td>
                          <td className="p-1 text-right border-r border-gray-800">
                            <input
                              type="number"
                              step="0.01"
                              value={row.rate}
                              onChange={(e) =>
                                handleCellChange(
                                  rowIndex,
                                  "rate",
                                  parseFloat(e.target.value)
                                )
                              }
                              className="w-full text-right focus:outline-none"
                            />
                          </td>
                          <td className="p-1 text-right">
                            {row.amount.toFixed(2)}
                          </td>
                        </tr>
                      ))}

                      {/* Add Item buttons - only visible when not printing */}
                      <tr className="no-print">
                        <td colSpan="8" className="p-2">
                          <div className="flex items-center space-x-4">
                            <button
                              onClick={handleAddRow}
                              className="flex items-center text-blue-600 hover:text-blue-800"
                            >
                              <FaPlus className="w-3 h-3 mr-1" /> Add Item
                            </button>
                            <button
                              onClick={handleRemoveRow}
                              disabled={tableData.length === 1}
                              className={`flex items-center ${
                                tableData.length === 1
                                  ? "text-gray-400"
                                  : "text-red-700 hover:text-red-800"
                              }`}
                            >
                              <FaMinus className="w-3 h-3 mr-1" /> Remove Item
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Tax Summary Table */}
                <div className="flex text-xs border-b border-gray-800">
                  {/* Tax Breakdown Table */}
                  <div className="w-7/12 border-r border-gray-800">
                    <table className="w-full border-collapse">
                      <thead>
                        <tr className="border-b border-gray-800">
                          <th className="p-1 text-center border-r border-gray-800">
                            TAX
                          </th>
                          <th className="p-1 text-center border-r border-gray-800">
                            TAXABLE
                          </th>
                          <th className="p-1 text-center border-r border-gray-800">
                            SGST
                          </th>
                          <th className="p-1 text-center border-r border-gray-800">
                            CGST
                          </th>
                          <th className="p-1 text-center">TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[0, 5, 12, 18].map((rate) => (
                          <tr key={rate} className="border-b border-gray-800">
                            <td className="p-1 text-center border-r border-gray-800">
                              {rate}%
                            </td>
                            <td className="p-1 text-right border-r border-gray-800">
                              {taxSummary[`taxable${rate}`].toFixed(2)}
                            </td>
                            <td className="p-1 text-right border-r border-gray-800">
                              {taxSummary[`sgst${rate}`].toFixed(2)}
                            </td>
                            <td className="p-1 text-right border-r border-gray-800">
                              {taxSummary[`cgst${rate}`].toFixed(2)}
                            </td>
                            <td className="p-1 text-right">
                              {(
                                taxSummary[`sgst${rate}`] +
                                taxSummary[`cgst${rate}`]
                              ).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td
                            className="p-1 font-bold text-right border-r border-gray-800"
                            colSpan="4"
                          >
                            TOTAL
                          </td>
                          <td className="p-1 font-bold text-right">
                            {taxSummary.totalTax.toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {/* Invoice Total */}
                  <div className="w-5/12 p-1">
                    <div className="space-y-0.5">
                      <div className="flex justify-between font-semibold">
                        <span>TAXABLE AMOUNT</span>
                        <span>{total.toFixed(2)}</span>
                      </div>
                      <div className="flex justify-between">
                        <span>DISCOUNT</span>
                        <span>0.00</span>
                      </div>
                      <div className="flex justify-between">
                        <span>SGST</span>
                        <span>
                          {(
                            taxSummary.sgst5 +
                            taxSummary.sgst12 +
                            taxSummary.sgst18
                          ).toFixed(2)}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span>CGST</span>
                        <span>
                          {(
                            taxSummary.cgst5 +
                            taxSummary.cgst12 +
                            taxSummary.cgst18
                          ).toFixed(2)}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span>IGST</span>
                        <span>0.00</span>
                      </div>
                      <div className="pt-0.5 mt-1 border-t border-gray-400">
                        <div className="flex justify-between">
                          <span>ROUND OFF</span>
                          <span>{roundOff.toFixed(2)}</span>
                        </div>
                        <div className="flex justify-between font-bold">
                          <span>INVOICE TOTAL</span>
                          <span>{grandTotal.toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Amount in Words */}
                <div className="p-2 border-b-2 border-gray-800">
                  <div className="text-sm font-bold">
                    TOTAL INVOICE AMOUNT (IN WORDS)
                  </div>
                  <div className="text-sm font-semibold text-red-700">
                    Rs. {toWords.convert(grandTotal)}
                  </div>
                </div>

                {/* Bank Details */}
                <div className="text-xs">
                  <div className="p-1 border-b border-gray-800">
                    <div className="mb-1 font-bold text-center">
                      BANK DETAILS
                    </div>
                    <div className="flex justify-between">
                      {firmName === "jk_solar_energy" ? (
                        <>
                          {JKSE_ACCOUNTS.map((account, index) => (
                            <div
                              key={index}
                              className={`w-1/3 ${index !== 0 && "border-l"} ${
                                index !== JKSE_ACCOUNTS.length - 1 && "border-r"
                              } border-gray-400 px-1 uppercase`}
                            >
                              <div className="font-semibold">
                                {account.bankName}
                              </div>
                              <div>A/C: {account.accountNumber}</div>
                              <div>IFSC: {account.ifscCode}</div>
                              <div>BRANCH: {account.branch}</div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          {JKSS_ACCOUNTS.map((account, index) => (
                            <div
                              key={index}
                              className={`w-1/3 ${index !== 0 && "border-l"} ${
                                index !== JKSS_ACCOUNTS.length - 1 && "border-r"
                              } border-gray-400 px-1 uppercase`}
                            >
                              <div className="font-semibold">
                                {account.bankName}
                              </div>
                              <div>A/C: {account.accountNumber}</div>
                              <div>IFSC: {account.ifscCode}</div>
                              <div>BRANCH: {account.branch}</div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>

                  {/* Terms and Signature - Compacted */}
                  <div className="flex footer-section">
                    <div className="w-2/3 p-2 border-r border-gray-800 terms-section">
                      <div className="mb-1 font-semibold">
                        Terms & Conditions
                      </div>
                      <ol className="pl-4 list-decimal">
                        <li>
                          GOODS ONCE SOLD WILL NOT BE TAKEN BACK OR EXCHANGED
                        </li>
                        <li>
                          WARRANTY/GUARANTEE IS ONLY APPLICABLE AS PER COMPANY
                          NORMS
                        </li>
                        <li>
                          ALL DISPUTES ARE SUBJECT TO ANANTNAG JURISDICTION ONLY
                        </li>
                        <li>
                          E.& O.E. (Errors and Omissions are Excepted) Subject
                          to Anantnag Jurisdiction
                        </li>
                      </ol>
                      <div className="p-1 mt-1 border border-gray-300 border-dashed declaration">
                        <div className="text-center">
                          <div className="font-semibold">DECLARATION</div>
                          <div>
                            THIS INVOICE SHOWS ACTUAL PRICE OF THE GOODS & ALL
                            PARTICULARS ARE TRUE & CORRECT
                          </div>
                          <div>
                            For enquiries: care@jksolarenergy.in | +91
                            6005016509
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Signature Section */}
                    <div className="flex flex-col items-end justify-between w-1/3 p-2">
                      <div className="w-full text-center">
                        <p className="font-semibold">
                          For{" "}
                          {firmName === "jk_solar_energy"
                            ? "JK SOLAR ENERGY"
                            : "JK SALE & SERVICE AGENCY"}
                        </p>
                        <div className="h-10 mt-3"></div>
                        <div className="w-32 pt-1 mx-auto border-t border-gray-400">
                          <p className="font-semibold">Authorised Signatory</p>
                        </div>
                      </div>

                      <div className="w-full mt-2 text-center">
                        <p className="text-gray-500">
                          This is a Computer Generated Invoice
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
};
