import React, { useContext, useMemo, useState, useEffect } from "react";
import UserProtectedRoute from "../../components/UserProtectedRoute";
import UserLayout from "../../components/UserLayout";
import { AuthContext } from "../../utils/authContext";
import LayoutHeader from "../../components/LayoutHeader";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/helpers";
import { updateUserProfileInFirestore } from "../../utils/database";

// Icons
import { FaRegCalendarAlt } from "react-icons/fa";
import {
  MdOutlineEmail,
  MdVerified,
  MdOutlineManageAccounts,
} from "react-icons/md";

const UserDashboard = () => {
  const {
    currentUser,
    updateUserProfile,
    sendVerificationEmail,
    changePassword,
    reloadUser,
  } = useContext(AuthContext);

  const [firestoreUser, setFirestoreUser] = useState(null);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [newDisplayName, setNewDisplayName] = useState("");
  const [newPhotoURL, setNewPhotoURL] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Fetch Firestore user data
  useEffect(() => {
    const fetchUserData = async () => {
      if (!currentUser?.uid) return;

      try {
        const docRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setFirestoreUser(docSnap.data());
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Failed to load user profile data", toastOptions);
      }
    };

    fetchUserData();
  }, [currentUser?.uid]);

  // Initialize form values when user data is loaded
  useEffect(() => {
    if (currentUser) {
      setNewDisplayName(currentUser.displayName || "");
      setNewPhotoURL(currentUser.photoURL || "");
    }
  }, [currentUser]);

  // Format the last login date nicely
  const formattedLastLogin = useMemo(() => {
    if (firestoreUser?.lastLogin) {
      try {
        // Firestore timestamp has toDate() method
        const date = firestoreUser.lastLogin.toDate();
        return new Intl.DateTimeFormat("en-US", {
          weekday: "short",
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(date);
      } catch (e) {
        // Fallback to string representation if toDate fails
        return firestoreUser.lastLogin.toString();
      }
    }

    return "Unknown";
  }, [firestoreUser?.lastLogin]);

  // Format account creation date
  const formattedCreationDate = useMemo(() => {
    if (firestoreUser?.createdAt) {
      try {
        const date = firestoreUser.createdAt.toDate();
        return new Intl.DateTimeFormat("en-US", {
          weekday: "short",
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(date);
      } catch (e) {
        return firestoreUser.createdAt.toString();
      }
    }

    return "Unknown";
  }, [firestoreUser?.createdAt]);

  // Calculate account age
  const accountAge = useMemo(() => {
    if (firestoreUser?.createdAt) {
      try {
        const creationDate = firestoreUser.createdAt.toDate();
        const now = new Date();

        const diffTime = Math.abs(now - creationDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays < 30) return `${diffDays} days`;
        if (diffDays < 365) return `${Math.floor(diffDays / 30)} months`;
        return `${Math.floor(diffDays / 365)} years`;
      } catch (e) {
        return "Unknown";
      }
    }

    return "Unknown";
  }, [firestoreUser?.createdAt]);

  // Handle profile update
  const handleProfileUpdate = async (e) => {
    e.preventDefault();

    try {
      // Update in Firebase Auth
      await updateUserProfile(newDisplayName, newPhotoURL);

      // Also update in Firestore
      if (currentUser?.uid) {
        await updateUserProfileInFirestore(currentUser.uid, {
          displayName: newDisplayName,
          photoURL: newPhotoURL,
        });
      }

      await reloadUser(); // Reload user to get updated profile
      toast.success("Profile updated successfully", toastOptions);
      setIsEditingProfile(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error(error.message || "Failed to update profile", toastOptions);
    }
  };

  // Handle email verification
  const handleVerifyEmail = async () => {
    try {
      await sendVerificationEmail();
      toast.success(
        "Verification email sent. Please check your inbox.",
        toastOptions
      );
    } catch (error) {
      console.error("Error sending verification email:", error);
      toast.error(
        error.message || "Failed to send verification email",
        toastOptions
      );
    }
  };

  // Handle password change
  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match", toastOptions);
      return;
    }

    if (newPassword.length < 8) {
      toast.error("Password must be at least 8 characters", toastOptions);
      return;
    }

    try {
      // This function needs to be implemented in AuthContext
      // Example implementation provided in the Auth Provider artifact
      await changePassword(currentPassword, newPassword);
      toast.success("Password updated successfully", toastOptions);
      setIsChangingPassword(false);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      console.error("Error changing password:", error);
      toast.error(error.message || "Failed to change password", toastOptions);
    }
  };

  return (
    <UserProtectedRoute>
      <UserLayout>
        <div className="h-full mx-auto max-w-7xl">
          <LayoutHeader title="User Dashboard" />

          {/* Stats Overview */}
          <div className="grid grid-cols-1 gap-4 px-4 mt-4 md:grid-cols-3 sm:px-0">
            <div className="flex items-center p-4 bg-white border border-gray-100 rounded-lg shadow-sm">
              <div className="p-3 mr-4 bg-blue-100 rounded-full">
                <MdOutlineEmail className="text-xl text-blue-600" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">Email</h3>
                <p className="text-gray-900 font-medium truncate max-w-[220px]">
                  {currentUser?.email || "Not available"}
                </p>
              </div>
            </div>

            <div className="flex items-center p-4 bg-white border border-gray-100 rounded-lg shadow-sm">
              <div className="p-3 mr-4 bg-blue-100 rounded-full">
                <MdVerified
                  className={`text-xl ${
                    currentUser?.emailVerified
                      ? "text-green-600"
                      : "text-orange-500"
                  }`}
                />
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">
                  Verification Status
                </h3>
                <p
                  className={`font-medium ${
                    currentUser?.emailVerified
                      ? "text-green-600"
                      : "text-orange-500"
                  }`}
                >
                  {currentUser?.emailVerified ? "Verified" : "Not Verified"}
                </p>
              </div>
            </div>

            <div className="flex items-center p-4 bg-white border border-gray-100 rounded-lg shadow-sm">
              <div className="p-3 mr-4 bg-blue-100 rounded-full">
                <FaRegCalendarAlt className="text-xl text-blue-600" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">
                  Last Login
                </h3>
                <p className="font-medium text-gray-900">
                  {formattedLastLogin}
                </p>
              </div>
            </div>
          </div>

          {/* Main Profile Card with Extended Details */}
          <div className="px-4 my-8 sm:px-0">
            <div className="overflow-hidden bg-white border border-gray-200 rounded-lg shadow-sm">
              <div className="md:flex">
                {/* Profile Image Section */}
                <div className="flex flex-col items-center justify-center p-6 md:w-1/3 bg-gradient-to-br from-blue-800 to-blue-900">
                  <div className="w-32 h-32 p-1 mb-4 bg-white rounded-full">
                    {currentUser?.photoURL ? (
                      <img
                        src={currentUser.photoURL}
                        alt="Profile"
                        className="object-cover w-full h-full rounded-full"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(
                            currentUser?.displayName || "User"
                          )}&background=0D8ABC&color=fff`;
                        }}
                      />
                    ) : (
                      <div className="flex items-center justify-center w-full h-full bg-blue-100 rounded-full">
                        <MdOutlineManageAccounts className="text-5xl text-blue-600" />
                      </div>
                    )}
                  </div>
                  <h2 className="mb-1 text-xl font-semibold text-center text-white">
                    {currentUser?.displayName || "User Account"}
                  </h2>
                  <p className="text-sm text-center text-blue-200">
                    Account active for {accountAge}
                  </p>
                </div>

                {/* User Details Section */}
                <div className="p-6 md:w-2/3">
                  <h3 className="flex items-center mb-4 text-lg font-semibold text-gray-800">
                    <MdOutlineManageAccounts className="mr-2" />
                    Account Details
                  </h3>

                  {!isEditingProfile && !isChangingPassword ? (
                    <>
                      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                        <div className="pb-3 border-b border-gray-100">
                          <p className="text-sm text-gray-500">Display Name</p>
                          <p className="font-medium">
                            {currentUser?.displayName || "Not set"}
                          </p>
                        </div>

                        <div className="pb-3 border-b border-gray-100">
                          <p className="text-sm text-gray-500">Email</p>
                          <p className="font-medium">{currentUser?.email}</p>
                        </div>

                        <div className="pb-3 border-b border-gray-100">
                          <p className="text-sm text-gray-500">User ID</p>
                          <p className="text-sm font-medium truncate">
                            {currentUser?.uid || "Not available"}
                          </p>
                        </div>

                        <div className="pb-3 border-b border-gray-100">
                          <p className="text-sm text-gray-500">
                            Email Verified
                          </p>
                          <p
                            className={`font-medium ${
                              currentUser?.emailVerified
                                ? "text-green-600"
                                : "text-orange-500"
                            }`}
                          >
                            {currentUser?.emailVerified
                              ? "Yes"
                              : "No - Please verify your email"}
                          </p>
                        </div>

                        <div className="pb-3 border-b border-gray-100">
                          <p className="text-sm text-gray-500">
                            Account Created
                          </p>
                          <p className="font-medium">{formattedCreationDate}</p>
                        </div>

                        <div className="pb-3 border-b border-gray-100">
                          <p className="text-sm text-gray-500">Last Sign In</p>
                          <p className="font-medium">{formattedLastLogin}</p>
                        </div>
                      </div>

                      <div className="flex flex-wrap gap-2 mt-6">
                        <button
                          className="px-4 py-2 text-sm text-white transition-colors bg-blue-600 rounded-md hover:bg-blue-700"
                          onClick={() => setIsEditingProfile(true)}
                        >
                          Edit Profile
                        </button>
                        {!currentUser?.emailVerified && (
                          <button
                            className="px-4 py-2 text-sm text-white transition-colors bg-orange-500 rounded-md hover:bg-orange-600"
                            onClick={handleVerifyEmail}
                          >
                            Verify Email
                          </button>
                        )}
                        <button
                          className="px-4 py-2 text-sm text-gray-700 transition-colors border border-gray-300 rounded-md hover:bg-gray-50"
                          onClick={() => setIsChangingPassword(true)}
                        >
                          Change Password
                        </button>
                      </div>
                    </>
                  ) : isEditingProfile ? (
                    <form onSubmit={handleProfileUpdate}>
                      <div className="space-y-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Display Name
                          </label>
                          <input
                            type="text"
                            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            value={newDisplayName}
                            onChange={(e) => setNewDisplayName(e.target.value)}
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Photo URL
                          </label>
                          <input
                            type="text"
                            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            value={newPhotoURL}
                            onChange={(e) => setNewPhotoURL(e.target.value)}
                          />
                        </div>
                        <div className="flex gap-2">
                          <button
                            type="submit"
                            className="px-4 py-2 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700"
                          >
                            Save Changes
                          </button>
                          <button
                            type="button"
                            className="px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
                            onClick={() => {
                              setIsEditingProfile(false);
                              setNewDisplayName(currentUser?.displayName || "");
                              setNewPhotoURL(currentUser?.photoURL || "");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <form onSubmit={handlePasswordChange}>
                      <div className="space-y-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Current Password
                          </label>
                          <input
                            type="password"
                            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            New Password
                          </label>
                          <input
                            type="password"
                            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Confirm New Password
                          </label>
                          <input
                            type="password"
                            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                        </div>
                        <div className="flex gap-2">
                          <button
                            type="submit"
                            className="px-4 py-2 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700"
                          >
                            Change Password
                          </button>
                          <button
                            type="button"
                            className="px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
                            onClick={() => {
                              setIsChangingPassword(false);
                              setCurrentPassword("");
                              setNewPassword("");
                              setConfirmPassword("");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Recent Activity Section - You could expand this in the future */}
          <div className="px-4 mb-8 sm:px-0">
            <h3 className="mb-4 text-lg font-semibold text-gray-800">
              Recent Activity
            </h3>
            <div className="flex flex-col items-center justify-center p-8 text-center bg-white border border-gray-200 rounded-lg shadow-sm">
              <p className="mb-2 text-gray-500">
                Your recent activity will appear here
              </p>
              <p className="text-sm text-gray-400">
                Check back later for updates on your account activity
              </p>
            </div>
          </div>
        </div>
      </UserLayout>
    </UserProtectedRoute>
  );
};

export default UserDashboard;
