import React, { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../utils/authContext";
import {
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { toast } from "react-toastify";
import { toastOptions } from "../utils/helpers";
import { ClipLoader } from "react-spinners";

export default function UserProtectedRoute({ children }) {
  const navigate = useNavigate();
  const { currentUser, signOut } = useContext(AuthContext);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;

  // Memoized function to add user details to Firestore
  const addUserDetailsIfNotAdded = useCallback(async () => {
    if (!currentUser?.uid) return false;

    try {
      const docRef = doc(db, "users", currentUser.uid);
      const docSnap = await getDoc(docRef);

      // Only proceed if document doesn't exist
      if (!docSnap.exists()) {
        const userData = {
          active: true,
          admin: currentUser?.email === ADMIN_EMAIL,
          email: currentUser?.email || "",
          emailVerified: !!currentUser?.emailVerified,
          displayName: currentUser?.displayName || "",
          photoURL: currentUser?.photoURL || "",
          createdAt: serverTimestamp(),
          lastLogin: serverTimestamp(),
          uid: currentUser?.uid,
        };

        await setDoc(docRef, userData);
        return true;
      } else {
        // Update last login time
        await setDoc(docRef, { lastLogin: serverTimestamp() }, { merge: true });
        return true;
      }
    } catch (error) {
      console.error("Error adding user details:", error);
      return false;
    }
  }, [currentUser, ADMIN_EMAIL]);

  // Handle user account status
  useEffect(() => {
    let unsubscribe = () => {};

    const setupUserListener = async () => {
      if (!currentUser?.uid) {
        setIsLoading(false);
        return;
      }

      try {
        // First ensure the user document exists
        await addUserDetailsIfNotAdded();

        // Then set up the listener for active status
        unsubscribe = onSnapshot(
          doc(db, "users", currentUser.uid),
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              const userData = docSnapshot.data();

              if (!userData.active) {
                toast.error(
                  "Your account has been deactivated. Please contact the admin.",
                  toastOptions
                );
                signOut();
                navigate("/user");
              } else {
                setIsAuthorized(true);
              }
            } else {
              // Document doesn't exist, which shouldn't happen at this point
              console.error("User document unexpectedly missing");
              setIsAuthorized(false);
            }
            setIsLoading(false);
          },
          (error) => {
            console.error("Error listening to user document:", error);
            toast.error("Error checking your account status", toastOptions);
            setIsLoading(false);
          }
        );
      } catch (error) {
        console.error("Error in setupUserListener:", error);
        setIsLoading(false);
      }
    };

    setupUserListener();

    return () => unsubscribe();
  }, [currentUser?.uid, addUserDetailsIfNotAdded, navigate, signOut]);

  // Handle routing logic
  useEffect(() => {
    const handleRouting = async () => {
      if (!currentUser) {
        navigate("/user");
        return;
      }

      if (currentUser.email === ADMIN_EMAIL) {
        navigate("/admin");
        return;
      }

      // Regular user path continues normally
    };

    handleRouting();
  }, [currentUser, ADMIN_EMAIL, navigate]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <ClipLoader size={40} />
      </div>
    );
  }

  // If user is admin or not authorized, don't render children
  if (currentUser?.email === ADMIN_EMAIL || !isAuthorized) {
    return null;
  }

  // Render children for authorized regular users
  return <>{children}</>;
}
