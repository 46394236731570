import React, { useCallback, useEffect, useMemo, useState } from "react";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import {
  collection,
  getDocs,
  query,
  orderBy as firestoreOrderBy,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import LayoutHeader from "../../components/LayoutHeader";
import { ClipLoader } from "react-spinners";
import SolarRoofTopLeadsTable from "../../components/SolarRoofTopLeadsTable";
import { TbRefresh } from "react-icons/tb";
import { FaSearch, FaFilter, FaDownload } from "react-icons/fa";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/helpers";

const SolarRoofTop = () => {
  const [leads, setLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isCached, setIsCached] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  // Handle search input change
  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  // Filter leads based on search query
  const filteredLeads = useMemo(
    () =>
      leads.filter(
        (lead) =>
          lead.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          lead.address?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (lead.phone && lead.phone.includes(searchQuery))
      ),
    [leads, searchQuery]
  );

  // Fetch leads from Firestore
  const fetchSolarRoofTopLeads = useCallback(async () => {
    setIsLoading(true);

    try {
      const solarRoofTopLeadsQuery = query(
        collection(db, "solar_roof_top_leads"),
        firestoreOrderBy("timestamp", "desc")
      );

      const querySnapshot = await getDocs(solarRoofTopLeadsQuery);
      const docs = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setLeads(docs);
      setTotalLeads(docs.length);
    } catch (error) {
      console.error("Error fetching solar roof top leads:", error);
      toast.error("Failed to load leads data", toastOptions);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Handle manual refresh
  const handleRefreshData = useCallback(async () => {
    setIsCached(true);
    await fetchSolarRoofTopLeads();
    setIsCached(false);
  }, [fetchSolarRoofTopLeads]);

  // Format date for export
  const formatDate = (timestamp) => {
    if (!timestamp) return "";
    try {
      const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    } catch (error) {
      return "";
    }
  };

  // Convert a value to CSV-safe format
  const toCsvValue = (value) => {
    if (value === null || value === undefined) return "";

    const stringValue = String(value);
    // If the value contains commas, quotes, or newlines, wrap it in quotes
    if (
      stringValue.includes(",") ||
      stringValue.includes('"') ||
      stringValue.includes("\n")
    ) {
      // Double up any quotes to escape them
      return `"${stringValue.replace(/"/g, '""')}"`;
    }
    return stringValue;
  };

  // Export leads to CSV
  const handleExportData = useCallback(() => {
    if (leads.length === 0) {
      toast.info("No leads to export", toastOptions);
      return;
    }

    setIsExporting(true);

    try {
      // Determine all unique keys across all lead objects to ensure all fields are included
      const allKeys = new Set();
      leads.forEach((lead) => {
        Object.keys(lead).forEach((key) => {
          // Exclude internal keys or keys that shouldn't be exported
          if (key !== "id" && key !== "timestamp") {
            allKeys.add(key);
          }
        });
      });

      // Sort keys to make CSV structure consistent
      const sortedKeys = Array.from(allKeys).sort();

      // Add additional fields for formatted data
      const exportKeys = ["id", "date", ...sortedKeys];

      // Create CSV header
      const headerRow = exportKeys
        .map((key) => {
          // Format header names to be more readable
          const formattedKey = key
            .replace(/([A-Z])/g, " $1") // Add space before capital letters
            .replace(/_/g, " ") // Replace underscores with spaces
            .replace(/^\w/, (c) => c.toUpperCase()); // Capitalize first letter

          return toCsvValue(formattedKey);
        })
        .join(",");

      // Create CSV rows
      const csvRows = leads.map((lead) => {
        return exportKeys
          .map((key) => {
            if (key === "date") {
              return toCsvValue(formatDate(lead.timestamp));
            }
            if (key === "id") {
              return toCsvValue(lead.id);
            }
            return toCsvValue(lead[key]);
          })
          .join(",");
      });

      // Combine header and rows
      const csvContent = [headerRow, ...csvRows].join("\n");

      // Create a Blob with the CSV content
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      // Create download link
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");

      // Set link attributes
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `solar-leads-${new Date().toISOString().split("T")[0]}.csv`
      );
      link.style.visibility = "hidden";

      // Append link to document, trigger download, and remove link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success("Export completed successfully", toastOptions);
    } catch (error) {
      console.error("Export error:", error);
      toast.error("Failed to export data", toastOptions);
    } finally {
      setIsExporting(false);
    }
  }, [leads]);

  // Fetch leads on component mount
  useEffect(() => {
    fetchSolarRoofTopLeads();
  }, [fetchSolarRoofTopLeads]);

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="container px-4 py-6 mx-auto max-w-7xl">
          <LayoutHeader title="Solar Roof Top Leads" />

          <div className="overflow-hidden bg-white border border-gray-100 rounded-lg shadow-lg">
            {/* Card Header with Stats */}
            <div className="p-4 border-b border-gray-100 sm:p-6 bg-gradient-to-r from-blue-50 to-white">
              <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center">
                <div>
                  <h2 className="text-xl font-bold text-blue-900">
                    Lead Management
                  </h2>
                  <p className="mt-1 text-sm text-gray-600">
                    Showing {filteredLeads.length} of {totalLeads} total leads
                  </p>
                </div>

                <div className="flex items-center space-x-3">
                  <button
                    onClick={handleRefreshData}
                    disabled={isCached || isLoading}
                    className="flex items-center p-2 text-blue-900 transition duration-200 rounded-md bg-blue-50 hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <TbRefresh
                      className={`w-5 h-5 ${isCached ? "animate-spin" : ""}`}
                    />
                    <span className="hidden ml-2 sm:inline">Refresh</span>
                  </button>

                  <button
                    onClick={handleExportData}
                    disabled={isExporting || isLoading || leads.length === 0}
                    className="flex items-center p-2 text-blue-900 transition duration-200 rounded-md bg-blue-50 hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <FaDownload
                      className={`w-4 h-4 ${
                        isExporting ? "animate-pulse" : ""
                      }`}
                    />
                    <span className="hidden ml-2 sm:inline">
                      {isExporting ? "Exporting..." : "Export"}
                    </span>
                  </button>
                </div>
              </div>
            </div>

            {/* Search and Filter Section */}
            <div className="p-4 bg-white border-b border-gray-100 sm:p-6">
              <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4 md:items-center">
                {/* Search Input */}
                <div className="relative flex-grow">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearch}
                    placeholder="Search by name, phone, or address..."
                    className="w-full py-3 pl-10 pr-4 transition duration-200 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-50"
                  />
                  <FaSearch className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2" />
                </div>

                {/* Filter Button - For future use */}
                <button className="flex items-center justify-center px-4 py-3 text-gray-700 transition duration-200 border border-gray-300 rounded-lg hover:bg-gray-50">
                  <FaFilter className="w-4 h-4 mr-2" />
                  <span>Filter</span>
                </button>
              </div>
            </div>

            {/* Table Content */}
            <div className="p-0 sm:p-0">
              {/* Loading State */}
              {isLoading ? (
                <div className="flex flex-col items-center justify-center w-full py-16">
                  <ClipLoader color="#1E3A8A" size={60} loading={isLoading} />
                  <p className="mt-4 text-gray-600">Loading leads data...</p>
                </div>
              ) : filteredLeads.length === 0 ? (
                <div className="flex flex-col items-center justify-center w-full px-4 py-16 text-center">
                  <div className="w-16 h-16 mb-4 text-gray-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <h3 className="text-lg font-semibold text-gray-700">
                    No leads found
                  </h3>
                  <p className="mt-1 text-gray-500">
                    {searchQuery
                      ? "Try adjusting your search criteria."
                      : "No solar roof top leads are available yet."}
                  </p>
                </div>
              ) : (
                <div className="overflow-x-auto">
                  <SolarRoofTopLeadsTable leads={filteredLeads} />
                </div>
              )}
            </div>
          </div>
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
};

export default SolarRoofTop;
