import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { FaSort, FaSearch, FaSortUp, FaSortDown } from "react-icons/fa";
import { ClipLoader } from "react-spinners";

const BillsTable = () => {
  const navigate = useNavigate();

  // State variables
  const [bills, setBills] = useState([]);
  const [filteredBills, setFilteredBills] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc", // Default to descending (newest first)
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  // Fetch bills from Firestore
  useEffect(() => {
    const fetchBills = async () => {
      try {
        setIsLoading(true);
        const q = query(collection(db, "billing"), orderBy("date", "desc"));
        const querySnapshot = await getDocs(q);

        const billsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setBills(billsList);
        setFilteredBills(billsList);
      } catch (error) {
        console.error("Error fetching bills:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBills();
  }, []);

  // Handle search
  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredBills(bills);
    } else {
      const term = searchTerm.toLowerCase();
      const filtered = bills.filter(
        (bill) =>
          (bill.name && bill.name.toLowerCase().includes(term)) ||
          (bill.phoneNo && bill.phoneNo.toString().includes(term)) ||
          (bill.address && bill.address.toLowerCase().includes(term)) ||
          (bill.billNo && bill.billNo.toLowerCase().includes(term))
      );
      setFilteredBills(filtered);
    }
    setPage(1); // Reset to first page when filtering
  }, [searchTerm, bills]);

  // Handle sorting
  const handleSort = (key) => {
    let direction = "asc";

    if (sortConfig.key === key) {
      direction = sortConfig.direction === "asc" ? "desc" : "asc";
    }

    setSortConfig({ key, direction });

    const sortedBills = [...filteredBills].sort((a, b) => {
      if (!a[key] && !b[key]) return 0;
      if (!a[key]) return 1;
      if (!b[key]) return -1;

      // Compare based on data type
      if (typeof a[key] === "string") {
        return direction === "asc"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      } else {
        return direction === "asc" ? a[key] - b[key] : b[key] - a[key];
      }
    });

    setFilteredBills(sortedBills);
  };

  // Pagination handlers
  const handleNext = () => {
    setPage(page + 1);
    setLimit(limit + 20);
  };

  const handlePrevious = () => {
    setPage(Math.max(1, page - 1));
    setLimit(Math.max(20, limit - 20));
  };

  // Navigate to bill detail
  const handleRowClick = (billId) => {
    navigate(`/admin/bill/${billId}`);
  };

  // Pagination logic
  const paginatedBills = filteredBills.slice(0, limit);

  // Get sort icon based on current sort configuration
  const getSortIcon = (key) => {
    if (sortConfig.key !== key) return <FaSort className="ml-1" />;
    if (sortConfig.direction === "asc") return <FaSortUp className="ml-1" />;
    return <FaSortDown className="ml-1" />;
  };

  // Format date string (DD/MM/YYYY) for display
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return dateString;
  };

  return (
    <div className="overflow-hidden bg-white rounded-lg shadow-md">
      {/* Search and filter bar */}
      <div className="flex flex-col items-start justify-between p-4 border-b border-gray-200 md:flex-row md:items-center">
        <div className="relative w-full mb-4 md:w-96 md:mb-0">
          <input
            type="text"
            className="w-full py-2 pl-10 pr-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Search by name, phone, address..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            <FaSearch className="text-gray-400" />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center py-12">
          <ClipLoader size={40} color="#1E3A8A" />
        </div>
      ) : (
        <>
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase cursor-pointer"
                    onClick={() => handleSort("billNo")}
                  >
                    <div className="flex items-center">
                      Bill No
                      {getSortIcon("billNo")}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase cursor-pointer"
                    onClick={() => handleSort("name")}
                  >
                    <div className="flex items-center">
                      Customer Name
                      {getSortIcon("name")}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase cursor-pointer"
                    onClick={() => handleSort("phoneNo")}
                  >
                    <div className="flex items-center">
                      Phone Number
                      {getSortIcon("phoneNo")}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
                  >
                    Address
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase cursor-pointer"
                    onClick={() => handleSort("date")}
                  >
                    <div className="flex items-center">
                      Date
                      {getSortIcon("date")}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase cursor-pointer"
                    onClick={() => handleSort("grandTotal")}
                  >
                    <div className="flex items-center">
                      Amount
                      {getSortIcon("grandTotal")}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
                  >
                    Bill Type
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {paginatedBills.length > 0 ? (
                  paginatedBills.map((bill) => (
                    <tr
                      key={bill.id}
                      onClick={() => handleRowClick(bill.id)}
                      className="cursor-pointer hover:bg-gray-50"
                    >
                      <td className="px-6 py-4 text-sm font-bold text-blue-600 whitespace-nowrap hover:underline">
                        {bill.billNo || "N/A"}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {bill.name || "N/A"}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {bill.phoneNo || "N/A"}
                      </td>
                      <td className="max-w-xs px-6 py-4 text-sm text-gray-500 truncate whitespace-nowrap">
                        {bill.address
                          ? bill.address.length > 33
                            ? bill.address.substring(0, 30) + "..."
                            : bill.address
                          : "N/A"}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {formatDate(bill.date)}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                        ₹{bill.grandTotal ? bill.grandTotal.toFixed(2) : "0.00"}
                      </td>
                      <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                        <span
                          className={`px-2 py-1 text-xs rounded-full ${
                            bill.billType === "tax_invoice"
                              ? "bg-green-100 text-green-800"
                              : bill.billType === "bill_performa"
                              ? "bg-blue-100 text-blue-800"
                              : "bg-yellow-100 text-yellow-800"
                          }`}
                        >
                          {bill.billType === "tax_invoice"
                            ? "Tax Invoice"
                            : bill.billType === "bill_performa"
                            ? "Bill Proforma"
                            : "Estimate"}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      className="px-6 py-4 text-sm text-center text-gray-500"
                    >
                      No bills found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="flex items-center justify-between p-4 mt-4">
            <button
              onClick={handlePrevious}
              className={`flex items-center p-2 px-4 font-semibold text-white bg-blue-900 border rounded-md hover:bg-blue-800 ${
                page === 1 ? "opacity-50 cursor-not-allowed" : "opacity-100"
              }`}
              disabled={page === 1}
            >
              Previous
            </button>
            {paginatedBills.length === limit && (
              <button
                onClick={handleNext}
                className="flex items-center p-2 px-4 font-semibold text-white bg-blue-900 border rounded-md hover:bg-blue-800"
              >
                Next
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BillsTable;
