import { useState } from "react";

const DashboardCard = ({ title, num, icon: Icon, hide = false }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="relative p-4 transition-all duration-300 bg-white border border-gray-100 rounded-lg shadow-sm hover:shadow-md">
      <div className="flex items-start justify-between">
        <div className="flex-1">
          <h3 className="mb-1 text-sm font-medium text-gray-500">{title}</h3>
          {!hide ? (
            <p className="text-xl font-bold text-[#1E3A8A]">{num}</p>
          ) : (
            <div className="flex items-center">
              <button
                onClick={() => setShow(!show)}
                className="text-xl font-bold text-[#1E3A8A] focus:outline-none"
              >
                {show ? num : "••••••••"}
              </button>
              <button
                onClick={() => setShow(!show)}
                className="ml-2 text-blue-500 focus:outline-none"
                aria-label={show ? "Hide value" : "Show value"}
              >
                {show ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                )}
              </button>
            </div>
          )}
        </div>
        <div className="p-3 rounded-full bg-blue-50">
          <Icon size={24} className="text-[#1E3A8A]" />
        </div>
      </div>

      {/* Optional bottom border decoration */}
      <div className="absolute bottom-0 left-0 w-full h-1 rounded-b-lg opacity-75 bg-gradient-to-r from-blue-500 to-blue-300"></div>
    </div>
  );
};

export default DashboardCard;
