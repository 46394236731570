import { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import {
  FaSpinner,
  FaExternalLinkSquareAlt,
  FaUser,
  FaUpload,
} from "react-icons/fa";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { toast } from "react-toastify";
import { formatAmountToInr, toastOptions } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import { DEFAULT_PHOTO_URL } from "../utils/constants";

const AdvisorModal = ({ data, isModalOpen, setIsModalOpen }) => {
  const navigate = useNavigate();

  // Role options for the dropdown
  const roleOptions = [
    "Technician",
    "Sales Officer",
    "Sales Advisor",
    "Head Technician",
    "Salesman",
    "Other",
  ];

  const [advisorName, setAdvisorName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [emailId, setEmailId] = useState("");
  const [role, setRole] = useState("Other"); // Default role
  const [idIssued, setIdIssued] = useState("");
  const [isActive, setIsActive] = useState("");
  const [advisorCode, setAdvisorCode] = useState("");
  const [updateBtnClicked, setUpdateBtnClicked] = useState(false);

  const [photoUrl, setPhotoUrl] = useState("");
  const [imageUploading, setImageUploading] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCommision, setTotalCommision] = useState(0);

  const storage = getStorage();

  useEffect(() => {
    setAdvisorName(data.advisor_name);
    setAddress(data.address);
    setPhoneNo(data.phone_no);
    setEmailId(data.email_id);
    setRole(data.role || "Other"); // Use "Other" as default if role is not set
    setIdIssued(data.id_issued);
    setIsActive(data.is_active);
    setPhotoUrl(data.photoUrl ?? DEFAULT_PHOTO_URL);
    setAdvisorCode(data.advisor_code);
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdateBtnClicked(true);
    const updatedData = {
      advisor_name: advisorName,
      address: address,
      phone_no: phoneNo,
      email_id: emailId,
      role: role, // Include role in the update
      id_issued: idIssued,
      is_active: isActive,
      photoUrl: photoUrl,
    };
    try {
      const updateRef = doc(db, "advisor_list", data.id);
      await updateDoc(updateRef, updatedData);

      // Also update the role in employee_codes collection if exists
      try {
        const employeeCodesQuery = query(
          collection(db, "employee_codes"),
          where("employee_code", "==", advisorCode)
        );
        const employeeCodeDocs = await getDocs(employeeCodesQuery);

        if (!employeeCodeDocs.empty) {
          const employeeDoc = employeeCodeDocs.docs[0];
          await updateDoc(doc(db, "employee_codes", employeeDoc.id), {
            role: role,
          });
        }
      } catch (innerError) {
        console.error("Error updating employee_codes:", innerError);
        // Continue with the success flow even if this update fails
      }

      setUpdateBtnClicked(false);
      setIsModalOpen(false);
      toast.success("Employee updated successfully", toastOptions);
    } catch (error) {
      setUpdateBtnClicked(false);
      toast.error("Failed to update employee", toastOptions);
      console.error(error);
    }
  };

  const handleImageUpload = useCallback(
    (e) => {
      try {
        const file = e.target.files[0];
        setImageUploading(true);
        if (!file) {
          toast.error("Please choose a file first!", toastOptions);
        } else {
          const timestamp = Date.now();
          const randomNum = Math.floor(Math.random() * 1000);
          const storageRef = ref(
            storage,
            `/advisors/${timestamp}-${randomNum}`
          );
          const uploadTask = uploadBytesResumable(storageRef, file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (err) => console.error(err),
            async () => {
              const downloadUrl = await getDownloadURL(
                uploadTask?.snapshot?.ref
              );

              setPhotoUrl(downloadUrl);
              setImageUploading(false);
            }
          );
        }
      } catch (error) {
        setPhotoUrl(DEFAULT_PHOTO_URL);
        setImageUploading(false);
        toast.error("Error uploading image", toastOptions);
        console.error(error);
      }
    },
    [storage]
  );

  const fetchOrdersOfAdvisor = async () => {
    if (advisorCode === "") {
      return;
    }
    const ordersCol = collection(db, "orders");
    const ordersQuery = query(
      ordersCol,
      where("advisor_code", "==", advisorCode)
    );
    const res = await getDocs(ordersQuery);

    const totalCost = res.docs.reduce(
      (acc, doc) => acc + (parseInt(doc.data().order_cost) || 0),
      0
    );

    const totalCommission = res.docs.reduce(
      (acc, doc) => acc + (parseInt(doc.data().commission) || 0),
      0
    );

    setTotalOrders(res.docs.length);
    setTotalAmount(totalCost);
    setTotalCommision(totalCommission);
  };

  useEffect(() => {
    fetchOrdersOfAdvisor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisorCode]);

  return (
    <Modal
      isOpen={isModalOpen}
      ariaHideApp={false}
      className="m-auto max-w-3xl bg-white rounded-lg shadow-xl overflow-auto max-h-[90vh]"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
    >
      <div className="p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold text-blue-900">Employee Details</h2>
          <button
            onClick={() => setIsModalOpen(false)}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="grid grid-cols-1 gap-6 mb-6 lg:grid-cols-3">
          {/* Profile Image Section */}
          <div className="flex flex-col items-center justify-center p-4 rounded-lg lg:col-span-1 bg-gray-50">
            <div className="relative mb-4">
              {photoUrl ? (
                <img
                  src={photoUrl}
                  alt="Employee profile"
                  className="object-cover w-32 h-32 border-4 border-blue-900 rounded-full"
                />
              ) : (
                <div className="flex items-center justify-center w-32 h-32 bg-gray-200 border-4 border-blue-900 rounded-full">
                  <FaUser size={50} className="text-gray-400" />
                </div>
              )}

              {imageUploading && (
                <div className="absolute inset-0 flex items-center justify-center bg-black rounded-full bg-opacity-40">
                  <FaSpinner className="text-white animate-spin" size={24} />
                </div>
              )}
            </div>

            <div className="text-center">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden"
                id="image-upload"
              />
              <label
                htmlFor="image-upload"
                className="inline-flex items-center px-4 py-2 text-white transition-colors bg-blue-900 rounded-md cursor-pointer hover:bg-blue-800"
              >
                <FaUpload className="mr-2" />
                Change Photo
              </label>
            </div>
          </div>

          {/* Personal Info Section */}
          <div className="lg:col-span-2">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div>
                <label
                  className="block mb-1 text-sm font-medium text-gray-700"
                  htmlFor="employeeName"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="employeeName"
                  placeholder="Enter Employee Name"
                  value={advisorName ?? ""}
                  onChange={(e) => setAdvisorName(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
                />
              </div>

              <div>
                <label
                  className="block mb-1 text-sm font-medium text-gray-700"
                  htmlFor="phoneNo"
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  id="phoneNo"
                  placeholder="Enter Phone Number"
                  maxLength={10}
                  value={phoneNo ?? ""}
                  onChange={(e) => setPhoneNo(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
                />
              </div>

              <div>
                <label
                  className="block mb-1 text-sm font-medium text-gray-700"
                  htmlFor="role"
                >
                  Role
                </label>
                <select
                  id="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
                >
                  {roleOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label
                  className="block mb-1 text-sm font-medium text-gray-700"
                  htmlFor="emailId"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="emailId"
                  placeholder="Enter Email Address"
                  value={emailId ?? ""}
                  onChange={(e) => setEmailId(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
                />
              </div>

              <div className="md:col-span-2">
                <label
                  className="block mb-1 text-sm font-medium text-gray-700"
                  htmlFor="address"
                >
                  Address
                </label>
                <input
                  type="text"
                  id="address"
                  placeholder="Enter Address"
                  value={address ?? ""}
                  onChange={(e) => setAddress(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <input
                  id="idIssued"
                  type="checkbox"
                  className="w-5 h-5 text-blue-900 border-gray-300 rounded focus:ring-blue-900"
                  checked={idIssued ?? false}
                  onChange={(e) => setIdIssued(e.target.checked)}
                />
                <label
                  htmlFor="idIssued"
                  className="ml-2 text-sm font-medium text-gray-700"
                >
                  ID Issued
                </label>
              </div>

              <div className="flex items-center">
                <input
                  id="isActive"
                  type="checkbox"
                  className="w-5 h-5 text-blue-900 border-gray-300 rounded focus:ring-blue-900"
                  checked={isActive ?? false}
                  onChange={(e) => setIsActive(e.target.checked)}
                />
                <label
                  htmlFor="isActive"
                  className="ml-2 text-sm font-medium text-gray-700"
                >
                  Account Active
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* Sales Statistics Section */}
        <div className="p-5 mb-6 rounded-lg bg-gray-50">
          <h3 className="mb-4 text-lg font-semibold text-blue-900">
            Performance Statistics
          </h3>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <div className="p-4 bg-white rounded-lg shadow-sm">
              <p className="text-sm font-medium text-gray-500">Total Orders</p>
              <p className="text-2xl font-bold text-blue-900">{totalOrders}</p>
            </div>

            <div className="p-4 bg-white rounded-lg shadow-sm">
              <p className="text-sm font-medium text-gray-500">Total Sales</p>
              <p className="text-2xl font-bold text-blue-900">
                {formatAmountToInr(totalAmount)}
              </p>
            </div>

            <div className="p-4 bg-white rounded-lg shadow-sm">
              <p className="text-sm font-medium text-gray-500">
                Total Commission
              </p>
              <p className="text-2xl font-bold text-blue-900">
                {formatAmountToInr(totalCommision)}
              </p>
            </div>
          </div>

          <div className="mt-4">
            <button
              type="button"
              onClick={
                totalOrders !== 0
                  ? () => navigate(`/admin/advisor/orders/${advisorCode}`)
                  : null
              }
              disabled={totalOrders === 0}
              className={`flex items-center px-4 py-2 rounded-md ${
                totalOrders !== 0
                  ? "bg-blue-900 text-white hover:bg-blue-800"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
              } transition-colors`}
            >
              View Order Details
              <FaExternalLinkSquareAlt className="ml-2" />
            </button>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => setIsModalOpen(false)}
            className="px-6 py-2 text-gray-700 transition-colors border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Cancel
          </button>

          <button
            onClick={handleSubmit}
            disabled={updateBtnClicked}
            className="px-6 py-2 bg-blue-900 text-white rounded-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-900 transition-colors flex items-center justify-center min-w-[100px]"
          >
            {updateBtnClicked ? (
              <FaSpinner className="animate-spin" size={20} />
            ) : (
              "Save Changes"
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AdvisorModal;
