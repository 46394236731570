import React, { useCallback, useState, useEffect } from "react";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { toast } from "react-toastify";
import { getFinancePartnerName, toastOptions } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import useOrdersStore from "../utils/useOrdersStore";
import Modal from "react-modal";
import { Link } from "react-router-dom";

// Icons
import { AiFillEdit } from "react-icons/ai";
import { RiBillLine } from "react-icons/ri";
import { FaTrash, FaSpinner } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import {
  BsCheckCircleFill,
  BsXCircleFill,
  BsBoxSeam,
  BsTools,
} from "react-icons/bs";
import { HiOutlineRefresh } from "react-icons/hi";

// Order status options with logical flow
const ORDER_STATUS_OPTIONS = [
  {
    value: "pending",
    label: "Pending",
    icon: <BsBoxSeam className="mr-2" />,
    color: "text-yellow-600",
  },
  {
    value: "delivered",
    label: "Delivered",
    icon: <BsCheckCircleFill className="mr-2" />,
    color: "text-blue-600",
  },
  {
    value: "installed",
    label: "Installed",
    icon: <BsTools className="mr-2" />,
    color: "text-green-600",
  },
  {
    value: "returned",
    label: "Returned",
    icon: <HiOutlineRefresh className="mr-2" />,
    color: "text-orange-600",
  },
  {
    value: "cancelled",
    label: "Cancelled",
    icon: <BsXCircleFill className="mr-2" />,
    color: "text-red-600",
  },
];

export default function OrderModal({
  ref: componentRef,
  data,
  isModalOpen,
  setIsModalOpen,
}) {
  const { deleteOrder } = useOrdersStore();
  const updateOrderStatus = useOrdersStore((state) => state.updateOrderStatus);
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(
    data?.order_status || "pending"
  );

  // Update local state when data changes or modal opens
  useEffect(() => {
    if (data && isModalOpen) {
      setCurrentStatus(data.order_status);
    }
  }, [data, isModalOpen]);

  const {
    id: orderId,
    advisor_code: advisorCode,
    advisor_name: advisorName,
    customer_name: customerName,
    customer_address: customerAddress,
    customer_phone_no: customerPhoneNo,
    customer_alternate_phone_no: customerAlternatePhoneNo,
    customer_aadhar_no: customerAadharNo,
    customer_pan_no: customerPanNo,
    customer_parentage: customerParentage,
    customer_bank_account_no: customerBankAccountNo,
    customer_bank_name: customerBankName,
    customer_bank_ifsc_code: customerBankIfscCode,
    customer_photo_url: customerPhotoUrl,
    customer_occupation: customerOccupation,
    customer_qualification: customerQualification,
    guarantor,
    guarantor_name: guarantorName,
    guarantor_pan_no: guarantorPanNo,
    guarantor_aadhar_no: guarantorAadharNo,
    guarantor_address: guarantorAddress,
    guarantor_bank_account_no: guarantorBankAccountNo,
    guarantor_bank_name: guarantorBankName,
    guarantor_bank_ifsc_code: guarantorIfscCode,
    guarantor_parentage: guarantorParentage,
    guarantor_phone_no: guarantorPhoneNo,
    guarantor_photo_url: guarantorPhotoUrl,
    guarantor_occupation: guarantorOccupation,
    guarantor_qualification: guarantorQualification,
    finance,
    financed_by: financedBy,
    order_details: orderDetails,
    order_date: orderDate,
    order_cost: orderCost,
    order_notes: orderNotes,
    payment_method: paymentMethod,
    downpayment,
    transaction_id: transactionId,
    customer_id: customerId,
    performaBillNo,
    challanNo,
    chequeDate,
    chequeNumber,
    chequeBankName,
    commission,
  } = data;

  const handleUpdateOrderStatus = useCallback(
    async (e) => {
      const newStatus = e.target.value;

      try {
        // First update Firebase
        const updateOrderRef = doc(db, "orders", orderId);
        await updateDoc(updateOrderRef, { order_status: newStatus });

        // Then update local state in the Zustand store
        updateOrderStatus(orderId, newStatus);

        // Update the local component state to reflect the change immediately
        setCurrentStatus(newStatus);

        toast.success(`Order status updated to ${newStatus}`, toastOptions);
      } catch (error) {
        console.error(error);
        toast.error("Failed to update order status", toastOptions);
      }
    },
    [orderId, updateOrderStatus]
  );

  const handleDeleteOrder = useCallback(async () => {
    if (!window.confirm("Are you sure you want to delete this order?")) {
      return;
    }

    try {
      setIsDeleting(true);
      const deleteOrderRef = doc(db, "orders", orderId);
      await deleteDoc(deleteOrderRef);
      toast.success("Order deleted successfully", toastOptions);
      deleteOrder(orderId);
      setIsModalOpen(false);
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete order", toastOptions);
    } finally {
      setIsDeleting(false);
    }
  }, [orderId, setIsModalOpen, deleteOrder]);

  // Helper to get the status name for display
  const getStatusDisplayName = (statusValue) => {
    const status = ORDER_STATUS_OPTIONS.find(
      (option) => option.value === statusValue
    );
    return status ? status.label : "Unknown";
  };

  return (
    <Modal
      isOpen={isModalOpen}
      ariaHideApp={false}
      className="m-auto max-w-5xl bg-white rounded-lg shadow-xl overflow-auto max-h-[90vh]"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
      onRequestClose={() => setIsModalOpen(false)}
    >
      <div className="p-6" ref={(el) => el && (componentRef = el)}>
        {/* Header with close button */}
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold text-blue-900">Order Details</h2>
          <button
            onClick={() => setIsModalOpen(false)}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
            aria-label="Close"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="space-y-8">
          {/* Order Information */}
          <section className="p-4 border border-gray-200 rounded-lg bg-gray-50">
            <h3 className="flex items-center mb-4 text-lg font-bold text-blue-900">
              <BsBoxSeam className="mr-2" /> Order Information
            </h3>

            {/* Order status badge - Using currentStatus */}
            <div className="flex items-center mb-4">
              <div
                className={`px-3 py-1 rounded-full ${
                  currentStatus === "installed"
                    ? "bg-green-100 text-green-800"
                    : currentStatus === "delivered"
                    ? "bg-blue-100 text-blue-800"
                    : currentStatus === "pending"
                    ? "bg-yellow-100 text-yellow-800"
                    : currentStatus === "returned"
                    ? "bg-orange-100 text-orange-800"
                    : "bg-red-100 text-red-800"
                } text-sm font-medium`}
              >
                {getStatusDisplayName(currentStatus)}
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
              <InfoItem label="Order ID" value={orderId} />
              <InfoItem label="Performa Bill No." value={performaBillNo} />
              <InfoItem label="Challan No." value={challanNo || "N/A"} />
              <InfoItem label="Details" value={orderDetails} />
              <InfoItem label="Date" value={orderDate} />
              <InfoItem label="Cost" value={`₹ ${orderCost}`} />
              <InfoItem label="Notes" value={orderNotes || "N/A"} />
              <InfoItem
                label="Downpayment"
                value={downpayment ? `₹ ${downpayment}` : "N/A"}
              />

              <div>
                <p className="text-sm font-medium text-gray-700">
                  Update Status
                </p>
                <select
                  value={currentStatus ?? ""}
                  onChange={handleUpdateOrderStatus}
                  className="w-full px-3 py-2 mt-1 text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  {ORDER_STATUS_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <InfoItem label="Payment Method" value={paymentMethod} />
              {(paymentMethod === "upi" || paymentMethod === "card") && (
                <InfoItem label="Transaction ID" value={transactionId} />
              )}
            </div>

            {paymentMethod === "cheque" && (
              <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 md:grid-cols-3">
                <InfoItem label="Cheque Number" value={chequeNumber} />
                <InfoItem label="Cheque Bank Name" value={chequeBankName} />
                <InfoItem label="Cheque Date" value={chequeDate} />
              </div>
            )}

            <div className="mt-4 no-print">
              <InfoItem
                label="Commission"
                value={commission ? `₹ ${commission}` : "N/A"}
              />
            </div>
          </section>

          {/* Customer Information with Photo */}
          <section className="p-4 border border-gray-200 rounded-lg bg-gray-50">
            <h3 className="mb-4 text-lg font-bold text-blue-900">
              Customer Information
            </h3>

            <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
              {/* Customer Photo */}
              {customerPhotoUrl && (
                <div className="flex flex-col items-center justify-center">
                  <div className="overflow-hidden border-4 border-blue-900 rounded-lg w-36 h-36">
                    <img
                      src={customerPhotoUrl}
                      alt="Customer"
                      className="object-cover w-full h-full"
                    />
                  </div>
                  <p className="mt-2 font-medium">{customerName}</p>
                  <p className="text-sm text-gray-600">ID: {customerId}</p>
                </div>
              )}

              {/* Customer Details - First Column */}
              <div className="space-y-3 lg:col-span-2">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <InfoItem label="Name" value={customerName} />
                  <InfoItem label="Parentage" value={customerParentage} />
                  <InfoItem label="Phone No." value={customerPhoneNo} />
                  <InfoItem
                    label="Alternate Phone No."
                    value={customerAlternatePhoneNo}
                  />
                  <InfoItem label="Occupation" value={customerOccupation} />
                  <InfoItem
                    label="Qualification"
                    value={customerQualification}
                  />
                  <InfoItem label="Address" value={customerAddress} />
                  <InfoItem
                    label="Aadhar No."
                    value={customerAadharNo || "N/A"}
                  />
                  <InfoItem label="PAN No." value={customerPanNo || "N/A"} />
                  <InfoItem
                    label="Bank Account No."
                    value={customerBankAccountNo || "N/A"}
                  />
                  <InfoItem
                    label="Bank Name"
                    value={customerBankName || "N/A"}
                  />
                  <InfoItem
                    label="Bank IFSC Code"
                    value={customerBankIfscCode || "N/A"}
                  />
                </div>
              </div>
            </div>
          </section>

          {/* Guarantor Information */}
          {guarantor && (
            <section className="p-4 border border-gray-200 rounded-lg bg-gray-50">
              <h3 className="mb-4 text-lg font-bold text-blue-900">
                Guarantor Information
              </h3>

              <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
                {/* Guarantor Photo */}
                {guarantorPhotoUrl && (
                  <div className="flex flex-col items-center justify-center">
                    <div className="overflow-hidden border-4 border-blue-900 rounded-lg w-36 h-36">
                      <img
                        src={guarantorPhotoUrl}
                        alt="Guarantor"
                        className="object-cover w-full h-full"
                      />
                    </div>
                    <p className="mt-2 font-medium">{guarantorName}</p>
                  </div>
                )}

                {/* Guarantor Details */}
                <div className="space-y-3 lg:col-span-2">
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <InfoItem label="Name" value={guarantorName} />
                    <InfoItem label="Parentage" value={guarantorParentage} />
                    <InfoItem label="Phone No." value={guarantorPhoneNo} />
                    <InfoItem label="Occupation" value={guarantorOccupation} />
                    <InfoItem
                      label="Qualification"
                      value={guarantorQualification}
                    />
                    <InfoItem label="Address" value={guarantorAddress} />
                    <InfoItem
                      label="Aadhar No."
                      value={guarantorAadharNo || "N/A"}
                    />
                    <InfoItem label="PAN No." value={guarantorPanNo || "N/A"} />
                    <InfoItem
                      label="Bank Account No."
                      value={guarantorBankAccountNo || "N/A"}
                    />
                    <InfoItem
                      label="Bank Name"
                      value={guarantorBankName || "N/A"}
                    />
                    <InfoItem
                      label="IFSC Code"
                      value={guarantorIfscCode || "N/A"}
                    />
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* Finance Information */}
          {finance && (
            <section className="p-4 border border-gray-200 rounded-lg bg-gray-50">
              <h3 className="mb-4 text-lg font-bold text-blue-900">
                Finance Information
              </h3>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <InfoItem
                  label="Financed By"
                  value={getFinancePartnerName(financedBy)}
                />
              </div>
            </section>
          )}

          {/* Advisor Information */}
          <section className="p-4 border border-gray-200 rounded-lg bg-gray-50">
            <h3 className="mb-4 text-lg font-bold text-blue-900">
              Advisor Information
            </h3>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <InfoItem label="Advisor Code" value={advisorCode} />
              <InfoItem label="Advisor Name" value={advisorName} />
            </div>
          </section>
        </div>

        {/* Action Buttons */}
        <div className="flex flex-col justify-end gap-3 mt-8 sm:flex-row">
          <div className="flex flex-wrap w-full gap-3 sm:w-auto">
            <ActionButton
              icon={<RiBillLine className="mr-2" />}
              text="Bill"
              onClick={() => navigate(`/admin/bill/${orderId}`)}
              primary
              className="flex-1 sm:flex-none"
            />

            <ActionButton
              as={Link}
              to={`/admin/edit-order/${orderId}`}
              icon={<AiFillEdit className="mr-2" />}
              text="Edit"
              primary
              className="flex-1 sm:flex-none"
            />
          </div>

          <div className="flex flex-wrap w-full gap-3 mt-3 sm:w-auto sm:mt-0">
            <ActionButton
              icon={
                isDeleting ? (
                  <FaSpinner className="mr-2 animate-spin" />
                ) : (
                  <FaTrash className="mr-2" />
                )
              }
              text="Delete"
              onClick={handleDeleteOrder}
              disabled={isDeleting}
              danger
              className="flex-1 sm:flex-none"
            />

            <ActionButton
              icon={<MdOutlineCancel className="mr-2" />}
              text="Close"
              onClick={() => setIsModalOpen(false)}
              secondary
              className="flex-1 sm:flex-none"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

// Helper Components
const InfoItem = ({ label, value }) => (
  <div>
    <p className="text-sm font-medium text-gray-700">{label}</p>
    <p className="mt-1 font-semibold text-gray-900 break-words">{value}</p>
  </div>
);

const ActionButton = ({
  icon,
  text,
  onClick,
  as: Component = "button",
  primary,
  danger,
  secondary,
  disabled,
  className = "",
  ...props
}) => {
  let bgColor = "bg-gray-200 hover:bg-gray-300 text-gray-800";

  if (primary) bgColor = "bg-blue-900 hover:bg-blue-800 text-white";
  if (danger) bgColor = "bg-red-600 hover:bg-red-700 text-white";
  if (secondary) bgColor = "bg-gray-500 hover:bg-gray-600 text-white";

  return (
    <Component
      onClick={onClick}
      disabled={disabled}
      className={`inline-flex items-center justify-center px-4 py-2 font-medium rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${bgColor} ${
        disabled ? "opacity-50 cursor-not-allowed" : ""
      } ${className}`}
      {...props}
    >
      {icon}
      {text}
    </Component>
  );
};
