import React, { useCallback, useState, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../utils/authContext";
import { toast } from "react-toastify";

// Icons
import { RxDashboard } from "react-icons/rx";
import { FiLogOut } from "react-icons/fi";
import { FaPeopleArrows, FaSpinner } from "react-icons/fa";
import { MdShoppingCart } from "react-icons/md";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { TbRefresh } from "react-icons/tb";

const UserLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { signOut, currentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Auto-collapse sidebar on small screens
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth < 768) {
        setCollapsed(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize on mount

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLogOut = useCallback(async () => {
    try {
      setIsLoading(true);
      await signOut();
      navigate("/user");
      toast.success("Successfully logged out");
    } catch (error) {
      console.error("Logout error:", error);
      toast.error("There was an issue logging out");
    } finally {
      setIsLoading(false);
    }
  }, [signOut, navigate]);

  const handleRefreshData = async () => {
    setIsRefreshing(true);
    // Simulate data refresh - replace with actual data fetching if needed
    setTimeout(() => {
      setIsRefreshing(false);
      toast.success("Data refreshed successfully");
    }, 1000);
  };

  const toggleSidebar = () => {
    // Only toggle if on larger screens
    if (windowWidth >= 768) {
      setCollapsed(!collapsed);
    }
  };

  const activeClassName = `px-3 py-2.5 rounded-md bg-blue-950 transition-all duration-200 ${
    collapsed ? "mx-0" : "mx-1"
  }`;
  const defaultClassName = `px-3 py-2.5 rounded-md hover:bg-blue-950 transition-all duration-200 ${
    collapsed ? "mx-0" : "mx-1"
  } bg-blue-900`;

  const navItems = [
    {
      path: "/user/dashboard",
      icon: <RxDashboard size={collapsed ? 22 : 18} />,
      label: "Dashboard",
    },
    {
      path: "/user/customers",
      icon: <FaPeopleArrows size={collapsed ? 22 : 18} />,
      label: "Customers",
    },
    {
      path: "/user/orders",
      icon: <MdShoppingCart size={collapsed ? 22 : 18} />,
      label: "Orders",
    },
  ];

  return (
    <main className="flex h-screen overflow-hidden bg-[#F6F7F9]">
      {/* Sidebar - always visible but collapsed on small screens */}
      <div
        className={`h-full bg-blue-900 transition-all duration-300 ease-in-out shadow-lg flex flex-col ${
          windowWidth < 768 || collapsed ? "w-16" : "w-64"
        }`}
      >
        <div className="flex items-center justify-between px-3 py-4 border-b border-blue-800">
          {!collapsed && windowWidth >= 768 ? (
            <Link to="/user/dashboard" className="flex items-center">
              <img
                height={48}
                width={48}
                src="/assets/logo.svg"
                alt="logo"
                className="w-10 h-10"
              />
            </Link>
          ) : (
            <Link
              to="/user/dashboard"
              className="flex items-center justify-center w-full"
            >
              <img
                height={22}
                width={22}
                src="/assets/logo.svg"
                alt="logo"
                className="w-8 h-8"
              />
            </Link>
          )}
        </div>

        {/* User Info */}
        {!collapsed && windowWidth >= 768 && (
          <div className="px-4 py-3 mx-2 mt-2 mb-2 text-center text-white rounded-lg bg-blue-950">
            <p className="text-sm font-medium truncate">
              {currentUser?.displayName || currentUser?.email || "User"}
            </p>
          </div>
        )}

        {/* Navigation */}
        <div className="flex flex-col justify-between h-[calc(100%-64px)] overflow-hidden">
          <nav className="flex-grow py-4 overflow-y-auto">
            <ul className="px-2 space-y-1">
              {navItems.map((item) => (
                <li
                  key={item.path}
                  className={
                    location.pathname === item.path
                      ? activeClassName
                      : defaultClassName
                  }
                >
                  <Link
                    to={item.path}
                    className={`flex items-center text-white ${
                      windowWidth < 768 || collapsed
                        ? "justify-center"
                        : "space-x-3"
                    }`}
                    title={windowWidth < 768 || collapsed ? item.label : ""}
                  >
                    {item.icon}
                    {!(windowWidth < 768 || collapsed) && (
                      <span className="font-medium">{item.label}</span>
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          {/* Bottom actions */}
          <div className="px-2 py-4 border-t border-blue-800">
            {/* Toggle sidebar button */}
            {windowWidth >= 768 && (
              <button
                onClick={toggleSidebar}
                className={`w-full mb-2 px-3 py-2.5 bg-blue-800 rounded-md hover:bg-blue-700 transition-all duration-200 ${
                  windowWidth < 768 || collapsed
                    ? "flex justify-center"
                    : "flex items-center space-x-3"
                }`}
                title={collapsed ? "Expand Sidebar" : "Collapse Sidebar"}
              >
                {collapsed ? (
                  <HiChevronRight
                    size={collapsed ? 22 : 18}
                    className="text-white"
                  />
                ) : (
                  <>
                    <HiChevronLeft size={18} className="text-white" />
                    <span className="font-medium text-white">
                      Collapse Menu
                    </span>
                  </>
                )}
              </button>
            )}

            {/* Refresh Data Button */}
            <button
              onClick={handleRefreshData}
              disabled={isRefreshing}
              className={`w-full mb-2 px-3 py-2.5 bg-blue-800 rounded-md hover:bg-blue-700 transition-all duration-200 ${
                windowWidth < 768 || collapsed
                  ? "flex justify-center"
                  : "flex items-center space-x-3"
              }`}
              title={windowWidth < 768 || collapsed ? "Refresh Data" : ""}
            >
              {isRefreshing ? (
                <FaSpinner
                  className="text-white animate-spin"
                  size={windowWidth < 768 || collapsed ? 22 : 18}
                />
              ) : (
                <>
                  <TbRefresh
                    size={windowWidth < 768 || collapsed ? 22 : 18}
                    className="text-white"
                  />
                  {!(windowWidth < 768 || collapsed) && (
                    <span className="font-medium text-white">Refresh Data</span>
                  )}
                </>
              )}
            </button>

            {/* Logout Button */}
            <button
              onClick={handleLogOut}
              className={`w-full px-3 py-2.5 bg-blue-800 rounded-md hover:bg-blue-700 transition-all duration-200 ${
                windowWidth < 768 || collapsed
                  ? "flex justify-center"
                  : "flex items-center space-x-3"
              }`}
              disabled={isLoading}
              title={windowWidth < 768 || collapsed ? "Log Out" : ""}
            >
              {isLoading ? (
                <FaSpinner
                  className="text-white animate-spin"
                  size={windowWidth < 768 || collapsed ? 22 : 18}
                />
              ) : (
                <>
                  <FiLogOut
                    size={windowWidth < 768 || collapsed ? 22 : 18}
                    className="text-white"
                  />
                  {!(windowWidth < 768 || collapsed) && (
                    <span className="font-medium text-white">Log Out</span>
                  )}
                </>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div
        className={`h-full overflow-x-auto overflow-y-auto transition-all duration-300 ease-in-out ${
          windowWidth < 768
            ? "w-[calc(100%-4rem)]"
            : collapsed
            ? "w-[calc(100%-4rem)]"
            : "w-[calc(100%-16rem)]"
        }`}
      >
        {/* Content padding */}
        <div className="px-4 pb-4 md:px-6">{children}</div>
      </div>
    </main>
  );
};

export default UserLayout;
