function parseFirebaseTimestamp(timestamp) {
  if (timestamp === null) return null;
  const date = new Date(timestamp.seconds * 1000);
  const formattedDate = date.toLocaleString();
  return formattedDate;
}

const toastOptions = {
  position: "bottom-center",
  theme: "light",
  autoClose: 1500,
};

const formatAmountToInr = (amount) => {
  const numericAmount = parseFloat(amount);
  if (isNaN(numericAmount)) {
    return "Invalid Input";
  }

  // Format the number to Indian currency format
  const formattedAmount = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(numericAmount);

  return formattedAmount;
};

const getFinancePartnerName = (code) => {
  if (!code) return "N/A";

  const financePartners = {
    j_and_k_bank: "J&K Bank",
    sbi_bank: "SBI Bank",
    hdfc_bank: "HDFC Bank",
    bajaj_finserv: "Bajaj Finserv",
    samsung_finance: "Samsung Finance",
  };

  return financePartners[code] || code;
};

export {
  parseFirebaseTimestamp,
  toastOptions,
  formatAmountToInr,
  getFinancePartnerName,
};
