import React, { useContext, useState, useEffect } from "react";
import { RxDashboard } from "react-icons/rx";
import { FaSolarPanel, FaSpinner, FaUserTie } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { AuthContext } from "../utils/authContext";
import { Link, useLocation } from "react-router-dom";
import { AiFillMessage } from "react-icons/ai";
import { HiUsers } from "react-icons/hi";
import { FaPeopleArrows } from "react-icons/fa";
import { MdShoppingCart } from "react-icons/md";
import { RiBillLine } from "react-icons/ri";
import { TbRefresh } from "react-icons/tb";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import useOrdersStore from "../utils/useOrdersStore";
import { ClipLoader } from "react-spinners";

export default function AdminLayout({ children }) {
  const location = useLocation();
  const { signOut } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { setLoading, setCached, fetchOrders, loading } = useOrdersStore();

  // Auto-collapse sidebar on small screens
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth < 768) {
        setCollapsed(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize on mount

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleRefreshData = async () => {
    setLoading(true);
    setCached(false);
    await fetchOrders();
  };

  const handleLogOut = async () => {
    try {
      setIsLoading(true);
      await signOut();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleSidebar = () => {
    // Only toggle if on larger screens
    if (windowWidth >= 768) {
      setCollapsed(!collapsed);
    }
  };

  const activeClassName = `px-3 py-2.5 rounded-md bg-blue-950 transition-all duration-200 ${
    collapsed ? "mx-0" : "mx-1"
  }`;
  const defaultClassName = `px-3 py-2.5 rounded-md hover:bg-blue-950 transition-all duration-200 ${
    collapsed ? "mx-0" : "mx-1"
  } bg-blue-900`;

  const navItems = [
    {
      path: "/admin/dashboard",
      icon: <RxDashboard size={collapsed ? 22 : 18} />,
      label: "Dashboard",
    },
    // {
    //   path: "/admin/advisors",
    //   icon: <HiUser size={collapsed ? 22 : 18} />,
    //   label: "Advisors",
    // },
    {
      path: "/admin/employees",
      icon: <FaUserTie size={collapsed ? 22 : 18} />,
      label: "Employees",
    },
    {
      path: "/admin/customers",
      icon: <FaPeopleArrows size={collapsed ? 22 : 18} />,
      label: "Customers",
    },
    {
      path: "/admin/orders",
      icon: <MdShoppingCart size={collapsed ? 22 : 18} />,
      label: "Orders",
    },
    {
      path: "/admin/billing",
      icon: <RiBillLine size={collapsed ? 22 : 18} />,
      label: "Billing",
    },
    {
      path: "/admin/messaging",
      icon: <AiFillMessage size={collapsed ? 22 : 18} />,
      label: "Messaging",
    },
    {
      path: "/admin/users",
      icon: <HiUsers size={collapsed ? 22 : 18} />,
      label: "Users",
    },
    {
      path: "/admin/solar-roof-top",
      icon: <FaSolarPanel size={collapsed ? 22 : 18} />,
      label: "Solar Roof Top",
    },
  ];

  return (
    <main className="flex h-screen overflow-hidden bg-[#F6F7F9]">
      {/* Sidebar - always visible but collapsed on small screens */}
      <div
        className={`h-full bg-blue-900 transition-all duration-300 ease-in-out shadow-lg flex flex-col ${
          windowWidth < 768 || collapsed ? "w-16" : "w-64"
        }`}
      >
        <div className="flex items-center justify-between px-3 py-4 border-b border-blue-800">
          {!collapsed && windowWidth >= 768 ? (
            <Link to="/admin/dashboard" className="flex items-center">
              <img
                height={48}
                width={48}
                src="/assets/logo.svg"
                alt="logo"
                className="w-10 h-10"
              />
            </Link>
          ) : (
            <Link
              to="/admin/dashboard"
              className="flex items-center justify-center w-full"
            >
              <img
                height={22}
                width={22}
                src="/assets/logo.svg"
                alt="logo"
                className="w-8 h-8"
              />
            </Link>
          )}
        </div>

        {/* Navigation */}
        <div className="flex flex-col justify-between h-[calc(100%-64px)] overflow-hidden">
          <nav className="flex-grow py-4 overflow-y-auto">
            <ul className="px-2 space-y-1">
              {navItems.map((item) => (
                <li
                  key={item.path}
                  className={
                    location.pathname === item.path
                      ? activeClassName
                      : defaultClassName
                  }
                >
                  <Link
                    to={item.path}
                    className={`flex items-center text-white ${
                      windowWidth < 768 || collapsed
                        ? "justify-center"
                        : "space-x-3"
                    }`}
                    title={windowWidth < 768 || collapsed ? item.label : ""}
                  >
                    {item.icon}
                    {!(windowWidth < 768 || collapsed) && (
                      <span className="font-medium">{item.label}</span>
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          {/* Bottom actions */}
          <div className="px-2 py-4 border-t border-blue-800">
            {/* Toggle sidebar button - placed at the top of bottom actions section */}
            {windowWidth >= 768 && (
              <button
                onClick={toggleSidebar}
                className={`w-full mb-2 px-3 py-2.5 bg-blue-800 rounded-md hover:bg-blue-700 transition-all duration-200 ${
                  windowWidth < 768 || collapsed
                    ? "flex justify-center"
                    : "flex items-center space-x-3"
                }`}
                title={collapsed ? "Expand Sidebar" : "Collapse Sidebar"}
              >
                {collapsed ? (
                  <HiChevronRight
                    size={collapsed ? 22 : 18}
                    className="text-white"
                  />
                ) : (
                  <>
                    <HiChevronLeft size={18} className="text-white" />
                    <span className="font-medium text-white">
                      Collapse Menu
                    </span>
                  </>
                )}
              </button>
            )}

            <button
              onClick={handleRefreshData}
              disabled={loading}
              className={`w-full mb-2 px-3 py-2.5 bg-blue-800 rounded-md hover:bg-blue-700 transition-all duration-200 ${
                windowWidth < 768 || collapsed
                  ? "flex justify-center"
                  : "flex items-center space-x-3"
              }`}
              title={windowWidth < 768 || collapsed ? "Refresh Data" : ""}
            >
              {loading ? (
                <>
                  <ClipLoader size={24} className="mr-2" color="white" />
                  {!(windowWidth < 768 || collapsed) && (
                    <span className="font-medium text-white">Loading...</span>
                  )}
                </>
              ) : (
                <>
                  <TbRefresh
                    size={windowWidth < 768 || collapsed ? 22 : 18}
                    className="text-white"
                  />
                  {!(windowWidth < 768 || collapsed) && (
                    <span className="font-medium text-white">Refresh Data</span>
                  )}
                </>
              )}
            </button>
            <button
              onClick={handleLogOut}
              className={`w-full px-3 py-2.5 bg-blue-800 rounded-md hover:bg-blue-700 transition-all duration-200 ${
                windowWidth < 768 || collapsed
                  ? "flex justify-center"
                  : "flex items-center space-x-3"
              }`}
              disabled={isLoading}
              title={windowWidth < 768 || collapsed ? "Log Out" : ""}
            >
              {isLoading ? (
                <FaSpinner
                  className="text-white animate-spin"
                  size={windowWidth < 768 || collapsed ? 22 : 18}
                />
              ) : (
                <>
                  <FiLogOut
                    size={windowWidth < 768 || collapsed ? 22 : 18}
                    className="text-white"
                  />
                  {!(windowWidth < 768 || collapsed) && (
                    <span className="font-medium text-white">Log Out</span>
                  )}
                </>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div
        className={`h-full overflow-x-auto overflow-y-auto transition-all duration-300 ease-in-out ${
          windowWidth < 768
            ? "w-[calc(100%-4rem)]"
            : collapsed
            ? "w-[calc(100%-4rem)]"
            : "w-[calc(100%-16rem)]"
        }`}
      >
        {/* Content padding */}
        <div className="px-4 pb-4 md:px-6">{children}</div>
      </div>
    </main>
  );
}
