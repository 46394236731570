import React, { useState } from "react";
import { parseFirebaseTimestamp } from "../utils/helpers";

export default function CustomersTable({ customers }) {
  const [modalImageUrl, setModalImageUrl] = useState(null);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const truncatedCustomers =
    customers.length > 20 ? customers.slice(0, limit) : customers;

  const handleNext = () => {
    setPage(page + 1);
    setLimit(Math.max(1, limit + 20));
  };

  const handlePrevious = () => {
    setPage(Math.max(1, page - 1));
    setLimit(Math.max(1, limit - 20));
  };

  const handleImageClick = (imageUrl) => {
    setModalImageUrl(imageUrl);
  };

  const handleCloseModal = () => {
    setModalImageUrl(null);
  };
  return (
    <>
      {modalImageUrl && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
          <div className="max-w-md p-4 overflow-auto bg-white rounded-lg">
            <img src={modalImageUrl} alt="Enlarged Customer" />
            <div className="flex justify-center mt-4">
              <button
                className="block px-4 py-2 mt-4 text-white bg-blue-900 rounded-md hover:bg-blue-800"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Customer ID
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Customer Image
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Customer Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Phone Number
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Created At
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {truncatedCustomers.map((customer) => (
            <tr key={customer.id}>
              <td className="px-6 py-4 text-sm font-bold text-gray-500 whitespace-nowrap">
                {customer.id}
              </td>
              <td className="px-6 py-4">
                <img
                  src={customer.photoUrl}
                  alt={customer.customer_name}
                  className="w-10 h-10 rounded-full cursor-pointer"
                  onClick={() => handleImageClick(customer.photoUrl)}
                />
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {customer.customer_name}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {customer.customer_phone_no
                  ? customer.customer_phone_no
                  : "N/A"}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {customer.createdAt
                  ? parseFirebaseTimestamp(customer.createdAt)
                  : "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center justify-between p-4 mt-4">
        <button
          onClick={handlePrevious}
          className={`flex items-center p-2 px-4 font-semibold text-white bg-blue-900 border rounded-md hover:bg-blue-800 ${
            page === 1 ? "opacity-0" : "opacity-100"
          }`}
          disabled={page === 1}
        >
          Previous
        </button>
        {!(truncatedCustomers.length >= customers.length) && (
          <button
            onClick={handleNext}
            className="flex items-center p-2 px-4 font-semibold text-white bg-blue-900 border rounded-md hover:bg-blue-800"
          >
            Next
          </button>
        )}
      </div>
    </>
  );
}
