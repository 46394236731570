import React, { useState, useEffect, useRef } from "react";
import { parseFirebaseTimestamp } from "../utils/helpers";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { toast } from "react-toastify";
import { toastOptions } from "../utils/helpers";

const SolarRoofTopLeadsTable = ({ leads }) => {
  const [page, setPage] = useState(1);
  const [updatingId, setUpdatingId] = useState(null);
  const [localLeads, setLocalLeads] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [leadToDelete, setLeadToDelete] = useState(null);
  const deleteDialogRef = useRef(null);

  useEffect(() => {
    // Initialize localLeads with the props leads, ensuring status is set
    setLocalLeads(
      leads.map((lead) => ({
        ...lead,
        status: lead.status || "none",
      }))
    );
  }, [leads]);

  const truncatedLeads =
    localLeads.length > 20
      ? localLeads.slice((page - 1) * 20, page * 20)
      : localLeads;

  const handleNext = () => {
    setPage(page + 1);
  };

  const handlePrevious = () => {
    setPage(Math.max(1, page - 1));
  };

  const statusOptions = [
    { value: "none", label: "None" },
    { value: "interested", label: "Interested" },
    { value: "not interested", label: "Not Interested" },
    { value: "assigned", label: "Assigned" },
    { value: "closed", label: "Closed" },
  ];

  const handleStatusChange = async (leadId, newStatus) => {
    setUpdatingId(leadId);

    try {
      // Update Firestore
      const leadRef = doc(db, "solar_roof_top_leads", leadId);
      await updateDoc(leadRef, {
        status: newStatus,
      });

      // Update local state
      setLocalLeads((prevLeads) =>
        prevLeads.map((lead) =>
          lead.id === leadId ? { ...lead, status: newStatus } : lead
        )
      );

      toast.success("Status updated successfully", toastOptions);
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status", toastOptions);
    } finally {
      setUpdatingId(null);
    }
  };

  // Delete lead initialization
  const openDeleteDialog = (lead) => {
    setLeadToDelete(lead);
    setShowDeleteDialog(true);
  };

  // Delete lead functionality
  const handleDeleteLead = async () => {
    if (!leadToDelete) return;

    setIsDeleting(true);

    try {
      // Delete from Firestore
      const leadRef = doc(db, "solar_roof_top_leads", leadToDelete.id);
      await deleteDoc(leadRef);

      // Update local state
      setLocalLeads((prevLeads) =>
        prevLeads.filter((lead) => lead.id !== leadToDelete.id)
      );

      toast.success("Lead deleted successfully", toastOptions);
    } catch (error) {
      console.error("Error deleting lead:", error);
      toast.error("Failed to delete lead", toastOptions);
    } finally {
      setShowDeleteDialog(false);
      setLeadToDelete(null);
      setIsDeleting(false);
    }
  };

  return (
    <>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Phone Number
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Address
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Timestamp
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Status
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-center text-gray-500 uppercase"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {truncatedLeads.map((lead) => (
            <tr key={lead.id}>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {lead.name}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {lead.phone ? lead.phone : "N/A"}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {lead.address}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {lead.timestamp
                  ? parseFirebaseTimestamp(lead.timestamp)
                  : "N/A"}
              </td>
              <td className="px-6 py-4 text-sm whitespace-nowrap">
                <div className="relative flex items-center">
                  <div
                    className={`px-2 py-1 text-xs font-medium rounded-full mr-2 ${getStatusBadgeClasses(
                      lead.status || "none"
                    )}`}
                  >
                    {getStatusLabel(lead.status || "none")}
                  </div>

                  {/* Delete Confirmation Dialog */}
                  {showDeleteDialog && (
                    <div
                      className="fixed inset-0 z-50 overflow-y-auto bg-gray-200 bg-opacity-5 "
                      aria-labelledby="modal-title"
                      role="dialog"
                      aria-modal="true"
                    >
                      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                        {/* Background overlay with elegant blur effect */}
                        <div
                          className="fixed inset-0 transition-opacity"
                          aria-hidden="true"
                          onClick={() => setShowDeleteDialog(false)}
                        ></div>

                        {/* Modal panel with improved aesthetics */}
                        <div
                          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-sm sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                          ref={deleteDialogRef}
                        >
                          <div className="px-4 pt-5 pb-4 bg-white sm:p-6">
                            <div className="sm:flex sm:items-start">
                              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                                <svg
                                  className="w-6 h-6 text-red-600"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              </div>
                              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3
                                  className="text-xl font-semibold leading-6 text-gray-900"
                                  id="modal-title"
                                >
                                  Delete Lead
                                </h3>
                                <div className="mt-2">
                                  <p className="text-sm text-gray-600 whitespace-normal">
                                    Are you sure you want to delete this lead?
                                  </p>
                                  <p className="mt-1 text-sm text-gray-600 whitespace-normal">
                                    This action cannot be undone.
                                  </p>
                                  {leadToDelete && (
                                    <div className="p-4 mt-3 border border-gray-200 rounded-lg bg-gray-50">
                                      <p className="text-sm font-medium text-gray-800">
                                        {leadToDelete.name}
                                        {leadToDelete.phone && (
                                          <span className="ml-2 text-gray-600">
                                            {leadToDelete.phone}
                                          </span>
                                        )}
                                      </p>
                                      {leadToDelete.address && (
                                        <p className="mt-1 text-sm text-gray-600 break-words">
                                          {leadToDelete.address}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="px-4 py-4 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                              type="button"
                              onClick={handleDeleteLead}
                              disabled={isDeleting}
                              className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white transition-colors duration-200 bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto disabled:opacity-60"
                            >
                              {isDeleting ? (
                                <>
                                  <svg
                                    className="w-4 h-4 mr-2 text-white animate-spin"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                  <span>Deleting...</span>
                                </>
                              ) : (
                                "Delete"
                              )}
                            </button>
                            <button
                              type="button"
                              onClick={() => setShowDeleteDialog(false)}
                              className="inline-flex justify-center w-full px-4 py-2 mt-3 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <select
                    value={lead.status || "none"}
                    onChange={(e) =>
                      handleStatusChange(lead.id, e.target.value)
                    }
                    disabled={updatingId === lead.id}
                    className="block py-1 pl-2 pr-8 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  >
                    {statusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {updatingId === lead.id && (
                    <svg
                      className="w-4 h-4 ml-2 text-blue-900 animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                </div>
              </td>
              <td className="px-6 py-4 text-sm text-center whitespace-nowrap">
                <button
                  onClick={() => openDeleteDialog(lead)}
                  disabled={updatingId === lead.id}
                  className="inline-flex items-center justify-center p-2 text-red-600 bg-red-100 rounded-full hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:opacity-50"
                  aria-label="Delete lead"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center justify-between p-4 mt-4">
        <button
          onClick={handlePrevious}
          className={`flex items-center p-2 px-4 font-semibold text-white bg-blue-900 border rounded-md hover:bg-blue-800 ${
            page === 1 ? "opacity-50 cursor-not-allowed" : "opacity-100"
          }`}
          disabled={page === 1}
        >
          Previous
        </button>
        {localLeads.length > page * 20 && (
          <button
            onClick={handleNext}
            className="flex items-center p-2 px-4 font-semibold text-white bg-blue-900 border rounded-md hover:bg-blue-800"
          >
            Next
          </button>
        )}
      </div>
    </>
  );
};

// Helper function for status badges
const getStatusBadgeClasses = (status) => {
  switch (status) {
    case "interested":
      return "bg-green-100 text-green-800";
    case "not interested":
      return "bg-red-100 text-red-800";
    case "assigned":
      return "bg-blue-100 text-blue-800";
    case "closed":
      return "bg-gray-200 text-gray-800";
    default:
      return "bg-gray-100 text-gray-600";
  }
};

// Helper function to get a formatted status label
const getStatusLabel = (status) => {
  switch (status) {
    case "none":
      return "None";
    case "interested":
      return "Interested";
    case "not interested":
      return "Not Interested";
    case "assigned":
      return "Assigned";
    case "closed":
      return "Closed";
    default:
      return "None";
  }
};

export default SolarRoofTopLeadsTable;
