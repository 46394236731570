import React, { useState, useEffect, useCallback, useMemo } from "react";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import { Link } from "react-router-dom";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { HiSearch } from "react-icons/hi";
import OrdersTable from "../../components/OrdersTable";
import { ClipLoader } from "react-spinners";
import LayoutHeader from "../../components/LayoutHeader";
import useOrdersStore from "../../utils/useOrdersStore";

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isPmsgyOnly, setIsPmsgyOnly] = useState(false);
  const [ordersCount, setOrdersCount] = useState(0);

  const { docs, fetchOrders, loading } = useOrdersStore();

  // Memoized callbacks for better performance
  const updateOrders = useCallback((newOrders) => {
    setOrders(newOrders);
  }, []);

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const handlePmsgyToggle = useCallback(() => {
    setIsPmsgyOnly((prev) => !prev);
  }, []);

  // Fetch orders on component mount
  useEffect(() => {
    const getOrders = async () => {
      await fetchOrders();
      updateOrders(docs);
    };

    getOrders();
  }, [docs, fetchOrders, updateOrders]);

  // Filter orders based on search query and PMSGY filter
  const filteredOrders = useMemo(() => {
    if (!orders.length) return [];

    const query = searchQuery.toLowerCase().trim();

    return orders
      .filter((order) => {
        if (!query) return true;

        return (
          order.customer_name?.toLowerCase().includes(query) ||
          order.customer_phone_no?.toLowerCase().includes(query) ||
          order.customer_address?.toLowerCase().includes(query)
        );
      })
      .filter((order) => {
        if (isPmsgyOnly) {
          return order.pmsgyCustomer === "yes";
        }
        return true;
      });
  }, [orders, searchQuery, isPmsgyOnly]);

  // Update order count when filtered orders change
  useEffect(() => {
    setOrdersCount(filteredOrders.length);
  }, [filteredOrders]);

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="h-full">
          <LayoutHeader title="Orders" />

          <div className="px-4 pb-8">
            {/* Search and filters container */}
            <div className="p-4 mb-6 bg-white rounded-lg shadow-sm">
              <div className="grid items-center grid-cols-1 gap-4 lg:grid-cols-12">
                {/* Search input */}
                <div className="lg:col-span-5">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <HiSearch className="w-5 h-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearch}
                      placeholder="Search by name, phone or address"
                      className="w-full pl-10 pr-4 py-2.5 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
                    />
                  </div>
                </div>

                {/* PMSGY filter */}
                <div className="lg:col-span-2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="pmsgy-checkbox"
                      checked={isPmsgyOnly}
                      onChange={handlePmsgyToggle}
                      className="w-5 h-5 text-blue-600 rounded focus:ring-blue-500"
                    />
                    <label
                      htmlFor="pmsgy-checkbox"
                      className="ml-2 text-sm font-medium text-gray-700"
                    >
                      PMSGY Only
                    </label>
                  </div>
                </div>

                {/* Order count */}
                <div className="lg:col-span-3">
                  <div className="inline-flex items-center px-4 py-2 rounded-lg bg-blue-50">
                    <span className="text-sm font-medium text-gray-700">
                      Total Orders:{" "}
                      <span className="font-bold text-blue-900">
                        {ordersCount}
                      </span>
                    </span>
                  </div>
                </div>

                {/* Add order button */}
                <div className="flex justify-center lg:col-span-2 lg:justify-end">
                  <Link
                    to="/admin/add-order"
                    className="inline-flex items-center px-4 py-2.5 font-medium text-white bg-blue-900 border border-transparent rounded-lg shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 w-full justify-center lg:w-auto"
                  >
                    <MdOutlineAddShoppingCart className="w-5 h-5 mr-2" />
                    <span>Add Order</span>
                  </Link>
                </div>
              </div>
            </div>

            {/* Loading state */}
            {loading && (
              <div className="flex items-center justify-center w-full py-12">
                <ClipLoader size={40} color="#1E3A8A" />
              </div>
            )}

            {/* No results state */}
            {!loading && filteredOrders.length === 0 && (
              <div className="p-8 text-center bg-white rounded-lg shadow-sm">
                <div className="flex flex-col items-center justify-center">
                  <svg
                    className="w-16 h-16 mb-4 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    ></path>
                  </svg>
                  <h3 className="mb-1 text-lg font-medium text-gray-900">
                    No orders found
                  </h3>
                  <p className="text-gray-500">
                    {searchQuery || isPmsgyOnly
                      ? "Try adjusting your search filters"
                      : "Get started by adding your first order"}
                  </p>
                  {(searchQuery || isPmsgyOnly) && (
                    <button
                      onClick={() => {
                        setSearchQuery("");
                        setIsPmsgyOnly(false);
                      }}
                      className="px-4 py-2 mt-4 text-sm font-medium text-blue-900 transition duration-150 bg-blue-100 rounded-md hover:bg-blue-200"
                    >
                      Clear filters
                    </button>
                  )}
                </div>
              </div>
            )}

            {/* Results table */}
            {!loading && filteredOrders.length > 0 && (
              <div className="overflow-hidden bg-white rounded-lg shadow-sm">
                <div className="overflow-x-auto">
                  <OrdersTable orders={filteredOrders} />
                </div>
              </div>
            )}
          </div>
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
}
